<form [formGroup]="formGroup">
  <div fxLayout="row">
    <div fxLayout="row" fxFlex fxLayoutGap="8px">
      <mat-form-field fxFlex>
        <mat-label>Contact</mat-label>
        <mat-select formControlName="idtContactTo" [compareWith]="compareContacts" required>
          <mat-option>
            <ngx-mat-select-search [formControl]="contactSearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
              <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" />
            </ngx-mat-select-search>
          </mat-option>
          @for (contact of filterdContacts$ | async; track contact) {
            <mat-option [value]="contact.idtContact">
              {{ contact.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex>
        <mat-label>Relationship Type</mat-label>
        <mat-select formControlName="idtContactRelationshipType" required>
          <mat-option>
            <ngx-mat-select-search [formControl]="relationshipTypeSearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
              <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" />
            </ngx-mat-select-search>
          </mat-option>
          @for (type of filteredTypes$ | async; track type) {
            <mat-option [value]="type.idtContactRelationshipType">
              {{ type.relationship }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <button fxLayoutAlign="end" mat-icon-button aria-label="Remove" (click)="removeForm(index)" type="button">
      <mat-icon fontIcon="mdi-minus-circle" />
    </button>
  </div>
</form>
