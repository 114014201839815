import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClassAddOrEditComponent } from 'app/modules/routes/portfolio/components/class-add-or-edit/class-add-or-edit.component';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { PortfolioClass } from '../model/portfolio.model';

/**
 * Service to call the api for a Portfolio CRUD and business rule
 */
@Injectable({
  providedIn: 'root',
})
export class PortfolioClassService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  /**
   * Get a class details by id.
   *
   * @param idtClass the class (or series) id
   * @returns an observable that emits the class data when the server responds
   */
  getOne(idtClass: number) {
    return this.http.get<PortfolioClass>(`${environment.apiUrl}/class/${idtClass}`);
  }

  /**
   * Search for portfolio classes.
   *
   * @returns list of classes found
   */
  findAll(sort: string = 'name,asc', filter = ''): Observable<PortfolioClass[]> {
    return this.http.get<PortfolioClass[]>(`${environment.apiUrl}/class?filter=${filter}`, { params: { sort } });
  }

  /**
   * Get list of classes and series matching the provided filters.
   *
   * @param filters filters to send to the server
   * @returns observable that emits the list of classes and series
   */
  getList(filters: ClassSearchFilterDTO) {
    return this.http.get<PortfolioClass[]>(`${environment.apiUrl}/class/detail/list`, {
      params: {
        idtPortfolio: filters.idtPortfolio,
        ...(!!filters.name && { name: filters.name }),
        ...(filters.inactive != null && { inactive: filters.inactive }),
        ...(filters.closed != null && { closed: filters.closed }),
        ...(filters.idtTerm != null && { idtTerm: filters.idtTerm }),
      },
    });
  }

  /**
   * Get all series for the provided portfolio class.
   *
   * @param idtClass the class id
   * @returns an observable that emits the list of series found when the server responds
   */
  getSeries(idtClass: number) {
    return this.http.get<PortfolioClass[]>(`${environment.apiUrl}/class/${idtClass}/series`);
  }

  /**
   * Open dialog to add or edit a class.
   *
   * @param idtPortfolio the portfolio id
   * @param portfolioClass existing class or series for editions
   * @param parentClass parent class for series creation/edition
   * @returns the dialog instance reference
   */
  openAddOrEditDialog(idtPortfolio: number, portfolioClass?: PortfolioClass | null, parentClass?: PortfolioClass | null) {
    return this.dialog.open(ClassAddOrEditComponent, {
      width: '500px',
      data: {
        idtPortfolio,
        portfolioClass,
        parentClass,
      },
    });
  }
}

/**
 * Definition of filters used when searching for classes.
 */
interface ClassSearchFilterDTO {
  idtPortfolio: number;
  name: string;
  inactive: boolean | null;
  closed: boolean | null;
  idtTerm: number | null;
}
