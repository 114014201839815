<div class="tab-tools abs-margin-right"></div>

@if (showChip) {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close" />
        </button>
      </mat-chip>
    }
  </mat-chip-listbox>
}

<app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
  <cdk-virtual-scroll-viewport class="tw-flex tw-flex-1 tw-flex-col" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="2" [height]="44" [items]="20" [loading]="loading()" />

    <app-custom-list-item
      *cdkVirtualFor="let opportunity of opportunities$ | async"
      [selected]="opportunity.idtContact === selectedConsultant?.idtContact"
      (click)="select(opportunity)"
    >
      <div class="tw-flex tw-max-w-[100%] tw-flex-1 tw-items-center">
        <div class="column-name tw-flex tw-max-w-[70%] tw-flex-1 tw-flex-col">
          <span>
            <div class="tw-flex">
              <app-contact-context-menu
                class="abs-bold abs-text-overflow-ellipsis"
                [matTooltip]="opportunity.name"
                [displayText]="opportunity.name"
                [id]="opportunity.idtContact"
              />
            </div>
          </span>
          <span class="abs-color-faded">{{ opportunity.count }} {{ opportunity.count > 1 ? 'opportunities' : 'opportunity' }}</span>
        </div>
        <div class="column-amount tw-flex tw-max-w-[30%] tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
          <span class="abs-bold">{{ opportunity.size | nearUnitNumber: '0.0-0' }}</span>
          <div class="abs-color-faded tw-flex">
            <span>{{ opportunity.portfolios[0] }}</span>
            @if (opportunity.portfolios.length > 1) {
              <span>&nbsp;+ {{ opportunity.portfolios.length - 1 }}</span>
            }
          </div>
        </div>
      </div>
      <mat-divider />
    </app-custom-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
