<mat-form-field fxFlex>
  <mat-label>Portfolios</mat-label>

  <mat-chip-grid #chipList [formControl]="control">
    @for (portfolio of control.value; track portfolio) {
      <mat-chip-row (removed)="remove(portfolio)" [value]="portfolio">
        {{ portfolio.shortName }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close" />
        </button>
      </mat-chip-row>
    }
    <input placeholder="Select portfolio" #input [formControl]="searchControl" [matAutocomplete]="auto" [matChipInputFor]="chipList" />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (portfolios of groupedPortfolios() | keyvalue: sortGroups; track portfolios.key) {
      <mat-optgroup [label]="portfolios.key !== 'null' ? portfolios.key : 'None'">
        @for (portfolio of portfolios.value; track portfolio.idtPortfolio) {
          <mat-option [value]="portfolio">
            {{ portfolio.shortName }}
          </mat-option>
        }
      </mat-optgroup>
    }
  </mat-autocomplete>
</mat-form-field>
