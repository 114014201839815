import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Chip } from 'app/modules/common/framework/model/chip.model';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { ChipService } from 'app/modules/common/framework/services/chip.service';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LogEventEnum } from '../../LogEventEnum';
import { LogDetailViewTO } from '../../model/log.model';
import { LogContactService } from '../../services/log-contact.service';

@Component({
  selector: 'app-contact-log',
  templateUrl: './contact-log.component.html',
  styleUrls: ['./contact-log.component.scss'],
})
export class ContactLogComponent implements OnInit {
  /**
   * The id of the contact.
   */
  private _idtContact!: number;

  /**
   * Setter for the id of the contact.
   */
  @Input()
  set idtContact(value: number) {
    this._idtContact = value;

    this.setLogData();
    this.setChipLogData();
  }

  /**
   * Getter for the id of the contact.
   */
  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * If it should keep the chips in a single line.
   */
  @Input()
  singeLineChips = false;

  /**
   * The data to be displayed.
   */
  logData!: PageableDataSource<LogDetailViewTO>;

  /**
   * The data for the chips.
   */
  chipLogData: Chip[] = [];

  /**
   * Event to emit when the logs are loading.
   */
  @Output()
  loadingChanged = new EventEmitter<boolean>();

  constructor(
    private logContactService: LogContactService,
    private contactService: ContactService,
    private chipService: ChipService,
    private eventService: EventQueueService,
    private trackerService: TrackerService,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.eventService
      .on(LogEventEnum.logSaved)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setLogData();
        this.setChipLogData();
      });
  }

  /**
   * Gets the data from the server.
   */
  private setLogData(): void {
    const self = this;
    const l = 10;

    this.logData = new (class extends PageableDataSource<LogDetailViewTO> {
      fetch(page: number, limit: number): Observable<Pageable<LogDetailViewTO>> {
        return self.logContactService.findLogs(self.idtContact, page, limit);
      }
    })(l);

    this.logData.loading$.subscribe((loading) => {
      setTimeout(() => {
        this.loadingChanged.emit(loading);
      });
    });
  }

  /**
   * Gets the contact log totals by type to populate log chips.
   */
  private setChipLogData(): void {
    this.contactService
      .getLogsTotal(this.idtContact)
      .pipe(
        tap((data) => {
          this.chipLogData = this.chipService.getChipListOfTotalType(data);
        }),
      )
      .subscribe();
  }

  /**
   * Open sidenav when the log is clicked and show log details.
   *
   * @param eventData the click event data
   */
  onLogClick(eventData: { log: LogDetailViewTO; index: number }): void {
    this.trackerService.event('contact', 'open_log_details');

    // Close any open sidenav then open the logs one. This allows to reopen the logs sidenav with a different filter.
    this.router.navigate([{ outlets: { sidenav: null } }]).then(() => {
      this.router.navigate(
        [
          {
            outlets: {
              sidenav: ['contact', this.idtContact, 'logs', eventData.log.idtLog],
            },
          },
        ],
        {
          queryParams: {
            'sidenav.logIndex': eventData.index,
          },
        },
      );
    });
  }

  /**
   * Open sidenav when the chip of logs, docs or mailing are clicked.
   *
   * @param type the type of log
   */
  onChipClick(type: Chip): void {
    this.trackerService.event('contact', 'expand_logs', { type });

    this.router.navigate([{ outlets: { sidenav: null } }]).then(() => {
      this.router.navigate(
        [
          {
            outlets: {
              sidenav: ['contact', this.idtContact, 'logs'],
            },
          },
        ],
        {
          queryParams: {
            'sidenav.logType': type.value,
          },
        },
      );
    });
  }
}
