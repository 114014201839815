import { Component, Input } from '@angular/core';
import { OpportunityViewTO } from '../../../model/opportunity.model';

/**
 * Component for a single opportunity list item.
 */
@Component({
  selector: 'app-home-opportunity-list-item',
  templateUrl: './home-opportunity-list-item.component.html',
  styleUrls: ['./home-opportunity-list-item.component.scss'],
})
export class HomeOpportunityListItemComponent {
  /**
   * The opportunity to be displayed.
   */
  @Input()
  opportunity!: OpportunityViewTO;

  /**
   * Whether the opportunity is selected.
   */
  @Input()
  selected = false;
}
