import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileService } from 'app/modules/common/framework/services/file.service';
import { environment } from 'environments/environment';
import { tap } from 'rxjs/operators';

/**
 * Service for email attachment operations.
 */
@Injectable({
  providedIn: 'root',
})
export class EmailAttachmentService {
  constructor(private http: HttpClient, private fileService: FileService) {}

  /**
   * Download an email attachment.
   *
   * @param idtEmailAttachment the id of the attachment entity
   */
  download(idtEmailAttachment: number): void {
    this.http
      .get<string>(`${environment.apiUrl}/email-attachment/${idtEmailAttachment}/url`)
      .pipe(tap((url) => this.fileService.download(url)))
      .subscribe();
  }
}
