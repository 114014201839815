<div fxFlex fxLayout="column" class="abs-scroll-hidden">
  <div fxLayout="row" class="abs-component-title abs-padding">
    <span fxFlex>Message Content</span>
  </div>

  @if (details$ | async; as details) {
    <div class="abs-padding" fxFlex fxLayout="column">
      <div fxLayout="row" class="abs-margin-bottom">
        <div fxFlex fxLayout="column" fxLayoutGap="8px">
          <app-output label="To">{{ details.toEmail }}</app-output>
          <app-output label="From">{{ details.fromEmail }}</app-output>
          <app-output label="Subject">{{ details.subject }}</app-output>
        </div>
        <mat-divider [vertical]="true" />
        <div fxFlex fxLayout="column">
          <div fxLayout="row" class="abs-component-title abs-padding">
            <span fxFlex>Returned Analytical Info</span>
          </div>
          <div fxLayout="row" class="abs-padding" fxLayoutGap="8px">
            <app-output label="Opens">{{ details.opens }}</app-output>
            <app-output label="Clicks">{{ details.clicks }}</app-output>
            <app-output label="Status" [matTooltip]="details.state === 'INVALID' ? 'Message with blank variables' : details.errorDetails">
              <span
              [ngClass]="{
                'abs-color-error': ['INVALID', 'SEND_FAILED', 'UNSUB'].includes(details.state)
              }"
                >
                {{ details.state }}
              </span>
            </app-output>
          </div>
        </div>
      </div>
      @if (details.attachments) {
        <app-output label="Attachments">
          <mat-chip-listbox>
            @for (attachment of details.attachments; track attachment) {
              <mat-chip (click)="download(attachment.idtEmailAttachment)" class="abs-clickable">
                {{ attachment.name }}
              </mat-chip>
            }
          </mat-chip-listbox>
        </app-output>
      }
      <app-output-iframe fxFlex class="abs-border" [content]="details.body" />
    </div>
  }
</div>
