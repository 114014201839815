import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { AccountService } from '../../../account/services/account.service';
import { OpportunityService } from '../../../opportunity/services/opportunity.service';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { Column } from '../../model/column.model';
import { EntityTypeEnum } from '../../model/entity-type-enum';
import { CustomTableService } from '../../services/custom-table';
import { EntityTemplateService } from '../../services/entity-template.service';

/**
 * Interface for a filter value.
 */
export interface Filter {
  filter: string;
  column: Column;
}

/**
 * Component to display a filter for the accounts table.
 */
@Component({
  selector: 'app-entity-table-filter',
  templateUrl: './entity-table-filter.component.html',
  styleUrls: ['./entity-table-filter.component.scss'],
})
export class EntityTableFilterComponent implements OnInit {
  /**
   * The available values to select after applying the filter.
   */
  filteredValues$?: Observable<(string | null)[]>;

  /**
   * Form control for the autocomplete filter.
   */
  filterFormControl = new FormControl<string>('', { nonNullable: true });

  /**
   * Form control for the select field itself.
   */
  selectionFormControl!: FormControl<string | string[]>;

  /**
   * The column to apply this filter.
   */
  @Input()
  column!: Column;

  /**
   * The portfolio id to filter the autocomplete response.
   */
  @Input()
  portfolioId?: number;

  /**
   * The class id to filter the autocomplete response.
   */
  @Input()
  classId?: number;

  /**
   * Flag to indicate if the search field should be present.
   */
  searchable = true;

  /**
   * Flag to identify when loading options. Shows a loading indicator in the search field.
   */
  searching = false;

  /**
   * Indicates if it should allow multiple selection.
   */
  multiple = true;

  /**
   * Emits changes to the selected values.
   */
  @Output()
  valueChanged = new EventEmitter<Filter>();

  /**
   * The entity type.
   */
  @Input()
  type!: EntityTypeEnum;

  /**
   * The options to display.
   */
  options: (string | null)[] = [];

  /**
   * The filters to apply when loading options.
   */
  private _filter = '';

  get filter(): string {
    return this._filter;
  }

  @Input()
  set filter(value: string) {
    this._filter = value;

    if (this.service && this.column.loadAllOptions) {
      this.options = [];
      this.searching = true;
      this.filteredValues$ = this.service.getOptions(this.filter, this.column.field).pipe(
        finalize(() => {
          this.searching = false;
        }),
      );
    }
  }

  private service!: CustomTableService<any>;

  constructor(
    private templateService: EntityTemplateService,
    private accountService: AccountService,
    private transactionService: TransactionService,
    private contactService: ContactService,
    private opportunityService: OpportunityService,
    private destroyRef: DestroyRef,
  ) {}

  /**
   * Angular lifecycle hook method, called after component initialization.
   * Adds listener to the search field to trigger fetching options from the server.
   */
  ngOnInit(): void {
    switch (this.type) {
      case EntityTypeEnum.account:
        this.service = this.accountService;
        break;
      case EntityTypeEnum.transaction:
        this.service = this.transactionService;
        break;
      case EntityTypeEnum.contact:
        this.service = this.contactService;
        break;
      case EntityTypeEnum.opportunity:
        this.service = this.opportunityService;
        break;
      default:
        this.service = this.accountService;
    }

    // Allow multiple selection on string and long fields. Dates, decimals and other fields currently can't allow multiple selection
    this.multiple = ['string', 'long'].includes(this.column.fieldType) || this.column.fieldType.startsWith('com.');

    this.selectionFormControl = new FormControl<string | string[]>(this.multiple ? [] : '', { nonNullable: true });

    // For boolean values, no need to fetch from server
    if (this.column.fieldType === 'boolean') {
      this.options = ['true', 'false'];
      this.searchable = false;
    }
    // If the possible values are defined in the column config, use them in the select field
    if (this.column.options) {
      this.options = [null, ...this.column.options];
      this.searchable = false;
    }

    if (this.column.loadAllOptions) {
      this.filteredValues$ = this.service.getOptions(this.filter, this.column.field);

      this.searchable = false;
    }

    // Trigger autocomplete query when search changes
    this.filterFormControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), debounceTime(1000)).subscribe((search) => {
      if (search.length < 2) {
        this.options = [];
      } else {
        this.searching = true;

        const filter = this.buildFilter(search);

        this.service
          .getOptions(filter, this.column.field)
          .pipe(finalize(() => (this.searching = false)))
          .subscribe((options) => {
            this.options = options;
          });
      }
    });

    this.selectionFormControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), debounceTime(1000))
      .subscribe((value) => {
        // Save the filter value in local storage
        localStorage.setItem(`${this.type}-filter-${this.column.field}`, JSON.stringify(value));

        // If no option is selected
        if (!value || value?.length === 0) {
          this.valueChanged.emit({ column: this.column, filter: '' });
          return;
        }

        // If multiple options are selected
        if (this.multiple) {
          this.emitMultipleFilter(value as string[]);
        } else {
          this.valueChanged.emit({
            column: this.column,
            filter: new DataFilter().equals(this.column.field, value, this.column.fieldType).encode(),
          });
        }
      });

    // Load previous filter value from local storage
    const savedValue = localStorage.getItem(`${this.type}-filter-${this.column.field}`);

    if (savedValue) {
      this.selectionFormControl.setValue(JSON.parse(savedValue));
    }
  }

  /**
   * Gets the proper display value for the displayed options.
   *
   * @param value the value to be formatted
   * @returns the value properly formatted according to the column definitions
   */
  getDisplayValue(value: string): string {
    if (value === '') {
      return 'None';
    }

    return this.templateService.getValue(value, this.column);
  }

  private buildFilter(searchTerm: string): string {
    let filter;

    switch (this.column.fieldType) {
      case 'string':
      case 'long': {
        // Long can be cast to string to apply "like" filtering
        filter = `${this.column.field}|like|%${searchTerm}%|string`;
        break;
      }
      default: {
        filter = `${this.column.field}|eq|${searchTerm}|${this.column.fieldType}`;
      }
    }

    return `${this.filter},${filter}`;
  }

  /**
   * Handle filtering when selecting multiple options.
   *
   * @param value the values to filter for
   */
  private emitMultipleFilter(value: string[]): void {
    let filter: DataFilter = new DataFilter();

    // If value array includes null (empty) add isnull condition
    if (value.includes('')) {
      // Remove null from array
      value = value.filter((v: string) => v !== '');

      filter = filter.isNull(this.column.field);

      if (value.length > 0) {
        // If column is a collection, means it is stored as a string separated list of values, which mean "IN" wouldn't work, instead we add multiple "LIKE" filters with "OR"
        if (this.column.collection) {
          value.forEach((v) => (filter = filter.like(this.column.field, `%${v}%`, this.column.fieldType)));
        } else {
          filter.in(this.column.field, value, this.column.fieldType);
        }

        filter.or();
      }
    } else {
      if (this.column.collection) {
        filter.or();

        value.forEach((v) => (filter = filter.like(this.column.field, `%${v}%`, this.column.fieldType)));
      } else {
        filter.in(this.column.field, value, this.column.fieldType);
      }
    }

    this.valueChanged.emit({
      column: this.column,
      filter: filter.encode(),
    });
  }

  /**
   * Clear the selected value.
   */
  clear(): void {
    this.selectionFormControl.reset();
    localStorage.removeItem(`${this.type}-filter-${this.column.field}`);
  }

  /**
   * Handle opening the filter, load options if not yet loaded.
   *
   * @param opened if the filter was opened or closed
   */
  opened(opened: boolean): void {
    if (opened && this.options.length === 0 && this.column.loadAllOptions) {
      this.filteredValues$?.subscribe((options) => (this.options = options));
    }
  }

  /**
   * Cast value to string.
   * @param value the string that will be convert
   * @returns a casted value
   */
  castToString(value: string | string[]) {
    return value as string | null;
  }
}
