import { DefaultValueAccessor } from '@angular/forms';

/**
 * Override custom acessor to form control to trim all string inputs.
 */
export function trimAllInputs(): void {
  const original = DefaultValueAccessor.prototype.registerOnChange;

  DefaultValueAccessor.prototype.registerOnChange = function (fn) {
    return original.call(this, (value) => {
      const trimmed = typeof value === 'string' ? value.trim() : value;
      return fn(trimmed);
    });
  };
}
