import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BusinessRole } from 'app/modules/common/business/business-role/model/business-role.model';
import { BusinessRoleService } from 'app/modules/common/business/business-role/services/business-role.service';
import { ContactJob, ContactJobInputDTO, ContactJobViewTO } from 'app/modules/common/business/contact/model/contact-job.model';
import { dateLessThan, DateLessThanMatcher } from 'app/modules/common/framework/validations/validation-utils';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';
import { ContactService } from '../../../services/contact.service';
import { ContactEndJobDialogComponent } from '../../contact-end-job-dialog/contact-end-job-dialog.component';

/**
 * The form definition.
 */
export type ContactJobForm = FormGroup<{
  idtContactJob: FormControl<number | undefined>;
  idtContact: FormControl<number | undefined>;
  idtCompany: FormControl<number | null>;
  businessRole: FormControl<string | undefined>;
  startDate: FormControl<DateTime | string | undefined>;
  endDate: FormControl<DateTime | string | undefined>;
  keyContact: FormControl<boolean>;
}>;

/**
 * Component with contact job form
 */
@Component({
  selector: 'app-contact-job-form',
  templateUrl: './contact-job-form.component.html',
  styleUrls: ['./contact-job-form.component.scss'],
})
export class ContactJobFormComponent implements OnInit {
  /**
   * The index of form array.
   */
  @Input()
  index!: number;

  /**
   * The contact job form
   */
  @Input()
  contactJobForm!: ContactJobForm;

  /**
   * The contact job for edition
   */
  @Input()
  contactJob?: ContactJobViewTO | null;

  /**
   * The contact id.
   */
  @Input()
  idtContact!: number;

  /**
   * Emits the remove event to delete a job form from array.
   */
  @Output()
  remove = new EventEmitter<number>();

  /**
   * The business role form to control the filter
   */
  businessRoleForm: FormControl = new FormControl<string>('');

  /**
   * Id of the company contact. Used to allow loading the full contact data.
   */
  idtCompanyContact?: number;

  /**
   * List of business roles filtered by search keyword
   */
  filteredRoles$?: Observable<BusinessRole[]>;

  /**
   * Indicate search operation is in progress.
   */
  searching = false;

  /**
   * Error matcher to mark field as invalid based on the form validation of the presentation and due date.
   */
  dateLessThanMatcher = new DateLessThanMatcher();

  constructor(
    private businessRoleService: BusinessRoleService,
    private dialog: MatDialog,
    private contactService: ContactService,
  ) {}

  static getContactJobForm(contactJob?: ContactJobViewTO): ContactJobForm {
    const formGroup = new FormGroup(
      {
        idtContactJob: new FormControl<number | undefined>(contactJob?.idtContactJob, { nonNullable: true }),
        idtContact: new FormControl<number | undefined>(contactJob?.idtContact, { nonNullable: true }),
        idtCompany: new FormControl<number | null>(contactJob?.idtCompany ?? null, { nonNullable: true, validators: Validators.required }),
        businessRole: new FormControl<string | undefined>(contactJob?.businessRole, { nonNullable: true }),
        startDate: new FormControl<DateTime | string | undefined>(contactJob?.startDate ? DateTime.fromISO(contactJob.startDate) : undefined, {
          nonNullable: true,
        }),
        endDate: new FormControl<DateTime | string | undefined>(contactJob?.endDate ? DateTime.fromISO(contactJob.endDate) : undefined, {
          nonNullable: true,
        }),
        keyContact: new FormControl<boolean>(contactJob?.keyContact || false, { nonNullable: true }),
      },
      {
        validators: [dateLessThan('startDate', 'endDate')],
      },
    );

    [formGroup.controls.idtContactJob, formGroup.controls.businessRole, formGroup.controls.startDate, formGroup.controls.endDate].forEach(
      (field: FormControl) => {
        field.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
          formGroup.controls.idtCompany.updateValueAndValidity();
        });
      },
    );

    return formGroup;
  }

  ngOnInit(): void {
    if (this.contactJob?.idtCompanyContact) {
      this.idtCompanyContact = this.contactJob.idtCompanyContact;
    }

    this.filteredRoles$ = this.contactJobForm.controls.businessRole.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      filter((search) => !!search && search.length >= 2),
      mergeMap((search) => {
        return this.businessRoleService.findByRole(search!);
      }),
    );
  }

  /**
   * Emits the event to remove a job form from form array.
   */
  removeForm(): void {
    this.remove.emit(this.index);
  }

  /**
   * Get the role name from the role object.
   *
   * @param value the business role
   * @returns the role name
   */
  getRoleName(value: BusinessRole | null): string {
    return value?.role || '';
  }

  /**
   * Open dialog to set the jobs end date.
   */
  openEndJobDialog(): void {
    const dialogRef = this.dialog.open(ContactEndJobDialogComponent, {
      data: {
        idtContact: this.idtContact,
      },
    });

    dialogRef.afterClosed().subscribe((endDate: DateTime | null) => {
      if (endDate) {
        this.contactJobForm.controls.endDate.setValue(endDate);
      }
    });
  }

  /**
   * Saves this job entity.
   *
   * @returns an observable that emits the saved job entity when the server responds
   */
  private saveJob(): Observable<ContactJob> {
    const job = this.contactJobForm.value;

    const jobInput: ContactJobInputDTO = {
      ...job,
      idtCompany: job.idtCompany!,
      startDate: job.startDate instanceof DateTime ? job.startDate.toISODate() : job.startDate,
      endDate: job.endDate instanceof DateTime ? job.endDate.toISODate() : job.endDate,
    };

    return this.contactService.saveJob(this.idtContact, jobInput);
  }
}
