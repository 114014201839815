<mat-form-field subscriptSizing="dynamic">
  <mat-label>{{ column.fieldName }}</mat-label>
  <mat-select [multiple]="multiple" [formControl]="selectionFormControl" disableOptionCentering #select="matSelect" (openedChange)="opened($event)">
    @if (searchable) {
      <mat-option>
        <ngx-mat-select-search
          [formControl]="filterFormControl"
          placeholderLabel="Search"
          noEntriesFoundLabel="Nothing found"
          [searching]="searching"
        >
          <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" />
        </ngx-mat-select-search>
      </mat-option>
    }

    <!-- Empty option to make the select options panel open even without any available "real" options, which will be loaded on demand -->
    <mat-option class="loading-option" disabled />

    <!-- Empty option to clear selection, not necessary when multiple selection is available -->
    @if (!multiple) {
      <mat-option />
    }

    <!-- The options to be displayed -->
    @for (value of options; track value) {
      <mat-option [value]="value || ''">
        {{ getDisplayValue(value || '') }}
      </mat-option>
    }

    <!-- Keeps the list of selected options to avoid hiding the selected values when results not loaded -->
    @if (!select.panelOpen || (searchable && !filterFormControl.value)) {
      @if (multiple) {
        @for (value of selectionFormControl.value; track value) {
          <mat-option [value]="value || ''">
            {{ getDisplayValue(value || '') }}
          </mat-option>
        }
      } @else {
        @if (selectionFormControl.value) {
          <mat-option [value]="selectionFormControl.value">
            {{ getDisplayValue(castToString(selectionFormControl.value) || '') }}
          </mat-option>
        }
      }
    }
  </mat-select>
</mat-form-field>
