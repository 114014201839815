/**
 * Enum with transaction type options.
 * Notice this maps the CLIENT_TRANSACTION_TYPE
 */
export enum TransactionTypeEnum {
  SUBSCRIPTION = 1,
  REDEMPTION,
  TRANSFER_IN,
  TRANSFER_OUT,
  SWITCH_IN,
  SWITCH_OUT,
  COMMITMENT,
  CALL,
  INCENTIVE_FEE_REBATE,
  MANAGEMENT_FEE_REBATE,
}
