<div class="labelless tw-flex tw-flex-row tw-items-center">
  <mat-form-field subscriptSizing="dynamic">
    <input matInput placeholder="Search" [formControl]="control" #searchField />
  </mat-form-field>
  <div>
    <button mat-icon-button color="primary" matTooltip="Search">
      <mat-icon fontIcon="mdi-magnify" />
    </button>
  </div>
</div>
