import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { Balance } from '../../client/model/client-balance.model';
import { Account } from '../model/account.model';
import { AccountService } from '../services/account.service';

/**
 * Component to display data for accounts.
 */
@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent {
  /**
   * The account details wrapped in an observable.
   */
  accountDetails$: Observable<Account>;

  /**
   * The current balance data.
   */
  currentBalance$: Observable<Balance | null>;

  /**
   * The id of the account.
   */
  idtAccount?: number;

  /**
   * The id of the client that owns the account.
   */
  idtClient?: number;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private destroyRef: DestroyRef
  ) {
    this.accountDetails$ = this.route.data.pipe(
      takeUntilDestroyed(this.destroyRef),
      map((data) => data.account),
      tap((account) => {
        this.idtAccount = account.idtAccount;
        this.idtClient = account.idtClient;
      }),
      shareReplay()
    );

    this.currentBalance$ = this.route.paramMap.pipe(
      takeUntilDestroyed(this.destroyRef),
      mergeMap((params) => {
        const id = params.get('idtAccount');

        if (id) {
          return this.accountService.getLatestBalance(+id);
        }

        return EMPTY;
      })
    );
  }
}
