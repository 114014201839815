import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TagTO } from '../model/tag.model';

/**
 * Service for tag related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private http: HttpClient) {}

  /**
   * Get all configured tags.
   *
   * @returns the list of tags configured in the system
   */
  getTags(): Observable<TagTO[]> {
    return this.http.get<TagTO[]>(`${environment.apiUrl}/tag`);
  }

  /**
   * Get all previously used values for the provided tag.
   *
   * @param idtTag the tag id
   * @returns an observable that emits list o tag values
   */
  getTagOptions(idtTag: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/tag/options`, { params: { idtTag } });
  }
}
