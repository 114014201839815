import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BusinessRole } from '../model/business-role.model';

/**
 * Service to handle with the api related to business role
 */
@Injectable({
  providedIn: 'root',
})
export class BusinessRoleService {
  constructor(private http: HttpClient) {}

  /**
   * Finds the business role by its role
   *
   * @param role the business role to be searched
   * @returns the list of found business roles
   */
  findByRole(role: string): Observable<BusinessRole[]> {
    return this.http.get<BusinessRole[]>(`${environment.apiUrl}/business-role`, { params: { role } });
  }
}
