import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { MailingService } from 'app/modules/common/business/mailing/services/mailing.service';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { EventQueueService } from 'app/modules/common/framework/services/event-queue.service';
import { EmailGroupContactViewTO } from 'app/modules/routes/mailing/model/mailing.model';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { MailingEventEnum } from '../../MailingEvent.enum';

/**
 * Component to list the Mailing Groups
 */
@Component({
  selector: 'app-mailing-group-list',
  templateUrl: './mailing-group-list.component.html',
  styleUrls: ['./mailing-group-list.component.scss'],
})
export class MailingGroupListComponent implements OnInit {
  /**
   * The id of the contact.
   */
  private _idtContact!: number;

  /**
   * Setter for the contact id. Changes trigger reloading the mail data.
   */
  @Input()
  set idtContact(value: number) {
    this.loading.emit(true);

    this._idtContact = value;
    this.loadData();
  }

  /**
   * Getter for the contact id.
   */
  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * The mailing group data wrapped in an observable.
   */
  mailingGroupData$?: Observable<EmailGroupContactViewTO[]>;

  /**
   * Event emitted when loading data from server.
   */
  @Output()
  loading = new EventEmitter<boolean>(true);

  private sort = ['portfolio,ASC', 'description,ASC'];

  /**
   * Whether rendered in the sidenav or not.
   */
  @Input()
  sidenav = false;

  /**
   * Whether the contact has an email address configured.
   */
  hasEmail = signal(false);

  constructor(
    private contactService: ContactService,
    private dialogService: DialogService,
    private mailingService: MailingService,
    private eventQueueService: EventQueueService,
  ) {}

  ngOnInit(): void {
    this.eventQueueService.on(MailingEventEnum.groupsUpdated).subscribe(() => {
      this.loadData();
    });
  }

  /**
   * Open confirmation dialog to remove contact from all groups.
   */
  openDeleteDialog(): void {
    this.dialogService
      .showConfirm('Are you sure you want to remove this contact from all groups?')
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          this.deleteFromAllGroups();
        }
      });
  }

  /**
   * Remove the contact from the provided email group.
   *
   * @param idtEmailGroup the email group to remove from
   */
  deleteFromSingleGroup(idtEmailGroup: number): void {
    this.mailingService.removeContacts(idtEmailGroup, [this.idtContact]).subscribe(() => {
      this.loadData();
    });
  }

  /**
   * Send request to remove contact from all groups.
   */
  private deleteFromAllGroups(): void {
    this.mailingService
      .removeContactFromAllGroups(this.idtContact, false)
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error removing from groups');
          return throwError(err);
        }),
      )
      .subscribe(() => {
        this.loading.emit(true);
        this.loadData();
      });
  }

  /**
   * Load the mail data.
   */
  private loadData(): void {
    this.loading.emit(true);

    this.contactService.getInfoById(this.idtContact).subscribe((c) => {
      this.hasEmail.set(!!c.email);
    });

    this.mailingGroupData$ = this.contactService.findMailingGroupsByIdtContact(this.idtContact, this.sort, this.buildFilter()).pipe(
      finalize(() => {
        this.loading.emit(false);
      }),
    );
  }

  /**
   * Builds a filter entity for searching the groups.
   *
   * @returns the filter entity
   */
  private buildFilter(): DataFilter {
    const filter = new DataFilter().equals('archived', false, 'boolean').equals('deleted', false, 'boolean');

    return filter;
  }
}
