import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';

/**
 * Component for standarizing dialog titles.
 */
@Component({
  selector: 'app-dialog-title',
  templateUrl: './dialog-title.component.html',
  styleUrl: './dialog-title.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTitleComponent {
  /**
   * Add class to the wrapping element for better css selectors.
   */
  @HostBinding('class')
  protected readonly classes = 'app-dialog-title';

  /**
   * The text to display as the dialog title.
   */
  displayTitle = input.required<string>();

  /**
   * Input to show or not loading progress bar.
   */
  loading = input<boolean>(false);
}
