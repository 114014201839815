import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

/**
 * Service for sharing reused functions for last email messages.
 */
@Injectable({
  providedIn: 'root',
})
export class ContactLastEmailErrorService {
  /**
   * Gets the text description to be shwon depending on the error provided.
   *
   * @param error the error string
   * @param unsubDate optional date for unsub requests
   * @returns the error description to show
   */
  getErrorDescription(error: string, unsubDate?: string): string | undefined {
    switch (error) {
      case 'unsub':
        if (unsubDate) {
          return `The contact asked to unsubscribe on ${DateTime.fromISO(unsubDate).toFormat(
            'MM/dd/yyyy'
          )} and stop receiving messages from system, therefore messages are not sent to this address`;
        }

        return 'The contact asked to unsubscribe and stop receiving messages from system, therefore messages are not sent to this address';
      case 'soft-bounce':
        return "The message couldn't be delivered for some reason, please check the inbox status with the contact. If the inbox remains unreachable for some time, the email address will be invalidated";
      case 'hard-bounce':
        return 'This email address is invalid or incorrect, please review it before sending new messages';
      case 'Invalid address':
        return 'The message could not be sent, check that the email address is properly typed';
    }

    return;
  }
}
