import { ChangeDetectionStrategy, Component, ViewChild, input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { DateTime, DurationUnit } from 'luxon';

/**
 * Component for a date range picker with buttons to select predetermined intervals.
 */
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerComponent {
  /**
   * Start date form control.
   */
  startControl = input.required<FormControl<DateTime | null>>();

  /**
   * End date form control.
   */
  endControl = input.required<FormControl<DateTime | null>>();

  /**
   * Minimum allowed date.
   */
  minDate = input<DateTime>();

  /**
   * Maximum allowed date.
   */
  maxDate = input<DateTime>();

  /**
   * Reference to the date range picker.
   */
  @ViewChild('picker', { static: false })
  picker!: MatDateRangePicker<DateTime>;

  /**
   * Select a full period, based on the provided period value.
   * Period could be week, month, quarter or year.
   *
   * @param period the duration unit
   * @param duration the number of the duration interval to be added, for example if the perdio is 'week', -1 will select the previous week
   */
  selectDate(period: DurationUnit, duration: number = 0): void {
    this.startControl().setValue(
      DateTime.now()
        .plus({ [period]: duration })
        .startOf(period)
    );
    this.endControl().setValue(
      DateTime.min(
        DateTime.now(),
        DateTime.now()
          .plus({ [period]: duration })
          .endOf(period)
      )
    );
    this.picker.close();
  }
}
