<form [formGroup]="contactJobForm">
  <div fxLayout="row">
    <div fxFlex fxLayout="column">
      <div fxFlex fxLayout="row" fxLayoutGap="8px">
        <app-company-autocomplete fxFlex label="Company" [control]="contactJobForm.controls.idtCompany" [idtCompanyContact]="idtCompanyContact" />

        <mat-form-field fxFlex>
          <mat-label>Job Role</mat-label>

          <input matInput formControlName="businessRole" [matAutocomplete]="businessRoleAutocomplete" />
          <mat-autocomplete #businessRoleAutocomplete="matAutocomplete">
            @for (job of filteredRoles$ | async; track job) {
              <mat-option [value]="job.role">
                {{ job.role }}
              </mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div fxFlex fxLayout="row" fxLayoutGap="8px">
        <mat-form-field fxFlex="50">
          <mat-label>Start</mat-label>
          <input matInput [matDatepicker]="start" formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="start" />
          <mat-datepicker #start disabled="false" />
          <mat-error>Invalid Date</mat-error>
        </mat-form-field>

        <!--
            Show input to allow changing the end date. Notice we show the input if there is an end date, and keep showing it even if the value is invalid, not fully typed.
            If there is no value, we show the button to set the end date
         -->
        @if (!!contactJobForm.controls.endDate.value || !contactJobForm.controls.endDate.valid) {
          <mat-form-field fxFlex="50">
            <mat-label>End</mat-label>
            <input matInput [matDatepicker]="end" formControlName="endDate" [errorStateMatcher]="dateLessThanMatcher" />
            <mat-datepicker-toggle matSuffix [for]="end" />
            <mat-datepicker #end disabled="false" />
            <mat-error>Invalid Date</mat-error>
          </mat-form-field>
        } @else {
          <div fxLayoutAlign="center start" fxFlex class="abs-margin-top">
            <button mat-raised-button color="primary" (click)="contactJobForm.valid && openEndJobDialog()">Set end date</button>
          </div>
        }
      </div>

      <div fxLayout="row">
        <mat-slide-toggle formControlName="keyContact">Key Contact</mat-slide-toggle>
      </div>
    </div>

    <button fxLayoutAlign="end" mat-icon-button aria-label="Remove" (click)="removeForm()" type="button">
      <mat-icon fontIcon="mdi-minus-circle" />
    </button>
  </div>
</form>
