import { Injectable, WritableSignal } from '@angular/core';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';

/**
 * Creates an operator that writes to a boolean signal after the source observable completes.
 *
 * @param loading a boolean signal
 * @returns the created operator
 */
export function trackLoading<T>(loading: WritableSignal<boolean>): (source$: Observable<T>) => Observable<T> {
  return (source$: Observable<T>) => {
    loading.set(true);

    return source$.pipe(
      finalize(() => {
        loading.set(false);
      }),
    );
  };
}

/**
 * Service for observable operators that require dependency injection.
 */
@Injectable({
  providedIn: 'root',
})
export class ObservableUtilsService {
  constructor(private dialogService: DialogService) {}

  /**
   * Operator to catch errors and display the error message in a snackbar.
   *
   * @param message the backup message to be displayed in case of errors
   * @returns an operator to be applied on observables
   */
  catchErrorAndNotify<T>(message: string): (source$: Observable<T>) => Observable<T> {
    return (source$: Observable<T>) => {
      return source$.pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || message);
          return throwError(() => err);
        }),
      );
    };
  }
}
