import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndexedIndexTO } from 'app/modules/common/business/index/models/index.model';
import { environment } from 'environments/environment';

/**
 * Service for currency related operations
 */
@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(private http: HttpClient) {}

  /**
   * Search for currencies.
   *
   * @param searchTerm term to search for
   * @returns observable that emits the list of currencies found when the server responds
   */
  searchCurrencies(searchTerm: string) {
    return this.http.get<IndexedIndexTO[]>(`${environment.apiUrl}/currency/search`, {
      params: {
        searchTerm,
      },
    });
  }
}
