import { User } from 'app/modules/common/business/user/user.model';
import { AccountKeyTO } from 'app/modules/routes/import/models/import.model';
import { Country } from '../../address/model/country.model';
import { Client } from '../../client/model/client.model';
import { ClientConsultantViewTO } from '../../client/model/consultant.model';
import { PortfolioClass, PortfolioTO } from '../../portfolio/model/portfolio.model';

/**
 * Model of account with fields required for search liste.
 */
export interface AccountListItem {
  idtContact: number;
  idtAccount: number;
  name: string;
  clientName: string;
  portfolioTicker: string;
  type: string;
  lead: string;
  leadBackup: string;
  idtPerson?: number;
  idtCompany?: number;
}

/**
 * The account type model.
 */
export interface AccountType {
  idtAccountType: number;
  name: string;
}

/**
 * Entire account model, with all fields and associated entities.
 */
export interface Account {
  idtAccount?: number;
  name: string;
  type?: AccountType;
  key?: string;
  keys: AccountKeyTO[];
  idtPortfolio: number;
  portfolio?: PortfolioTO;
  idtClass: number;
  portfolioClass?: PortfolioClass;
  idtSeries: number;
  portfolioSeries?: PortfolioClass;
  client?: Client;
  consultantView?: ClientConsultantViewTO;

  effectiveManagementFee: number;
  effectiveIncentiveFee: number;
  sideLetter: boolean;
  sideLetterSummary: string;
  erisaStatus: string;
  finra5131: string;
  blueSkyState: string;
  newIssueSummary: string;
  formPf: string;
  shareRebate: boolean;
  newIssueStatus: string;
  region?: Region;
  version: number;
  idtClient: number;
  idtType?: number;
  idtLead: number | null;
  lead?: User;
  idtLeadBackup: number | null;
  leadBackup?: User;
  idtPortfolioManager?: number;
  portfolioManager?: User;
  idtRegion: number;
  inactive: boolean;
  sidePocket: boolean;
  voting: boolean;
  idtCountry: number;
  initialInvestment: Date;
  country?: Country;
  ocio: boolean;
}

/**
 * Represents a category.
 */
export interface Category {
  idtClientCategory: number;
  name: string;
}

/**
 * Represents a region object.
 */
export interface Region {
  idtRegion: number;
  name: string;
}

/**
 * The management enum options.
 */
export enum ManagementSystemEnum {
  CITCO = 'CITCO',
  UMB = 'UMB',
  ABS = 'ABS',
}

/**
 * Enum with finra options.
 */
export enum Finra5131Enum {
  EXEMPT = 'Exempt',
  RESTRICT = 'Restrict',
  NON_RESTRICT = 'Non Restrict',
}

/**
 * Investor type options.
 */
export enum InvestorTypeEnum {
  TAFT_HARTLEY = 'TAFT_HARTLEY',
  RIA = 'RIA',
  EEF = 'EEF',
  CORPORATE = 'CORPORATE',
  PUBLIC = 'PUBLIC',
  CONSULTANT = 'CONSULTANT',
}

export interface Currency {
  idtCurrency: number;
  abbreviation: string;
}

/**
 * Data from indexed accounts.
 */
export interface AccountIndexedDTO {
  idtAccount: number;
  idtContact: number;
  name: string;
}
