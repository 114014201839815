import { Pipe, PipeTransform } from '@angular/core';

/**
 * Splits a value by given separator and
 * optionally returns a value by position
 */
@Pipe({
  name: 'splitBy',
})
export class SplitByPipe implements PipeTransform {
  transform(value: string, separator: string, position?: number): string[] | any {
    if (!value) {
      return [];
    }
    const splitValue = value.split(separator);

    if (position !== undefined && splitValue[position]) {
      return splitValue[position];
    }

    return splitValue;
  }
}
