import { Component, Inject, signal } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { ObservableUtilsService, trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { switchMap, tap } from 'rxjs/operators';
import { AccountService } from '../../../account/services/account.service';
import { ContactTypeEnum } from '../../model/contact.model';
import { TagTO } from '../../model/tag.model';
import { ContactService } from '../../services/contact.service';
import { TagService } from '../../services/tag.service';

@Component({
  selector: 'app-contact-additional-info-edit',
  templateUrl: './contact-additional-info-edit.component.html',
  styleUrls: ['./contact-additional-info-edit.component.scss'],
})
export class ContactAdditionalInfoEditComponent {
  /**
   * The id of the contact.
   */
  private idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    giftContactType: new FormControl<string | null>(null),
    contactKey: new FormControl<string | null>(null),
    contactNotes: new FormControl<string | null>(null),
    idtCategory: new FormControl<number | null>(null),
    idtSubcategory: new FormControl<number | null>(null),
    spouseName: new FormControl<string | null>(null),
    shirtSize: new FormControl<string | null>(null, Validators.maxLength(32)),
    tags: this.fb.group({}),
  });

  /**
   * The list of categories and subcategories.
   */
  categories$ = this.accountService.getCategories();
  subcategories$ = this.accountService.getSubcategories();

  type!: ContactTypeEnum;

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * All tags configured in the system.
   */
  tags: TagTO[] = [];

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    private accountService: AccountService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; includeNew: boolean },
    private dialogRef: MatDialogRef<ContactAdditionalInfoEditComponent>,
    private observableUtils: ObservableUtilsService,
    private tagService: TagService,
  ) {
    this.idtContact = data.idtContact;
    this.getData();
    this.getTags();
  }

  /**
   * Get the phones from the server.
   */
  private getData(): void {
    this.contactService
      .getInfoById(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((contact) => {
          this.type = contact.type;
          this.formGroup.patchValue(contact);
        }),
      )
      .subscribe();
  }

  /**
   * Get the tags configured in the system.
   * Also get the values for all tags.
   */
  private getTags() {
    this.tagService
      .getTags()
      .pipe(
        tap((tags) => {
          this.tags = tags;

          tags.forEach((tag) => {
            this.formGroup.controls.tags.addControl(tag.idtTag.toString(), new FormControl<string[]>([], { nonNullable: true }));
          });
        }),
        switchMap(() => {
          return this.contactService.getTagValues(this.idtContact);
        }),
        tap((tagValues) => {
          for (const [key, value] of Object.entries(tagValues)) {
            this.getFormControlForTag(+key)!.setValue(value);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Get the form control instance for the provided tag.
   *
   * @param idtTag the tag id
   * @returns the form control for the provided tag
   */
  getFormControlForTag(idtTag: number): FormControl<string[]> {
    return this.formGroup.controls.tags.get(idtTag.toString())! as FormControl<string[]>;
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup?.markAllAsTouched();

    return this.formGroup?.valid || true;
  }

  save(): void {
    if (!this.saving() && this.validate()) {
      const value = this.formGroup.getRawValue();

      this.contactService
        .updateAdditionalData(this.idtContact, value)
        .pipe(
          trackLoading(this.saving),
          this.observableUtils.catchErrorAndNotify("Couldn't save the contact's additional info"),
          tap(() => {
            this.dialogService.showSuccess("Contact's additional info saved successfully");
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
