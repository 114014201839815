@if (!showChip) {
  <div class="tab-tools abs-margin-right"></div>

} @else {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close" />
        </button>
      </mat-chip>
    }
    @if (!!asOf) {
      <mat-chip-option>as of {{ asOf.toLocaleString() }}</mat-chip-option>
    }
  </mat-chip-listbox>
}

<app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
  <cdk-virtual-scroll-viewport class="tw-flex tw-flex-1 tw-flex-col" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="2" [height]="44" [loading]="loading()" [items]="20" />

    <app-custom-list-item
      *cdkVirtualFor="let consultant of $consultants | async; trackBy: trackConsultants"
      [selected]="selectedConsultant?.idtExternalRep === consultant.idtExternalRep"
      (click)="select(consultant)"
    >
      <div class="tw-flex tw-max-w-full tw-flex-1">
        <div class="column-name tw-flex tw-flex-1 tw-flex-col">
          <span>
            <div class="tw-flex">
              <app-contact-context-menu
                class="abs-bold abs-text-overflow-ellipsis"
                [matTooltip]="consultant.name"
                [displayText]="consultant.name"
                [id]="consultant.idtExternalRep"
              />
            </div>
          </span>
          <span class="abs-color-faded" [ngPlural]="consultant.clients">
            <ng-template ngPluralCase="=1">1 client</ng-template>
            <ng-template ngPluralCase="other">{{ consultant.clients }} clients</ng-template>
          </span>
        </div>
      </div>
      <div class="column-amount tw-flex tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
        <span>{{ consultant.totalConsultant | currency }}</span>
        <span class="abs-color-faded">{{ consultant.allocation / 100 | percent: '.2' }}</span>
      </div>
      <mat-divider />
    </app-custom-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
