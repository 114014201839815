import { ChangeDetectionStrategy, Component, DestroyRef, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormControlStatus } from '@angular/forms';

/**
 * A slide toggle whose value is inverted (checked means false, unchecked means true).
 */
@Component({
  selector: 'app-inverted-toggle',
  templateUrl: './inverted-toggle.component.html',
  styleUrl: './inverted-toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvertedToggleComponent implements OnInit {
  /**
   * The form control.
   */
  control = input.required<FormControl<boolean>>();

  /**
   * The form control holding the inverted value.
   */
  invertedControl = new FormControl<boolean>(false);

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit() {
    this.toggleEnable(this.control().status);

    this.control().statusChanges.subscribe((status) => {
      this.toggleEnable(status);
    });

    // Set initial value
    this.invertedControl.setValue(!this.control().value);

    // Setup value change listeners to make sure both controls are always updated.
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.invertedControl.setValue(!value, { emitEvent: false });
      });

    this.invertedControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      this.control().setValue(!value, { emitEvent: false });
    });
  }

  /**
   * Match the inverted controls status to the main control status
   * @param status the status of the main control
   */
  private toggleEnable(status: FormControlStatus) {
    if (status === 'DISABLED') {
      this.invertedControl.disable();
    } else {
      this.invertedControl.enable();
    }
  }
}
