import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Chip } from '../model/chip.model';

/**
 * Generic chip list
 */
@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent {
  @Input({ required: true })
  chipData!: Chip[];

  @Output()
  clicked = new EventEmitter<Chip>();

  /**
   * On chip click
   *
   * @param chip the clicked chip values
   */
  onClick(chip: Chip): void {
    this.clicked.emit(chip);
  }
}
