<div class="tab-tools abs-margin-right tw-flex tw-items-center">
  @if (!selectedLog) {
    @if (showChip) {
      <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
        @if (portfolio) {
          <mat-chip (removed)="removePortfolio()">
            {{ portfolio.ticker }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close" />
            </button>
          </mat-chip>
        }
        @if (client) {
          <mat-chip (removed)="removeClient()">
            {{ client.name }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close" />
            </button>
          </mat-chip>
        }
        @if (consultant) {
          <mat-chip (removed)="removeConsultant()">
            {{ consultant.name }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close" />
            </button>
          </mat-chip>
        }
        @if (opportunity) {
          <mat-chip (removed)="removeOpportunity()">
            Opportunity for {{ opportunity.contactName || opportunity.consultantCompanyName }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close" />
            </button>
          </mat-chip>
        }
        @if (opportunityConsultant) {
          <mat-chip (removed)="removeOpportunity()">
            Opportunities for {{ opportunityConsultant.name }}
            <button matChipRemove>
              <mat-icon fontIcon="mdi-close" />
            </button>
          </mat-chip>
        }
      </mat-chip-listbox>
    }
    <div class="tw-flex tw-flex-1"></div>
    <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Filter logs">
      <mat-icon fontIcon="mdi-filter-variant" />
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="setLogType()" mat-menu-item>
        <div class="tw-flex">
          <div class="abs-margin-right tw-flex tw-flex-1">All Logs</div>
          <span>({{ logCount }})</span>
        </div>
      </button>
      @for (logTotal of $logTotals | async; track logTotal) {
        <button (click)="setLogType(logTotal.type)" mat-menu-item>
          <div class="tw-flex">
            <div class="abs-margin-right tw-flex tw-flex-1">{{ getLogTypeLabel(logTotal.type) }}</div>
            <span>({{ logTotal.total }})</span>
          </div>
        </button>
      }
    </mat-menu>
  }
</div>

<app-log-list
  class="tw-flex tw-flex-1 tw-flex-col"
  [logData]="logData"
  (clicked)="onLogClick($event)"
  [ngClass]="{
    '!tw-hidden': selectedLog,
  }"
/>

@if (selectedLog) {
  <app-log-details class="tw-flex tw-flex-1 tw-flex-col" [log]="selectedLog" (closed)="closeLog()" />
}
