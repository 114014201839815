@if (hasEstimates) {
  <app-alert-box [showIcon]="false">Contains estimated returns, denoted with an *</app-alert-box>
}

<div fxLayout="row" class="header" style="padding-right: 6px" matSort (matSortChange)="sortChange($event)" [ngClass]="{ grouped: !!groupBy }">
  <div fxFlex class="abs-container-gt-xs"></div>
  <div class="data" fxLayoutAlign="start end">
    <div class="date" mat-sort-header="referenceDate">AS OF</div>
    <div class="balance" mat-sort-header="endBalance" arrowPosition="before">AUM</div>
    <div fxFlex class="xtd" mat-sort-header="mtd" arrowPosition="before">MTD</div>
    <div fxFlex class="xtd" mat-sort-header="qtd" arrowPosition="before">QTD</div>
    <div fxFlex class="xtd" mat-sort-header="ytd" arrowPosition="before">YTD</div>
    <div fxFlex class="xtd" matTooltip="Inception to date (Annualized)" mat-sort-header="itd" arrowPosition="before">
      <span>ITD</span>
      <sup>A</sup>
    </div>
    <div fxFlex class="xtd" matTooltip="Inception to date (Cumulative)" mat-sort-header="itdCumulative" arrowPosition="before">
      <span>ITD</span>
      <sup>C</sup>
    </div>
    <div fxFlex="18px"></div>
  </div>
</div>

<mat-divider />

@if (groupBy) {
  <app-scrollable fxFlex>
    <mat-accordion multi fxFlex displayMode="flat" #accordion="matAccordion">
      @for (accounts of groupedAccounts | keyvalue: sortGroups; track accounts.key) {
        <mat-expansion-panel class="abs-nopadding-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="abs-overflow-hidden">
              <div fxFlex class="abs-bold abs-overflow-hidden">
                @if (groupBy === 'contactName') {
                  <app-contact-context-menu [displayText]="accounts.key" [id]="accounts.value.accounts[0].idtContact" />
                } @else if (groupBy === 'portfolioTicker') {
                  <app-portfolio-context-menu
                    [displayText]="accounts.key"
                    [id]="accounts.value.accounts[0].idtPortfolio"
                  />
                }
              </div>
              <div>
                <app-positive-negative [value]="accounts.value.balance">
                  @if (!isMissingEstimates(accounts.value)) {
                    <span class="abs-container-xs">{{ accounts.value.balance | nearUnitNumber: '0.0-0' }}</span>
                    <span class="abs-container-gt-xs">{{ accounts.value.balance | number: '0.0-0' }}</span>
                    @if (accounts.value.hasEstimates) {
                      <span>*</span>
                    }
                  } @else {
                    <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing estimates in ABSolute" />
                  }
                </app-positive-negative>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="abs-margin-double-bottom">
              @for (account of accounts.value.accounts; track account.idtAccount) {
                <app-account-performance-list-item [groupBy]="groupBy" [account]="account" [refDate]="refDate" />
              }
            </div>
          </ng-template>
        </mat-expansion-panel>
      }
    </mat-accordion>
  </app-scrollable>
} @else {
  <app-scrollable fxFlex>
    @for (account of accounts; track trackAccount($index, account)) {
      <app-account-performance-list-item [account]="account" [refDate]="refDate" [isConsultant]="isConsultant" />
    }
  </app-scrollable>
}
