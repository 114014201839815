import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms value in yes or no
 */
@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      return 'Yes';
    }
    return 'No';
  }
}
