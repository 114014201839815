<div id="print-header" class="abs-margin-bottom">
  <div class="tw-flex tw-flex-row">
    <div class="tw-flex-1">
      <h1 id="report-title">{{ title() }}</h1>

      <ng-content select="[beforeDate]" />

      <div>Generated at {{ reportDate() | date: 'MM/dd/y hh:mm a' }} by {{ (currentUser$ | async)?.initials }}</div>

      <ng-content />
    </div>

    <div class="tw-w-[200px]">
      <img src="assets/images/ABS_Logo_Horiz.png" />
    </div>
  </div>

  <mat-divider class="abs-margin-top" />
</div>
