import { Component, ElementRef, Inject, QueryList, ViewChildren, signal } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { ObservableUtilsService, trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { tap } from 'rxjs/operators';
import { ContactRelationshipTO, ContactViewTO } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';
import {
  ContactRelationshipFormComponent,
  ContactRelationshipFormGroup,
} from '../contact-create-edit/contact-relationship-form/contact-relationship-form.component';

@Component({
  selector: 'app-contact-relationship-edit',
  templateUrl: './contact-relationship-edit.component.html',
  styleUrls: ['./contact-relationship-edit.component.scss'],
})
export class ContactRelationshipEditComponent {
  /**
   * The id of the contact.
   */
  private idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    relationships: new FormArray<ContactRelationshipFormGroup>([]),
  });

  /**
   * Get the formArray with the data.
   */
  get formArray() {
    return this.formGroup.controls.relationships;
  }

  private relationships: ContactRelationshipTO[] = [];

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * Reference to the form components created for each relationship.
   */
  @ViewChildren(ContactRelationshipFormComponent, { read: ElementRef })
  formComponents!: QueryList<ElementRef>;

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; includeNew: boolean },
    private dialogRef: MatDialogRef<ContactRelationshipEditComponent>,
    private observableUtils: ObservableUtilsService,
  ) {
    this.idtContact = data.idtContact;
    this.getRelationships(data.includeNew);
  }

  /**
   * Get the relationships from the server.
   */
  private getRelationships(includeNew: boolean): void {
    this.contactService
      .getRelationships(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((relationship) => {
          this.relationships = relationship;

          this.relationships.forEach((c) => {
            this.addContactRelationshipForm(c);
          });
        }),
        tap(() => {
          if (includeNew) {
            this.addContactRelationshipForm();
          }
        }),
      )
      .subscribe();
  }

  /**
   * Adds a new contact relationship form group in the form array
   */
  addContactRelationshipForm(relationship?: ContactRelationshipTO): void {
    const form = ContactRelationshipFormComponent.buildForm(relationship);
    this.formArray.push(form);

    // If creating a new email, after the form component is created, scroll it into the visible area
    if (!relationship) {
      setTimeout(() => {
        this.formComponents.last.nativeElement.scrollIntoView();
      });
    }
  }

  /**
   * Remove am entity from the list.
   *
   * @param index the index to be removed
   */
  remove(index: number) {
    this.formArray.removeAt(index);
    this.formGroup.markAsDirty();
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }

  /**
   * Get the contact referenced in an already saved relationship.
   *
   * @param index the index in the already saved relationships
   * @returns the contact of that relationship or undefined
   */
  getContactFromRelationship(index: number): ContactViewTO | undefined {
    return this.relationships[index]?.contactTo;
  }

  save(): void {
    if (!this.saving() && this.validate()) {
      const relationships: ContactRelationshipTO[] = this.formArray.getRawValue();

      relationships.forEach((r) => {
        r.idtContactFrom = this.idtContact;
      });

      this.contactService
        .updateRelationships(this.idtContact, relationships)
        .pipe(
          trackLoading(this.saving),
          this.observableUtils.catchErrorAndNotify("Couldn't save the contact relationships"),

          tap(() => {
            this.dialogService.showSuccess('Contact relationships saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
