<div class="tab-tools abs-margin-right tw-flex tw-items-center tw-justify-end">
  <span style="margin-right: 5px">as of</span>
  <mat-form-field class="as-of-datepicker labelless" subscriptSizing="dynamic">
    <input matInput [matDatepicker]="picker" [formControl]="dateControl" [max]="maxDate" />
    <mat-datepicker-toggle matSuffix [for]="picker" />
    <mat-datepicker #picker startView="year" (monthSelected)="monthSelected($event, picker)" />
  </mat-form-field>
</div>

<app-custom-list class="abs-scroll-auto-y tw-flex tw-flex-1 tw-flex-col" [ngClass]="{ 'abs-scroll-hidden': loading() }">
  <!-- Skeleton items while loading -->
  <app-list-skeleton-loader [lines]="2" [height]="44" [items]="20" [loading]="loading()" />

  <!-- Totalization line -->
  @if (total) {
    <app-custom-list-item [selected]="!selectedPortfolio" (click)="select()">
      <div class="tw-flex tw-flex-1">
        <div class="tw-flex tw-flex-1 tw-flex-col">
          <span class="abs-bold">TOTAL</span>
          <span class="abs-color-faded">{{ total.firmClients }} clients</span>
        </div>
        <div class="tw-flex tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
          <span>{{ total.totalFirm | currency }}</span>
          <span class="abs-color-faded">{{ 1 | percent: '.2' }}</span>
        </div>
      </div>
      <mat-divider />
    </app-custom-list-item>
  }

  <!-- Portfolios -->
  @for (portfolio of portfolios$ | async; track portfolio.idtPortfolio) {
    <app-custom-list-item [selected]="selectedPortfolio?.idtPortfolio === portfolio.idtPortfolio" (click)="select(portfolio)">
      <div class="tw-flex tw-flex-1">
        <div class="tw-flex tw-flex-1 tw-flex-col">
          <app-portfolio-context-menu class="abs-bold" [id]="portfolio.idtPortfolio" [displayText]="portfolio.ticker" />
          <span class="abs-color-faded" [ngPlural]="portfolio.portfolioClients">
            <ng-template ngPluralCase="=1">1 client</ng-template>
            <ng-template ngPluralCase="other">{{ portfolio.portfolioClients }} clients</ng-template>
          </span>
        </div>
        <div class="tw-flex tw-flex-1 tw-flex-col tw-items-end tw-justify-center">
          <span>{{ portfolio.totalPortfolio | currency }}</span>
          <span class="abs-color-faded">{{ portfolio.portfolioAllocation / 100 | percent: '.2' }}</span>
        </div>
      </div>
      <mat-divider />
    </app-custom-list-item>
  }
</app-custom-list>
