/**
 * Represents the channel of a contact
 */
export interface ContactChannel {
  idtContactChannel?: number;
  idtContact: number;
  channel: ContactChannelEnum;
  type: ContactChannelTypeEnum;
  value: string;
  defaultChannel: boolean;
}

/**
 * Represents the Enum of channel
 */
export enum ContactChannelEnum {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

/**
 * Represents the Enum of channel type
 */
export enum ContactChannelTypeEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
  FAX = 'FAX',
}
