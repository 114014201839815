import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PagedRenderingDataSource } from 'app/modules/common/framework/pagination/paged-rendering-datasource';
import { get } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Column } from '../../model/column.model';
import { EntityTypeEnum } from '../../model/entity-type-enum';
import { EntityTemplateService } from '../../services/entity-template.service';
import { EntityTableFilterComponent, Filter } from '../entity-table-filter/entity-table-filter.component';

/**
 * Component for the entity tables.
 */
@Component({
  selector: 'app-entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.scss'],
})
export class EntityTableComponent implements OnInit, OnChanges {
  /**
   * The data to be displayed.
   */
  @Input()
  dataSource!: PagedRenderingDataSource<any>;

  /**
   * Observable with the totals for summable columns.
   */
  @Input()
  totals?: Record<string, number> | null;

  /**
   * Whther to show filter or not.
   */
  @Input()
  showFilters = true;

  /**
   * Allows filtering the filters autocomplete by portfolio id.
   */
  @Input()
  portfolioFilter?: number;

  /**
   * Allows filtering the filters autocomplete by class id.
   */
  @Input()
  classFilter?: number;

  /**
   * The displayed columns.
   */
  displayedColumns$!: Observable<Column[]>;

  /**
   * The displayed columns paths only.
   */
  displayedColumnsPaths: string[] = [];

  /**
   * The index colun to be pinned.
   */
  pinIndex: number | null = null;

  /**
   * The columns to display a filter.
   */
  displayedFilterColumns: string[] = [];

  /**
   * Object with the value of each filter.
   */
  filters: Record<string, string> = {};

  /**
   * When the sorting changes.
   */
  @Output()
  sortChange = new EventEmitter<Sort>();

  /**
   * Emits the selected filters.
   */
  @Output()
  filterChange = new EventEmitter<Record<string, string>>();

  /**
   * The entity type.
   */
  @Input()
  type!: EntityTypeEnum;

  /**
   * The filter components.
   */
  @ViewChildren(EntityTableFilterComponent)
  filterComponents?: QueryList<EntityTableFilterComponent>;

  subscription?: Subscription;

  /**
   * Funtion to build the filter string to apply to each of the filter when loading options.
   *
   * @returns the filter to apply
   */
  @Input()
  buildFilter: (ignoreColumn: string) => string = () => '';

  constructor(
    private templateService: EntityTemplateService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.displayedColumns$ = this.templateService.selectedColumns[this.type];

    // define columns and filters to show, discarding atributes that may no longer exist (outdated templates)
    this.templateService.selectedColumns[this.type]
      .pipe(
        take(1),
        tap((columns) => {
          this.displayedColumnsPaths = [
            'actions',
            ...(this.templateService.template[this.type]?.columns || []).filter((templateColumn) => columns.some((c) => c.field === templateColumn)),
          ];
          this.displayedFilterColumns = this.displayedColumnsPaths.map((c) => `filter-${c}`);
        }),
      )
      .subscribe();
  }

  /**
   * Angular lifecycle hook method, called on input changes.
   */
  ngOnChanges(): void {
    this.subscription?.unsubscribe();

    if (this.dataSource) {
      // Repin the column on every data change to make sure the proper column width is used.
      this.subscription = this.dataSource.connect().subscribe(() => {
        this.pinIndex = null;
        this.cd.detectChanges();

        // Get pinned columns from the session storage
        this.pinIndex = JSON.parse(sessionStorage.getItem(`${this.type}-pinIndex`) || 'null');
      });
    }
  }

  /**
   * Emits event with new sort configuration.
   *
   * @param sort the new sort configuration
   */
  onSortChange(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  /**
   * Get the value for the given path in the given account entry.
   *
   * @param entity a single account entity
   * @param path the path to the value needed
   */
  getValue(entity: any, column: Column): string {
    return this.templateService.getEntityValue(entity, column);
  }

  /**
   * Transforms a value using the defined field transformations.
   *
   * @param value the value to transform
   * @param column the columns with the transform definitions
   * @returns the value after applying transformations
   */
  transformValue(value: any, column: Column): string {
    return this.templateService.getValue(value, column);
  }

  /**
   * Get the id of the entity related to the provided column, if that column metamodel includes the required definitions.
   *
   * @param entity the entity
   * @param column the column metamodel definition
   * @returns the id of the entity referenced by the column
   */
  getId(entity: any, column: Column): string {
    if (column.idField) {
      return get(entity, column.idField) as string;
    }

    return '';
  }

  /**
   * Get the proper path to redirect when clicking the provided column field.
   *
   * @param entity the entity
   * @param column the column difinition
   * @returns the proper path for this column clicks
   */
  getPath(entity: any, column: Column): string {
    if (column.entityType) {
      if (column.entityType === 'contact') {
        return '/contact';
      }
    }

    return '';
  }

  /**
   * Verifies if a column is numeric. Used to apply proper cell alignment.
   *
   * @param column the column to verify
   * @returns true if the column is numeric.
   */
  isNumeric(column: Column): boolean {
    return ['decimal', 'long'].includes(column.fieldType);
  }

  /**
   * After a filter value is changed, emit the newly selected values.
   *
   * @param value the value to add to filter request
   */
  filterChanged(value: Filter): void {
    this.filters[value.column.field] = value.filter;

    this.filterChange.emit({ ...this.filters });
  }

  /**
   * Verify if a column should be pinned.
   *
   * @param column the column to verify
   * @returns true, if the column should be pinned
   */
  isPinned(column: Column): boolean {
    if (this.pinIndex != null) {
      const index = this.displayedColumnsPaths.indexOf(column.field);

      return index <= this.pinIndex;
    }

    return false;
  }

  /**
   * Verify is the provided column is the last one to be pinned.
   *
   * @param column the column to verify
   * @returns true, if the column is the last one to be pinned
   */
  isLastPinned(column: Column): boolean {
    if (this.pinIndex != null) {
      const index = this.displayedColumnsPaths.indexOf(column.field);

      return index === this.pinIndex;
    }

    return false;
  }

  /**
   * Toggle pinning the provided column.
   *
   * @param event the click event
   * @param column the selected column
   */
  togglePin(event: Event, column: Column): void {
    event.stopPropagation();

    if (this.isLastPinned(column)) {
      this.pinIndex = null;
    } else {
      const index = this.displayedColumnsPaths.indexOf(column.field);

      this.pinIndex = index;
    }

    // Update the saved pinned columns
    sessionStorage.setItem(`${this.type}-pinIndex`, JSON.stringify(this.pinIndex));
  }

  /**
   * Clear all filters.
   */
  clearFilters(): void {
    this.filters = {};

    this.filterComponents?.forEach((f) => {
      f.clear();
    });
  }

  /**
   * Go to the details page for the provided entity.
   *
   * @param entity the entity data
   */
  goToDetails(entity: any): void {
    this.templateService.goToDetails(this.type, entity);
  }
}
