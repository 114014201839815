import { Component, ElementRef, Inject, QueryList, ViewChildren, signal } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { ObservableUtilsService, trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { tap } from 'rxjs/operators';
import { ContactChannel, ContactChannelEnum } from '../../model/contact-channel.model';
import { ContactService } from '../../services/contact.service';
import { ContactChannelForm, ContactChannelFormComponent } from '../contact-create-edit/contact-channel-form/contact-channel-form.component';

@Component({
  selector: 'app-contact-phone-edit',
  templateUrl: './contact-phone-edit.component.html',
  styleUrls: ['./contact-phone-edit.component.scss'],
})
export class ContactPhoneEditComponent {
  ContactChannelEnum = ContactChannelEnum;

  /**
   * The id of the contact.
   */
  private idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    phones: new FormArray<ContactChannelForm>([]),
  });

  /**
   * Get the formArray with the data.
   */
  get formArray() {
    return this.formGroup.controls.phones;
  }

  private phones?: ContactChannel[];

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * Reference to the form components created for each consultant.
   */
  @ViewChildren(ContactChannelFormComponent, { read: ElementRef })
  formComponents!: QueryList<ElementRef>;

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number; includeNew: boolean },
    private dialogRef: MatDialogRef<ContactPhoneEditComponent>,
    private observableUtils: ObservableUtilsService,
  ) {
    this.idtContact = data.idtContact;
    this.getPhones(data.includeNew);
  }

  /**
   * Get the phones from the server.
   */
  private getPhones(includeNew: boolean): void {
    this.contactService
      .getChannels(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((channels) => {
          this.phones = channels.filter((c) => c.channel === ContactChannelEnum.PHONE);

          for (const email of this.phones) {
            this.addContactPhoneForm(email);
          }
        }),
        tap(() => {
          if (includeNew) {
            this.addContactPhoneForm();
          }
        }),
      )
      .subscribe();
  }

  /**
   * Get a single phone data.
   *
   * @param index the index of the phone in the list
   * @returns the phone found or null
   */
  getPhone(index: number): ContactChannel | null {
    if (this.phones) {
      return this.phones[index];
    }

    return null;
  }

  /**
   * Adds a new contact phone form group in the form array.
   */
  addContactPhoneForm(contactPhone: ContactChannel | null = null): void {
    const form = ContactChannelFormComponent.getContactChannelForm(contactPhone);

    if (!contactPhone) {
      form.patchValue({ channel: ContactChannelEnum.PHONE });
    }

    this.formArray.push(form);

    // If creating a new phone, after the form component is created, scroll it into the visible area
    if (!contactPhone) {
      setTimeout(() => {
        this.formComponents.last.nativeElement.scrollIntoView();
      });
    }
  }

  /**
   * Remove am entity from the list.
   *
   * @param index the index to be removed
   */
  remove(index: number) {
    this.formArray.removeAt(index);
    this.formGroup.markAsDirty();
  }

  /**
   * Changes the flag to false when it is different from index and is true.
   * @param index the index from formArray of flag
   * @param field the field of the form to set the value
   */
  handleFormFieldFlag(index: number, field: string): void {
    const formArray = this.formArray;

    for (let i = 0; i < formArray.length; i++) {
      if (i !== index && formArray?.controls[i]?.get(field)?.value) {
        formArray.controls[i].get(field)?.setValue(false);
      }
    }
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }

  save(): void {
    if (!this.saving() && this.validate()) {
      const phones = this.formArray.getRawValue();

      // Format the phone object
      const phonesInput: ContactChannel[] = phones.map((phone) => {
        return {
          ...phone,
          idtContact: phone.idtContact!,
          channel: phone.channel!,
          type: phone.type!,
        };
      });

      this.contactService
        .saveChannels(this.idtContact, phonesInput, ContactChannelEnum.PHONE)
        .pipe(
          trackLoading(this.saving),
          this.observableUtils.catchErrorAndNotify("Couldn't save the contact phones"),
          tap(() => {
            this.dialogService.showSuccess('Contact phones saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
