<div mat-dialog-content>
  <div class="tw-flex tw-flex-1 tw-flex-col">
    <!-- Contact Search field -->
    <mat-form-field class="abs-margin vertical-align">
      <input
        matInput
        type="search"
        [placeholder]="type() === ContactTypeEnum.COMPANY ? 'Insert the company name' : 'Insert the contact name'"
        [formControl]="searchField"
        trim="blur"
        cdkFocusInitial
        autocapitalize="words"
      />
    </mat-form-field>

    <span class="abs-margin-left abs-bold">{{ type() === ContactTypeEnum.COMPANY ? 'Existing Companies' : 'Existing Contacts' }}</span>
    <!-- Contact Search result -->
    <app-scrollable class="abs-padding tw-flex tw-flex-1 tw-flex-col">
      @if (contactData$ | async; as contacts) {
        <app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
          @for (contact of contacts; track contact.idtContact) {
            <app-contact-item
              [contact]="contact"
              (click)="closeDialog(contact.idtContact)"
              [handleClicks]="false"
              [showContextMenus]="false"
            />
          } @empty {
            <div class="tw-text-center">No contacts found</div>
          }
        </app-custom-list>
      }
    </app-scrollable>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  @if (searchField.value) {
    @if (type() !== ContactTypeEnum.COMPANY) {
      <button mat-raised-button (click)="createNew(ContactTypeEnum.PERSON)" color="accent">Add New Person</button>
    }
    @if (type() !== ContactTypeEnum.PERSON) {
      <button mat-raised-button (click)="createNew(ContactTypeEnum.COMPANY)" color="accent">Add New Company</button>
    }
  }
</div>
