<app-dialog-title displayTitle="Contact" [loading]="saving() || loading()" />

<mat-dialog-content>
  <form (submit)="contactForm.valid && save()" id="basic-form" [formGroup]="contactForm">
    <div>
      @if (contact()?.type === ContactTypeEnum.PERSON) {
        <div>
          <mat-form-field fxFlex>
            <mat-label>Preferred name</mat-label>
            <input matInput formControlName="salutation" appAutoFocus autocapitalize="words" />
          </mat-form-field>
        </div>
        <div class="tw-flex tw-flex-row tw-gap-1">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <mat-select formControlName="title">
              <mat-option>None</mat-option>
              @for (title of personTitles; track title) {
                <mat-option [value]="title"> {{ title | titlecase }}. </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field class="tw-flex-1">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" required (focusout)="nameChanged()" trim="blur" autocapitalize="words" />
          </mat-form-field>
        </div>
      }
      @if (contact()?.type === ContactTypeEnum.COMPANY) {
        <div class="tw-flex tw-flex-row">
          @if (isBranch()) {
            <mat-form-field fxFlex>
              <mat-label>Branch Name</mat-label>
              <input matInput formControlName="branchName" appAutoFocus autocapitalize="words" />
            </mat-form-field>
          } @else {
            <mat-form-field fxFlex>
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" required appAutoFocus autocapitalize="words" />
            </mat-form-field>
          }
        </div>
      }
      <div class="tw-flex tw-flex-row tw-gap-1">
        <app-user-select class="tw-flex tw-flex-1 tw-flex-col" label="Main lead" [control]="contactForm.controls.idtLead" />
        <app-user-select class="tw-flex tw-flex-1 tw-flex-col" label="Backup lead" [control]="contactForm.controls.idtLeadBackup" />
      </div>

      @if (contact()?.type === ContactTypeEnum.COMPANY) {
        <app-company-autocomplete
          [parentCompaniesOnly]="true"
          label="Parent company"
          [control]="contactForm.controls.idtParentCompany"
          [idtCompanyContact]="contact()?.branch ? contact()?.company?.idtParentContact : null"
        />
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="basic-form">Save</button>
</div>
