import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { PagedRenderingDataSource } from 'app/modules/common/framework/pagination/paged-rendering-datasource';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {
  ContactEmailGroupViewTO,
  ContactGroupSearchViewTO,
  EmailGroupDetailsViewTO,
  EmailGroupTO,
} from '../../../../routes/mailing/model/mailing.model';

@Injectable({
  providedIn: 'root',
})
export class MailingService {
  constructor(private http: HttpClient) {}

  /**
   * Creates a new email group in the server.
   *
   * @param data the email group data
   * @returns an observable that resolves to the email group created
   */
  post(data: EmailGroupDetailsViewTO, cloneFrom?: number): Observable<EmailGroupTO> {
    return this.http.post<EmailGroupTO>(`${environment.apiUrl}/mailing-group`, data, {
      params: {
        ...(cloneFrom && { cloneFrom: cloneFrom?.toString() }),
      },
    });
  }

  /**
   * Updates a email group in the server.
   *
   * @param data the email group data
   * @param id the id of the email group to update
   * @returns an observable that resolves to the email group created
   */
  put(data: EmailGroupDetailsViewTO, id: number): Observable<EmailGroupTO> {
    return this.http.put<EmailGroupTO>(`${environment.apiUrl}/mailing-group/${id}`, data);
  }

  /**
   * Gets a page of email groups.
   *
   * @param page the page number
   * @param size the page size
   * @param filter the filters to apply
   * @returns the page of email groups found, wrapped in an observable
   */
  getPage(filter: string, sort: string | string[], page: number = 0, size: number = 20): Observable<Pageable<EmailGroupDetailsViewTO>> {
    return this.http.get<Pageable<EmailGroupDetailsViewTO>>(`${environment.apiUrl}/mailing-group?filter=${filter}`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        sort,
      },
    });
  }

  /**
   * Get all groups matching the provided criteria.
   *
   * @param filter the filters to apply to the search
   * @param sort the sort property and direction
   * @returns an observable that emits the list when the server responds
   */
  getAll(filter: DataFilter, sort: string | string[]): Observable<EmailGroupDetailsViewTO[]> {
    return this.http.get<EmailGroupDetailsViewTO[]>(`${environment.apiUrl}/mailing-group?filter=${filter.encodeURIComponent()}`, {
      params: {
        sort,
      },
    });
  }

  /**
   * Get pageable data source of distribution groups.
   *
   * @param filter the filters to apply
   * @param limit the size of the page
   * @returns the data source built
   */
  getGroups(filter: DataFilter, sort: string | string[], limit = 20): PageableDataSource<EmailGroupDetailsViewTO> {
    const getPage = this.getPage.bind(this);

    return new (class extends PageableDataSource<EmailGroupDetailsViewTO> {
      fetch(page: number, size: number): Observable<Pageable<EmailGroupDetailsViewTO>> {
        return getPage(filter.encodeURIComponent(), sort, page, size);
      }
    })(limit);
  }

  /**
   * Get the details of an email grooup.
   *
   * @param id the id of the group
   * @returns the email group data wrapped in an observable
   */
  getDetails(idtEmailGroup: number): Observable<EmailGroupDetailsViewTO> {
    return this.http.get<EmailGroupDetailsViewTO>(`${environment.apiUrl}/mailing-group/${idtEmailGroup}/details`);
  }

  /**
   * Get the list of used templates for the provided group.
   *
   * @param idtEmailGroup the id of the group
   * @returns the list of template names wrapped in an observable
   */
  getUsedTemplates(idtEmailGroup: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/mailing-group/${idtEmailGroup}/templates`);
  }

  /**
   * Archive a group.
   *
   * @param idtEmailGroup the id of the email group
   * @returns an observable that resolves to void
   */
  archiveGroup(idtEmailGroup: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/mailing-group/${idtEmailGroup}`);
  }

  /**
   * Create a pageable datasource of contacts that belong to the provided email group.
   *
   * @param id the id of the email group
   * @param size the page size
   * @returns the data source for a paged list
   */
  getContacts(id: number, term: string = '', sort: string = '', size: number = 20): Observable<ContactEmailGroupViewTO[]> {
    const filter = new DataFilter()
      .like(['name', 'companyName', 'email', 'city', 'state', 'country', 'lead', 'leadInitials', 'groupsNames'], `%${term}%`, 'string')
      .equals('idtEmailGroup', id, 'long')
      .encodeURIComponent();

    return this.http.get<ContactEmailGroupViewTO[]>(`${environment.apiUrl}/mailing-group/contacts?filter=${filter}`, {
      params: {
        sort,
        size,
      },
    });
  }

  /**
   * Get list of contacts that already belong to a group, with same data for when adding new contacts.
   *
   * @param idtEmailGroup the id of the email group
   * @param sort the property and direction to sort by
   * @returns the list of contacts found, wrapped in an observable
   */
  searchExistingContacts(idtEmailGroup: number, sort: string): Observable<ContactGroupSearchViewTO[]> {
    return this.http.get<ContactGroupSearchViewTO[]>(`${environment.apiUrl}/mailing-group/search/contacts`, {
      params: {
        sort,
        idtEmailGroup,
      },
    });
  }

  /**
   * Get a data source for contacts matching the provided filters.
   *
   * @param filter the filters to apply
   * @param sort the property and direction to sort by
   * @param limit the size of the pages
   * @returns the data source built
   */
  searchContactsToAdd(
    filter: string,
    sort: string | string[] = ['name,ASC'],
    limit: number = 40,
  ): PagedRenderingDataSource<ContactGroupSearchViewTO> {
    return new PagedRenderingDataSource(this.getContactsToAddPage.bind(this), sort, limit, filter);
  }

  /**
   * Get a page of contacts matching the provided filters.
   *
   * @param page the page to get
   * @param size the size of the page
   * @param sort the property and direction to sort by
   * @param filter the filters to apply to the query
   * @returns the page of contacts found, wrapped in an observable
   */
  getContactsToAddPage(page: number, size: number, sort: string | string[], filter: string = ''): Observable<Pageable<ContactGroupSearchViewTO>> {
    return this.http.get<Pageable<ContactGroupSearchViewTO>>(`${environment.apiUrl}/mailing-group/search/contacts?filter=${filter}`, {
      params: {
        sort,
        page: page.toString(),
        size: size.toString(),
      },
    });
  }

  /**
   * Get all contacts matching the provided criteria.
   *
   * @param filter the filters to apply
   * @return the list of contacts found
   */
  getAllContactsToAdd(filter: string = ''): Observable<ContactGroupSearchViewTO[]> {
    return this.http.get<ContactGroupSearchViewTO[]>(`${environment.apiUrl}/mailing-group/search/contacts?filter=${filter}`);
  }

  /**
   * Add contacts to an email group.
   *
   * @param id the id of the email group
   * @param contacts the id of the contacts
   * @returns an observable that resolves to void after the request is processed
   */
  manageContacts(id: number, add: number[], remove: number[]): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/mailing-group/${id}/contacts`, {
      add,
      remove,
    });
  }

  /**
   * Removes a list of contacts from an email group.
   *
   * @param id the id of the email group
   * @param contacts the list of contacts to remove from the group
   * @returns an observable that resolves to void after the server responds
   */
  removeContacts(id: number, contacts: number[]): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/mailing-group/${id}/contacts`, {
      params: {
        contacts: contacts.map((c) => c.toString()),
      },
    });
  }

  /**
   * Remove a contact from all mail gorups.
   *
   * @param idtContact the contact id
   * @param deleteEmailAndPhone whether to also delete primary email and phone
   * @returns an observable that emits void when the server responds
   */
  removeContactFromAllGroups(idtContact: number, deleteEmailAndPhone: boolean): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/contact/${idtContact}/groups`, {
      params: { deleteEmailAndPhone },
    });
  }

  /**
   * Export a list of contacts from an email group to an excel spreadsheet.
   *
   * @param id the id of the email group
   * @param term a search term to filter the results by
   */
  export(id: number, term: string): Observable<Blob> {
    const filter = encodeURIComponent(`idtEmailGroup|eq|${id}|long,name+companyName+email+city+state+country|like|%${term}%|string`);

    return this.http.post<Blob>(`${environment.apiUrl}/mailing-group/export?filter=${filter}`, {}, { responseType: 'blob' as 'json' });
  }

  /**
   * Search for group tags based on a search term.
   *
   * @param includeArchived whether to include tags used in archived groups
   * @returns the tags that have the provided term in their names
   */
  searchTags(includeArchived: boolean): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/mailing-group/tags`, {
      params: {
        includeArchived,
      },
    });
  }
}

/**
 * Resolve service to get a group details based on the route id.
 */
export const groupResolver: ResolveFn<EmailGroupDetailsViewTO> = (route: ActivatedRouteSnapshot) => {
  return inject(MailingService).getDetails(Number(route.paramMap.get('idtEmailGroup')));
};
