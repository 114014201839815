import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import { PageableDataSource } from 'app/modules/common/framework/pagination/pageable-datasource';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { TaskCountPerStatus, TaskCreateInputDTO, TaskStatusEnum, TaskType, TaskTypes, TaskViewTO } from '../models/task.model';

/**
 * Service for task (action items) related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class TaskService {
  /**
   * Task type options.
   */
  taskTypes = TaskTypes;

  constructor(private http: HttpClient) {}

  /**
   * Send request to create a task in the server.
   *
   * @param data the data to create the task
   * @returns an observable that emits void when the server responds
   */
  create(data: TaskCreateInputDTO): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/task`, data);
  }

  /**
   * Send request to update an existing task.
   *
   * @param idtTask the task id
   * @param data the task data to be saved
   * @returns an observable that emits void when the task is saved
   */
  update(idtTask: number, data: TaskCreateInputDTO): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/task/${idtTask}`, data);
  }

  /**
   * Get tasks datasource.
   *
   * @param idtAssignees list of assigness, must be assigned to all of them
   * @param [idtCreationUser=null] optional user that created the task
   * @param [idtContact=null] optional contact to filter
   * @param [idtPortfolio=null] optional portfolio to filter
   * @param [dueStatus=null] optional due status filter
   * @param [statusFilter=TaskStatusEnum.IN_PROGRESS] status filter
   * @param sort sort columns and direction
   * @returns a datasource to search for tasks according to the provided filters
   */
  getTasksDatasource(
    idtAssignees: number[] = [],
    idtCreationUser: number | null = null,
    idtContact: number | null = null,
    idtPortfolio: number | null = null,
    dueStatus: string | null = null,
    statusFilter: TaskStatusEnum = TaskStatusEnum.IN_PROGRESS,
    sort: string | string[] = ['NUM_DUE_STATUS_ORDER,ASC', 'DTE_DUE,ASC'],
  ): PageableDataSource<TaskViewTO> {
    const http = this.http;

    return new (class extends PageableDataSource<TaskViewTO> {
      fetch(page: number, size: number): Observable<Pageable<TaskViewTO>> {
        return http.get<Pageable<TaskViewTO>>(`${environment.apiUrl}/task`, {
          params: {
            idtAssignees,
            ...(idtCreationUser != null && { idtCreationUser }),
            ...(idtContact != null && { idtContact }),
            ...(idtPortfolio != null && { idtPortfolio }),
            ...(dueStatus != null && { dueStatus }),
            status: statusFilter,
            sort,
            page,
            size,
          },
        });
      }
    })(50);
  }

  /**
   * Get total tasks per due status.
   *
   * @param idtAssignees list of assigness, must be assigned to all of them
   * @param [idtCreationUser=null] optional user that created the task
   * @param [idtContact=null] optional contact to filter
   * @param [idtPortfolio=null] optional portfolio to filter
   */
  getTasksCount(
    idtAssignees: number[] = [],
    idtCreationUser: number | null = null,
    idtContact: number | null = null,
    idtPortfolio: number | null = null,
  ) {
    return this.http.get<TaskCountPerStatus>(`${environment.apiUrl}/task/count`, {
      params: {
        idtAssignees,
        ...(idtCreationUser != null && { idtCreationUser }),
        ...(idtContact != null && { idtContact }),
        ...(idtPortfolio != null && { idtPortfolio }),
      },
    });
  }

  /**
   * Get open tasks from the server.
   *
   * @param idtAssignees list of assigness, must be assigned to all of them
   * @param [idtCreationUser=null] optional user that created the task
   * @param [idtContact=null] optional contact to filter
   * @param [idtPortfolio=null] optional portfolio to filter
   * @returns an observable that emits the tasks found
   */
  getAllTasks(
    idtAssignees: number[] = [],
    idtCreationUser: number | null = null,
    idtContact: number | null = null,
    idtPortfolio: number | null = null,
  ): Observable<TaskViewTO[]> {
    return this.http.get<TaskViewTO[]>(`${environment.apiUrl}/task`, {
      params: {
        idtAssignees,
        ...(idtCreationUser != null && { idtCreationUser }),
        ...(idtContact != null && { idtContact }),
        ...(idtPortfolio != null && { idtPortfolio }),
      },
    });
  }

  /**
   * Get a task by id.
   *
   * @param idtTask the task id
   * @returns an observable that emits the task when the server responds
   */
  getOne(idtTask: number): Observable<TaskViewTO> {
    return this.http.get<TaskViewTO>(`${environment.apiUrl}/task/${idtTask}`);
  }

  delete(idtTask: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/task/${idtTask}`);
  }

  /**
   * Checks if the provided task is past the due date.
   *
   * @param task the task
   * @returns true, if the task is late
   */
  isLate(task: TaskViewTO): boolean {
    return task.dueDate < DateTime.now().toISODate();
  }

  /**
   * Checks if the provided task's due date is in 7 or less days.
   *
   * @param task the task
   * @returns true, if the task is late
   */
  isDue(task: TaskViewTO): boolean {
    return task.dueDate < DateTime.now().plus({ days: 8 }).toISODate() && !this.isLate(task);
  }

  getTaskTypeData(task: TaskViewTO): TaskType | undefined {
    return this.taskTypes.find((type) => type.value === task.type);
  }

  /**
   * Get the icon to use for the provided task type.
   *
   * @returns string for the icon to use
   */
  getTaskIcon(task: TaskViewTO): string | undefined {
    return this.taskTypes.find((type) => type.value === task.type)?.icon;
  }

  /**
   * Get the description text to use for the provided task type.
   *
   * @returns text for the description
   */
  getTaskDescription(task: TaskViewTO): string | undefined {
    return this.taskTypes.find((type) => type.value === task.type)?.text;
  }
}
