import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { UserService } from 'app/modules/common/business/user/user.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { requiredIfValidator } from 'app/modules/common/framework/validations/validation-utils';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { ClientTransactionModeEnum } from '../../../client/model/client.model';
import { ClientConsultantViewTO } from '../../../client/model/consultant.model';
import { ConsultantService } from '../../../consultant/services/consultant.service';
import { PortfolioClass, PortfolioTO } from '../../../portfolio/model/portfolio.model';
import { PortfolioClassService } from '../../../portfolio/services/portfolio-class.service';
import { PortfolioService } from '../../../portfolio/services/portfolio.service';
import { TransactionService } from '../../../transaction/services/transaction.service';
import { Account, Finra5131Enum } from '../../model/account.model';
import { TransactionPendingStatusEnum } from '../../model/transaction-status-enum';
import { TransactionTypeEnum } from '../../model/transaction-type.enum';
import { AccountService } from '../../services/account.service';
import { RegionService } from '../../services/region.service';

/**
 * The form definition.
 */
type AccountForm = FormGroup<{
  transaction?:
    | FormGroup<{
        idtClientTransactionType: FormControl<number | null>;
        value: FormControl<number | null>;
        idtCurrency: FormControl<number | null>;
        idtAltCurrency: FormControl<number | null>;
        valueLocal: FormControl<number | null>;
        nav: FormControl<number | null>;
        altNav: FormControl<number | null>;
        shares: FormControl<number | null>;
        transactionDate: FormControl<DateTime | null>;
        pendingStatus: FormControl<TransactionPendingStatusEnum | null>;
        mode: FormControl<ClientTransactionModeEnum | null>;
      }>
    | undefined;
  idtAccount: FormControl<number | null | undefined>;
  idtClient: FormControl<number | null | undefined>;
  idtPortfolio: FormControl<number | null | undefined>;
  idtClass: FormControl<number | null | undefined>;
  idtSeries: FormControl<number | null | undefined>;
  effectiveManagementFee: FormControl<number | null | undefined>;
  effectiveIncentiveFee: FormControl<number | null | undefined>;
  name: FormControl<string | null | undefined>;
  newIssueStatus: FormControl<string | null | undefined>;
  erisaStatus: FormControl<string | null | undefined>;
  formPf: FormControl<string | null | undefined>;
  finra5131: FormControl<string | null | undefined>;
  blueSkyState: FormControl<string | null | undefined>;
  shareRebate: FormControl<boolean>;
  sideLetter: FormControl<boolean>;
  sideLetterSummary: FormControl<string | null | undefined>;
  version: FormControl<number | null | undefined>;
  idtType: FormControl<number | null | undefined>;
  idtLead: FormControl<number | null>;
  idtLeadBackup: FormControl<number | null>;
  idtPortfolioManager: FormControl<number | null | undefined>;
  inactive: FormControl<boolean>;
  sidePocket: FormControl<boolean>;
  voting: FormControl<boolean>;
  idtRegion: FormControl<number | null | undefined>;
  idtCountry: FormControl<number | null | undefined>;
  initialInvestment: FormControl<Date | null | undefined>;
  ocio: FormControl<boolean>;
  consultant: FormControl<0 | ClientConsultantViewTO | null>;
}>;

/**
 * Component with the form to add or edit an account.
 */
@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent implements OnInit {
  /**
   * The client object.
   */
  @Input()
  client!: {
    name: string;
    idtClient?: number;
  };

  private _idtContact: number | null = null;

  @Input()
  set idtContact(value: number | null) {
    if (this._idtContact !== value) {
      this._idtContact = value;
      this.getContactLeads();
    }
  }

  get idtContact(): number | null {
    return this._idtContact;
  }

  private _account: Account | undefined;

  get account(): Account | undefined {
    return this._account;
  }

  /**
   * The account object.
   */
  @Input()
  set account(value: Account | undefined) {
    this._account = value;
    this.buildFormGroup(this.account);
  }

  /**
   * Whether to display in either a single or two columns.
   */
  @Input()
  useColumns = false;

  /**
   * Flag indicating if the page was loaded for editing an existing account.
   */
  edition = false;

  /**
   * The form group defining all fields.
   */
  formGroup!: AccountForm;

  /**
   * The options for the finra field.
   */
  finra = Object.keys(Finra5131Enum);

  /**
   * Form control for the filter input for searching for portfolios.
   */
  searchPortfolioControl = new FormControl<string>('', { nonNullable: true });

  /**
   * All available portfolios.
   */
  private allPortfolios: PortfolioTO[] = [];

  /**
   * Observable wrapping the list of portfolios, classes and series to show in the selects.
   */
  portfolios$?: Observable<PortfolioTO[]>;
  classes: PortfolioClass[] = [];
  series: PortfolioClass[] = [];

  /**
   * The selected class, with the term fees data.
   */
  selectedClass: PortfolioClass | undefined;

  /**
   * The transaction types.
   */
  transactionTypes$ = this.transactionService.getTypes();

  /**
   * The currencies options.
   */
  currencies$ = this.transactionService.getCurrencies();

  /**
   * Whether it is currently saving an account.
   */
  loading = false;

  /**
   * Options for select inputs.
   */
  blueSkyOptions$ = this.accountService.getGroups('', 'blueSkyState');
  formPf$ = this.accountService.getGroups('', 'formPf');

  /**
   * Properties for searching and selecting the country.
   */
  accountTypes$ = this.accountService.getTypes();

  /**
   * The users available for portfolio managers.
   */
  managers$ = this.userService.getPrincipals();

  /**
   * All possible regions.
   */
  regions$ = this.regionService.get();

  transactionPendingStatus = Object.keys(TransactionPendingStatusEnum);

  /**
   * Mode options.
   */
  transactionMode = Object.keys(ClientTransactionModeEnum);

  /**
   * Transaction type enum.
   */
  transactionType = TransactionTypeEnum;

  /**
   * Event emitter called when saving the form. Emits the form current value.
   */
  @Output()
  save = new EventEmitter<any>();

  /**
   * List of consultant options.
   */
  consultants$?: Observable<ClientConsultantViewTO[]>;

  constructor(
    private fb: FormBuilder,
    private portfolioService: PortfolioService,
    private portfolioClassService: PortfolioClassService,
    private accountService: AccountService,
    private transactionService: TransactionService,
    private contactService: ContactService,
    private userService: UserService,
    private regionService: RegionService,
    private destroyRef: DestroyRef,
    private consultantService: ConsultantService,
  ) {}

  ngOnInit(): void {
    if (!this.formGroup) {
      this.buildFormGroup();
    }

    this.setupPortfolioSearch();
    this.findConsultants();
  }

  /**
   * Builds the formGroup instance.
   *
   * @param account the account for editions
   */
  private buildFormGroup(account?: Account) {
    this.edition = !!this.account?.idtAccount;

    this.formGroup = this.fb.group({
      idtAccount: new FormControl<number | undefined>(account?.idtAccount),
      idtClient: new FormControl<number | undefined>(account?.idtClient || this.client.idtClient),

      idtPortfolio: new FormControl<number | undefined>(account?.idtPortfolio, Validators.required),
      idtClass: new FormControl<number | undefined>({ value: account?.idtClass, disabled: !account?.idtPortfolio }),
      idtSeries: new FormControl<number | undefined>({ value: account?.idtSeries, disabled: !account?.idtClass }),

      effectiveManagementFee: new FormControl<number | undefined>(account?.effectiveManagementFee),
      effectiveIncentiveFee: new FormControl<number | undefined>(account?.effectiveIncentiveFee),

      name: new FormControl<string | undefined>(account?.name || this.client.name, Validators.required),
      newIssueStatus: new FormControl<string | undefined>(account?.newIssueStatus),
      erisaStatus: new FormControl<string | undefined>(account?.erisaStatus),
      formPf: new FormControl<string | undefined>(account?.formPf),
      finra5131: new FormControl<string | undefined>(account?.finra5131),
      blueSkyState: new FormControl<string | undefined>(account?.blueSkyState),
      shareRebate: new FormControl<boolean>(account?.shareRebate || false, { nonNullable: true }),
      sideLetter: new FormControl<boolean>(account?.sideLetter || false, { nonNullable: true }),
      sideLetterSummary: new FormControl<string | undefined>(account?.sideLetterSummary),
      version: new FormControl<number | undefined>(account?.version),
      idtType: new FormControl<number | undefined>(account?.idtType),
      idtLead: new FormControl<number | null>(account?.idtLead || null),
      idtLeadBackup: new FormControl<number | null>(account?.idtLeadBackup || null),
      idtPortfolioManager: new FormControl<number | undefined>(account?.idtPortfolioManager),
      inactive: new FormControl<boolean>(account?.inactive || false, { nonNullable: true }),
      sidePocket: new FormControl<boolean>(account?.sidePocket || false, { nonNullable: true }),
      voting: new FormControl<boolean>(account?.voting || false, { nonNullable: true }),
      idtRegion: new FormControl<number | undefined>(account?.idtRegion),
      idtCountry: new FormControl<number | undefined>(account?.idtCountry),
      initialInvestment: new FormControl<Date | undefined>(account?.initialInvestment),
      ocio: new FormControl<boolean>(account?.ocio || false, { nonNullable: true }),

      consultant: new FormControl<ClientConsultantViewTO | 0 | null>(
        null,
        requiredIfValidator(() => !this.edition),
      ),

      // Add initial transactions only for new accounts
      ...(!this.edition && {
        transaction: this.fb.group({
          idtClientTransactionType: new FormControl<number | null>(
            null,
            requiredIfValidator(() => this.isTransactionFilled()),
          ),
          value: new FormControl<number>(
            0,
            requiredIfValidator(() => this.isTransactionFilled()),
          ),
          idtCurrency: new FormControl<number>(1),
          idtAltCurrency: new FormControl<number>(
            1,
            requiredIfValidator(() => this.isTransactionFilled()),
          ),
          valueLocal: new FormControl<number>(0),
          nav: new FormControl<number | null>(null),
          altNav: new FormControl<number | null>(null),
          shares: new FormControl<number | null>(null),
          transactionDate: new FormControl<DateTime | null>(
            null,
            requiredIfValidator(() => this.isTransactionFilled()),
          ),
          pendingStatus: new FormControl<TransactionPendingStatusEnum | null>(
            null,
            requiredIfValidator(() => this.isTransactionFilled()),
          ),
          mode: new FormControl<ClientTransactionModeEnum | null>(null),
        }),
      }),
    });

    // Load the classes for the portfolio in editions
    if (account?.idtPortfolio) {
      this.loadClasses(account.idtPortfolio).subscribe();

      if (account.idtClass) {
        this.loadSeries(account.idtClass).subscribe();
      }
    }

    this.setupClassAndSeriesSearch();
    this.getContactLeads();
    this.setupTypeAndMode();
    this.setupTransactionValidations();
  }

  /**
   * Verify if the transaction part of the form is partially filled.
   *
   * @returns true, if any of the transaction fields are filled
   */
  private isTransactionFilled(): boolean {
    const transaction = this.formGroup.controls.transaction?.value;

    if (!transaction) {
      return false;
    }

    const { idtCurrency, value, valueLocal, ...t } = transaction;

    return Object.values(t).some((v) => v != null) || value !== 0 || valueLocal !== 0;
  }

  private loadClasses(idtPortfolio: number): Observable<PortfolioClass[]> {
    return this.portfolioClassService
      .findAll(
        'name,asc',
        new DataFilter().equals('idtPortfolio', idtPortfolio, 'long').isNull('idtParent').equals('inactive', false, 'boolean').encodeURIComponent(),
      )
      .pipe(
        tap((classes) => {
          // If the account's class is not in the list of options (because it is inactive), add it so the select is not empty
          if (
            this.account?.idtClass &&
            this.account?.idtPortfolio === idtPortfolio &&
            !classes.some((c) => c.idtClass === this.account!.portfolioClass?.idtClass)
          ) {
            classes.push(this.account!.portfolioClass!);
          }

          this.classes = classes;

          if (classes.length > 0) {
            this.formGroup.controls.idtClass.enable({ emitEvent: false });
          } else {
            this.formGroup.controls.idtClass.disable({ emitEvent: false });
          }

          if (this.account?.idtClass) {
            this.setFees(this.account.idtClass);
          }
        }),
      );
  }

  private loadSeries(idtClass: number): Observable<PortfolioClass[]> {
    return this.portfolioClassService
      .findAll('name,asc', new DataFilter().equals('idtParent', idtClass, 'long').equals('inactive', false, 'boolean').encodeURIComponent())
      .pipe(
        tap((series) => {
          // If the account's series is not in the list of options (because it is inactive), add it so the select is not empty
          if (
            this.account?.idtSeries &&
            this.account?.idtClass === idtClass &&
            !series.some((c) => c.idtClass === this.account!.portfolioSeries?.idtClass)
          ) {
            series.push(this.account!.portfolioSeries!);
          }

          this.series = series;

          if (series.length > 0) {
            this.formGroup.controls.idtSeries.enable({ emitEvent: false });
          } else {
            this.formGroup.controls.idtSeries.disable({ emitEvent: false });
          }
        }),
      );
  }

  /**
   * Setup the class and series select completion.
   * On selecting the portfolio, load the classes. On selecting the class, load the series.
   */
  private setupClassAndSeriesSearch(): void {
    this.formGroup.controls.idtPortfolio.valueChanges
      .pipe(
        distinctUntilChanged(),
        // Sets the name of the account based on the selected portfolio only when creating a new one
        tap((value) => {
          if (value && !this.edition) {
            const portfolio = this.allPortfolios.find((p) => p.idtPortfolio === value);

            this.formGroup.controls.name.setValue(`${this.client.name} - ${portfolio?.ticker || portfolio?.shortName}`);
          }
        }),
        mergeMap((value) => {
          this.formGroup.patchValue({
            idtClass: null,
            idtSeries: null,
          });

          if (value) {
            return this.loadClasses(value);
          }

          return [];
        }),
      )
      .subscribe();

    this.formGroup.controls.idtClass.valueChanges
      .pipe(
        distinctUntilChanged(),
        // Sets the name of the account based on the selected portfolio and class only when creating a new one
        tap((value) => {
          this.setFees(value);

          if (value && !this.edition) {
            const idtPortfolio = this.formGroup.controls.idtPortfolio.value;

            this.formGroup.controls.name.setValue(
              `${this.client.name} - ${this.allPortfolios.find((p) => p.idtPortfolio === idtPortfolio)?.ticker} - ${
                this.classes.find((c) => c.idtClass === value)?.name
              }`,
            );
          }
        }),
        mergeMap((value) => {
          this.formGroup.patchValue({
            idtSeries: null,
          });

          if (value) {
            return this.loadSeries(value);
          }

          return [];
        }),
      )
      .subscribe();
  }

  /**
   * Apply the selected class fees values to the respective fields, if not editing.
   *
   * @param idtClass the class id or undefined
   */
  private setFees(idtClass?: number | null): void {
    this.selectedClass = undefined;

    if (idtClass) {
      this.selectedClass = this.classes.find((c) => c.idtClass === idtClass);

      if (this.selectedClass && !this.edition && !this.account) {
        this.formGroup.controls.effectiveManagementFee.setValue(this.selectedClass.managementFeeWaived ? 0 : this.selectedClass?.managementFee);
        this.formGroup.controls.effectiveIncentiveFee.setValue(this.selectedClass?.incentiveFee);
      }
    }
  }

  /**
   * Sets up the portfolio autocomplete search mechanism.
   */
  private setupPortfolioSearch(): void {
    // Load all portfolios from the server
    this.portfolioService.findAll('shortName,ASC', new DataFilter().equals('inactive', false, 'boolean')).subscribe((p) => {
      this.allPortfolios = p;

      // Setup the filter to search for portfolios locally
      this.portfolios$ = this.searchPortfolioControl.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        map((search: string) => {
          if (search.length === 0) {
            return this.allPortfolios;
          } else if (this.allPortfolios) {
            return this.allPortfolios?.filter((p) => p.shortName.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
          }

          return [];
        }),
        startWith(this.allPortfolios),
      );
    });
  }

  /**
   * Sets up transaction form validation behaviors.
   */
  private setupTransactionValidations(): void {
    // On transaction updates, retrigger validation, since the validation is conditional on other fields.
    // Transaction is optional, but if any value is filled (except the currency), all required fields for a transaction are required
    this.formGroup.controls.transaction?.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      for (const control of Object.values(this.formGroup.controls.transaction!.controls)) {
        control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    });

    // If the value local is changed, update the USD value if USD currency is selected
    this.formGroup.controls.transaction?.controls.valueLocal?.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        tap((amount) => {
          if (this.formGroup.controls.transaction?.controls.idtAltCurrency?.value === 1) {
            this.formGroup.controls.transaction?.controls.value?.setValue(amount);
          }
        }),
      )
      .subscribe();

    // If currency changes to USD, update the value to be the same as valueLocal
    this.formGroup.controls.transaction?.controls.idtAltCurrency?.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        tap((idtCurrency) => {
          if (idtCurrency === 1) {
            const amountAlt = this.formGroup.controls.transaction?.controls.valueLocal?.value;

            this.formGroup.controls.transaction?.controls.value?.setValue(amountAlt || null);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Send the request to the server to save the account.
   */
  onSave(): void {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const value = this.formGroup.value;

    // If the transaction
    if (!this.isTransactionFilled()) {
      delete value.transaction;
    }

    this.save.emit(value);
  }

  /**
   * Get the contact leads to already populate inputs.
   */
  private getContactLeads(): void {
    if (!this.edition) {
      this.contactService.get(this.idtContact!).subscribe((contact) => {
        this.formGroup.controls.idtLead.setValue(contact.idtLead);
        this.formGroup.controls.idtLeadBackup.setValue(contact.idtLeadBackup);
      });
    }
  }

  /**
   * Setup transaction mode options when transaction type is selected.
   * Also sets the transaction value if a a full redemptions is selected.
   */
  private setupTypeAndMode(): void {
    this.formGroup.controls.transaction?.controls.idtClientTransactionType?.valueChanges.subscribe((idtClientTransactionType) => {
      if (idtClientTransactionType === TransactionTypeEnum.SUBSCRIPTION) {
        this.transactionMode = [ClientTransactionModeEnum.NEW, ClientTransactionModeEnum.ADDITIONAL];
      } else if (idtClientTransactionType === TransactionTypeEnum.REDEMPTION) {
        this.transactionMode = [ClientTransactionModeEnum.FULL, ClientTransactionModeEnum.PARTIAL];
      } else {
        this.formGroup.controls.transaction?.controls.mode.setValue(null);
      }
    });
  }

  /**
   * Find list of registered consultants for the contact.
   */
  private findConsultants() {
    this.consultants$ = this.contactService.findUniqueConsultantsByIdtContact(this.idtContact!).pipe(
      tap((cs) => {
        if (cs.length === 1) {
          this.formGroup.controls.consultant.setValue(cs[0]);
        }
      }),
    );
  }

  /**
   * Open dialog to add a new consultant to the client.
   */
  addNewConsultant(): void {
    const dialogRef = this.consultantService.openNewConsultantDialog(this.idtContact!);

    dialogRef.afterClosed().subscribe((cons: ClientConsultantViewTO) => {
      this.findConsultants();
      this.formGroup.controls.consultant.setValue(cons);
    });
  }

  /**
   * Compares two objects to see if the represent the same consultant association.
   *
   * @param c1 a consultant definition
   * @param c2 another consultant definition
   * @returns true if representing the same consultant
   */
  compareConsultant(c1: ClientConsultantViewTO, c2: ClientConsultantViewTO): boolean {
    return c1 && c2 && c1.idtConsultant === c2.idtConsultant && c1.idtConsultantCompany === c2.idtConsultantCompany;
  }

  /**
   * Get the selected class entity.
   *
   * @returns the selected class entity
   */
  getSelectedClass() {
    return this.classes.find((c) => c.idtClass === this.formGroup.controls.idtClass.value);
  }

  /**
   * Get the selected series entity.
   *
   * @returns the selected series entity
   */
  getSelectedSeries() {
    return this.series.find((c) => c.idtClass === this.formGroup.controls.idtSeries.value);
  }
}
