<form [formGroup]="consultantForm">
  <div class="tw-flex tw-flex-row">
    <div fxFlex fxLayout="column">
      <mat-form-field>
        <mat-label>Consultant Company</mat-label>

        <input #consultantCompanyInput type="text" matInput formControlName="externalRep" [matAutocomplete]="consultantCompanyAutoComplete" />
        <mat-autocomplete requireSelection #consultantCompanyAutoComplete="matAutocomplete" [displayWith]="displayCompany">
          @for (companyContact of consultantCompanyOptions(); track companyContact.idtContact) {
            <mat-option [value]="companyContact">
              {{ companyContact.name }}
            </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>

      <div class="tw-flex tw-flex-row tw-gap-1">
        <mat-form-field class="tw-w-1/2">
          <mat-label>Consultant</mat-label>
          <mat-select formControlName="consultant" [compareWith]="contactCompare">
            <mat-select-trigger>
              {{ consultantForm.controls.consultant.value?.name }}
            </mat-select-trigger>

            <mat-option>
              <ngx-mat-select-search [formControl]="consultantSearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
                <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small" />
              </ngx-mat-select-search>
            </mat-option>

            <mat-option>None</mat-option>

            @for (personContact of consultantOptions$ | async; track personContact) {
              <mat-option [value]="personContact" class="option-2-line">
                <div class="option-line abs-text-overflow-ellipsis">{{ personContact.name }}</div>
                @if (personContact?.companyName) {
                  <div class="option-line mat-caption abs-color-faded abs-text-overflow-ellipsis">
                    {{ personContact.companyName }}
                  </div>
                }
              </mat-option>
            }
            @if (consultantForm.controls.consultant.value; as consultantValue) {
              <mat-option [value]="consultantValue" class="option-2-line">
                {{ consultantValue.name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <mat-form-field class="tw-w-1/2">
          <mat-label>Backup Consultant</mat-label>
          <mat-select formControlName="backupConsultant" [compareWith]="contactCompare">
            <mat-select-trigger>
              {{ consultantForm.controls.backupConsultant.value?.name }}
            </mat-select-trigger>

            <mat-option>
              <ngx-mat-select-search [formControl]="backupConsultantSearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
                <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small" />
              </ngx-mat-select-search>
            </mat-option>

            <mat-option>None</mat-option>

            @for (personContact of backupOptions$ | async; track personContact) {
              <mat-option [value]="personContact" class="option-2-line">
                <div class="option-line abs-text-overflow-ellipsis">
                  {{ personContact.name }}
                </div>
                @if (personContact?.companyName) {
                  <div class="option-line mat-caption abs-color-faded abs-text-overflow-ellipsis">
                    {{ personContact.companyName }}
                  </div>
                }
              </mat-option>
            }
            @if (consultantForm.controls.backupConsultant.value; as backupValue) {
              <mat-option [value]="backupValue" class="option-2-line">
                <div class="option-line abs-text-overflow-ellipsis">
                  {{ backupValue.name }}
                </div>
                @if (backupValue.companyName) {
                  <div class="option-line mat-caption abs-color-faded abs-text-overflow-ellipsis">
                    {{ backupValue.companyName }}
                  </div>
                }
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field *hasPermission="'EDIT_ADVANCED_CONTACT_FINANCIAL_DATA'">
        <mat-label>Portfolio</mat-label>
        <mat-select formControlName="idtPortfolios" multiple>
          <mat-option>
            <ngx-mat-select-search [formControl]="searchPortfolioControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
              <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small" />
            </ngx-mat-select-search>
          </mat-option>

          @for (portfolio of portfolios$ | async; track portfolio) {
            <mat-option [value]="portfolio.idtPortfolio">
              {{ portfolio.shortName }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      @if (consultantUsage$ | async; as consultantUsage) {
        <div>
          @if (consultantUsage.accounts + consultantUsage.opportunities > 0) {
            <span>
              This consultant is used in
              @if (consultantUsage.accounts) {
                <span> {{ consultantUsage.accounts }} active {{ consultantUsage.accounts > 1 ? 'accounts' : 'account' }} </span>
              }
              @if (consultantUsage.accounts > 0 && consultantUsage.opportunities > 0) {
                <span>and</span>
              }
              @if (consultantUsage.opportunities) {
                <span>
                  {{ consultantUsage.opportunities }} open
                  {{ consultantUsage.opportunities > 1 ? 'opportunities' : 'opportunity' }}
                </span>
              }
              for this contact.
            </span>
          }
        </div>
      }
    </div>

    <button fxLayoutAlign="end" mat-icon-button aria-label="Remove" (click)="removeForm()">
      <mat-icon fontIcon="mdi-minus-circle" />
    </button>
  </div>
</form>
