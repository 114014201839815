import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { SidenavService } from 'app/modules/common/framework/services/sidenav.service';
import { DateTime } from 'luxon';
import { EMPTY } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { ClientViewTO } from '../../client/model/client.model';
import { Account } from '../model/account.model';
import { AccountService } from '../services/account.service';

/**
 * Component to add or edit an account entity.
 */
@Component({
  selector: 'app-account-add-or-edit',
  templateUrl: './account-add-or-edit.component.html',
  styleUrls: ['./account-add-or-edit.component.scss'],
})
export class AccountAddOrEditComponent implements OnInit {
  /**
   * The client object.
   */
  client!: ClientViewTO;

  account?: Account;

  /**
   * Whether it is currently saving an account.
   */
  loading = false;

  get edition(): boolean {
    return !!this.account?.idtAccount;
  }

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private sidenavService: SidenavService,
    private dialogService: DialogService,
    private router: Router,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
      this.client = params.client;
      this.account = params.account;
    });

    // If a cloneFrom id is passed, load the account, remove attributes that should not be copied
    this.route.queryParamMap
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        mergeMap((params) => {
          const cloneFrom = params.get('sidenav.cloneFrom');

          if (cloneFrom) {
            return this.accountService.getOne(+cloneFrom);
          }

          return EMPTY;
        }),
      )
      .subscribe((account) => {
        if (account) {
          delete account.idtAccount;
          delete account.key;
          account.name = `CLONE_${account.name}`;

          this.account = account;
        }
      });
  }

  /**
   * Send the request to the server to save the account.
   */
  save(value: any): void {
    this.loading = true;

    const { transaction, consultant, ...account } = value;

    const consultantsIds = {
      idtConsultant: consultant?.idtConsultant,
      idtExternalRep: consultant?.idtConsultantCompany,
    };

    let obs;

    if (account.initialInvestment instanceof DateTime) {
      account.initialInvestment = account.initialInvestment.toISODate();
    }

    if (this.edition) {
      obs = this.accountService.update(account);
    } else {
      // Change to a dateonly string if necessary
      if (transaction?.transactionDate instanceof DateTime) {
        transaction.transactionDate = transaction.transactionDate.toISODate();
      }

      obs = this.accountService.create(account, transaction, consultantsIds);
    }

    obs.pipe(finalize(() => (this.loading = false))).subscribe({
      next: (acc) => {
        this.dialogService.showSuccess('Account saved successfully');

        if (this.edition) {
          this.sidenavService.close();
        } else {
          this.router.navigate(['', { outlets: { sidenav: ['account', acc.idtAccount, 'mailing'] } }]);
        }
      },
      error: (error) => {
        this.dialogService.showError(error?.error?.message || 'Error saving the account');
      },
    });
  }
}
