@if (groups && groups.length > 0 && allCompanyContacts && allConsultantContacts) {
  <div fxFlex fxLayout="column">
    @if (allCompanyContacts.length === 0 && allConsultantContacts.length === 0) {
      <div>
        This company has no registered contacts. Please, check if it's necessary to register contacts for this company.
      </div>
    } @else {
      <mat-form-field>
        <mat-label>Select contact</mat-label>
        <mat-select [formControl]="selectContactControl">
          <mat-option>
            <ngx-mat-select-search [formControl]="searchContactControl" placeholderLabel="Search" noEntriesFoundLabel="No contacts found">
              <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" class="small" />
            </ngx-mat-select-search>
          </mat-option>
          @if (filteredCompanyContacts?.length) {
            <div class="abs-bold abs-padding">Company contacts</div>
            @for (contact of filteredCompanyContacts; track contact) {
              <mat-option [value]="contact.idtContact">
                {{ contact.name }}
              </mat-option>
            }
          }
          @if (filteredConsultantContacts?.length) {
            <div class="abs-bold abs-padding">Consultant contacts</div>
            @for (contact of filteredConsultantContacts; track contact) {
              <mat-option [value]="contact.idtContact">
                {{ contact.name }}
              </mat-option>
            }
          }
        </mat-select>
      </mat-form-field>
      <div fxFlex class="abs-scroll-auto abs-margin-top">
        @if (groups && selectContactControl.value) {
          <app-add-group-list
            fxFlex
            [idtContact]="selectContactControl.value"
            [groups]="groups"
          />
        }
      </div>
    }
  </div>
}

@if (groups && groups.length === 0) {
  <div class="abs-padding">No groups found for this portfolio</div>
}
