import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { EmailGroupDetailsViewTO } from 'app/modules/routes/mailing/model/mailing.model';
import { throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BasicContact, ContactTypeEnum, ContactViewTO } from '../../../contact/model/contact.model';
import { ContactService } from '../../../contact/services/contact.service';
import { MailingService } from '../../services/mailing.service';

/**
 * Component for adding contacts to distribution groups after creating an account.
 */
@Component({
  selector: 'app-add-account-groups',
  templateUrl: './add-account-groups.component.html',
  styleUrls: ['./add-account-groups.component.scss'],
})
export class AddAccountGroupsComponent implements OnInit {
  /**
   * The contact id, owner of the account.
   */
  private _idtContact!: number;

  @Input()
  set idtContact(value: number) {
    this._idtContact = value;
    this.loadContact();
  }

  get idtContact(): number {
    return this._idtContact;
  }

  /**
   * The contact owner data.
   */
  contact?: ContactViewTO;

  /**
   * The account portfolio.
   */
  private _idtPortfolio!: number;

  @Input()
  set idtPortfolio(value: number) {
    this._idtPortfolio = value;

    this.loadGroups();
  }

  get idtPortfolio(): number {
    return this._idtPortfolio;
  }

  /**
   * The distribution groups.
   */
  groups?: EmailGroupDetailsViewTO[];

  /**
   * Form control for the selected contact.
   */
  selectContactControl = new FormControl<number | null>(null);

  /**
   * The company contacts.
   */
  allCompanyContacts?: BasicContact[];
  filteredCompanyContacts?: BasicContact[] = [];

  /**
   * The contacts for companies consulting the contact.
   */
  allConsultantContacts?: BasicContact[];
  filteredConsultantContacts?: BasicContact[] = [];

  /**
   * Form control for the contact search field.
   */
  searchContactControl = new FormControl<string>('', { nonNullable: true });

  @Output()
  loading = new EventEmitter<boolean>();

  private loadingCounter = 3;

  constructor(
    private mailingService: MailingService,
    private dialogService: DialogService,
    private contactService: ContactService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    // Configure filters for
    this.searchContactControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500), distinctUntilChanged())
      .subscribe((term: string) => {
        if (term.length === 0) {
          this.filteredCompanyContacts = this.allCompanyContacts;
          this.filteredConsultantContacts = this.allConsultantContacts;
        } else {
          this.filteredCompanyContacts = this.allCompanyContacts?.filter((c) => c.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
          this.filteredConsultantContacts = this.allConsultantContacts?.filter((c) => c.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
        }
      });
  }

  /**
   * Load the contact data.
   */
  private loadContact(): void {
    this.contactService.getInfoById(this.idtContact).subscribe((c) => {
      this.contact = c;
      this.loadContacts();
    });
  }

  /**
   * Load the person contacts for the client.
   */
  private loadContacts(): void {
    this.allCompanyContacts = undefined;
    this.allConsultantContacts = undefined;

    // If a company, load all persons from that company
    if (this.contact?.type === ContactTypeEnum.COMPANY) {
      const filter = new DataFilter()
        .equals('idtCompanyContact', this.idtContact, 'long')
        .equals('type', ContactTypeEnum.PERSON, 'com.abs.crm.contact.ContactTypeEnum');

      this.contactService.getAll(filter, ['keyContact,DESC', 'name,ASC']).subscribe((contacts) => {
        this.allCompanyContacts = contacts;
        this.filteredCompanyContacts = this.allCompanyContacts;
        this.finishLoading();
      });
    } else if (this.contact) {
      // If a person, show the person itself
      this.allCompanyContacts = [this.contact];
      this.filteredCompanyContacts = this.allCompanyContacts;
      this.finishLoading();
    }

    // Load the contacts from consultants
    this.contactService.getConsultants(this.idtContact).subscribe((cs) => {
      if (cs.length > 0) {
        const filter2 = new DataFilter()
          .in(
            'idtCompanyContact',
            cs.map((css) => css.idtExternalRep),
            'long',
          )
          .equals('type', ContactTypeEnum.PERSON, 'com.abs.crm.contact.ContactTypeEnum');

        this.contactService.getAll(filter2, ['keyContact,DESC', 'name,ASC']).subscribe((contacts) => {
          this.allConsultantContacts = contacts;
          this.filteredConsultantContacts = this.allConsultantContacts;
          this.finishLoading();
        });
      } else {
        this.allConsultantContacts = [];
        this.filteredConsultantContacts = [];
        this.finishLoading();
      }
    });
  }

  /**
   * Load the distribution groups for the account portfolio.
   */
  private loadGroups(): void {
    const filter = new DataFilter();

    filter.equals('idtPortfolio', this.idtPortfolio, 'long');
    filter.equals('archived', false, 'boolean');

    this.mailingService
      .getAll(filter, 'description,asc')
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error loading groups');
          return throwError(err);
        }),
      )
      .subscribe((groups) => {
        this.groups = groups;
        this.finishLoading();
      });
  }

  /**
   * Finish loading part of the data. When all 3 parts are loaded emits event indicatng all was loaded.
   */
  private finishLoading(): void {
    if (--this.loadingCounter === 0) {
      this.loading.emit(false);
    }
  }
}
