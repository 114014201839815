<app-custom-list-item [routerLink]="['', { outlets: { sidenav: ['opportunity', opportunity.idtOpportunity] } }]" class="tw-@container">
  <div class="tw-flex tw-max-w-[60%] tw-flex-1 tw-flex-col @xs:tw-max-w-[50%]">
    <div style="display: flex">
      <app-contact-context-menu
        class="abs-bold abs-text-overflow-ellipsis"
        [displayText]="opportunity.displayName"
        [id]="opportunity.idtContact || opportunity.idtConsultantCompany || 0"
      />
    </div>
    <span class="mat-caption abs-color-faded">{{ opportunity.portfolio }}</span>
  </div>

  <div
    fxFlex="10"
    [matTooltip]="(opportunity.probabilityTiming | probabilityTiming) + '\nCreated ' + (opportunity.creationDate | timeAgo)"
    matTooltipClass="multline-tooltip"
    class="timing-column abs-container-gt-xs"
  >
    {{ opportunity.probabilityTiming | titlecase }}
  </div>

  <div fxFlex="10">{{ opportunity.size | nearUnitNumber: '0.0-0' }}</div>
  <a fxFlex="20" class="abs-link" [routerLink]="['', { outlets: { sidenav: ['log', opportunity.idtLastLog] } }]" (click)="$event.stopPropagation()">
    {{ opportunity.lastLogDate | date: 'MM/dd/y' }}
  </a>
  <div fxFlex="10">
    <app-opportunity-status [status]="opportunity.status" />
  </div>
  @if (!last) {
    <mat-divider />
  }
</app-custom-list-item>
