import { formatCurrency, formatDate, formatNumber, formatPercent } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ContactService } from 'app/modules/common/business/contact/services/contact.service';
import { environment } from 'environments/environment';
import { get } from 'lodash-es';
import { EMPTY, Observable, of } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';
import { AccountService } from '../../account/services/account.service';
import { OpportunityService } from '../../opportunity/services/opportunity.service';
import { TransactionService } from '../../transaction/services/transaction.service';
import { Column } from '../model/column.model';
import { EntityTemplate, EntityTemplateListItem } from '../model/entity-template.model';
import { EntityTypeEnum } from '../model/entity-type-enum';
import { CustomTableService } from './custom-table';

@Injectable({ providedIn: 'root' })
export class EntityTemplateService {
  /**
   * The selected columns.
   */
  selectedColumns: { [key in EntityTypeEnum]: Observable<Column[]> } = {
    [EntityTypeEnum.account]: of([]),
    [EntityTypeEnum.transaction]: of([]),
    [EntityTypeEnum.contact]: of([]),
    [EntityTypeEnum.opportunity]: of([]),
  };

  /**
   * The columns to group by.
   */
  groupByColumn: { [key in EntityTypeEnum]: Observable<Column[]> } = {
    [EntityTypeEnum.account]: of([]),
    [EntityTypeEnum.transaction]: of([]),
    [EntityTypeEnum.contact]: of([]),
    [EntityTypeEnum.opportunity]: of([]),
  };

  /**
   * The selected template.
   */
  template: { [key in EntityTypeEnum]?: EntityTemplate } = {};

  /**
   * All available columns separated by entities, ordered by display name.
   */
  columns: { [key in EntityTypeEnum]: Observable<Column[]> } = {
    [EntityTypeEnum.account]: of([]),
    [EntityTypeEnum.transaction]: of([]),
    [EntityTypeEnum.contact]: of([]),
    [EntityTypeEnum.opportunity]: of([]),
  };

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private locale: string,
    accountService: AccountService,
    transactionService: TransactionService,
    contactService: ContactService,
    opportunityService: OpportunityService,
    private router: Router,
  ) {
    this.loadType(EntityTypeEnum.account, accountService);
    this.loadType(EntityTypeEnum.transaction, transactionService);
    this.loadType(EntityTypeEnum.contact, contactService);
    this.loadType(EntityTypeEnum.opportunity, opportunityService);
  }

  /**
   * Load an entity type definition and initial template.
   *
   * @param type the type to load
   * @param service the service to fetch column definitions from
   */
  loadType(type: EntityTypeEnum, service: CustomTableService<any>): void {
    this.columns[type] = service.getColumnsDefinitions().pipe(shareReplay(1));
    const savedTemplate = localStorage.getItem(this.getLocalStorageName(type));

    if (savedTemplate) {
      this.selectTemplate(type, JSON.parse(savedTemplate));
    } else {
      this.useDefaultTemplate(type);
    }
  }

  /**
   * Set a default template as the active one.
   */
  useDefaultTemplate(type: EntityTypeEnum): void {
    switch (type) {
      case EntityTypeEnum.account:
        this.selectTemplate(type, {
          name: 'Account List',
          columns: ['name', 'clientName', 'type', 'portfolioShortName', 'consultantCompanyName', 'consultant', 'key'],
          groupBy: [],
        });
        break;
      case EntityTypeEnum.transaction:
        this.selectTemplate(type, {
          name: 'Transaction List',
          columns: [
            'account.name',
            'account.portfolio.shortName',
            'account.portfolioClass.name',
            'account.portfolioSeries.name',
            'value',
            'valueLocal',
            'status',
            'type.name',
            'transactionDate',
            'nav',
            'shares',
            'currency.abbreviation',
            'currencyAlt.abbreviation',
            'reportingMonthDate',
            'modificationDate',
            'mode',
            'idtClientTransaction',
          ],
          groupBy: [],
        });
        break;
      case EntityTypeEnum.contact:
        this.selectTemplate(type, {
          name: 'Contacts List',
          columns: ['name'],
          groupBy: [],
        });
        break;
      case EntityTypeEnum.opportunity:
        this.selectTemplate(type, {
          name: 'Opportunities List',
          columns: ['contactName', 'keyContactName', 'consultantName', 'consultantCompanyName', 'status', 'timing', 'size'],
          groupBy: [],
        });
        break;
    }
  }

  /**
   * Get the local storage key to get/save the template locally.
   */
  getLocalStorageName(type: EntityTypeEnum): string {
    switch (type) {
      case EntityTypeEnum.account:
        return 'accountsTemplate';
      case EntityTypeEnum.transaction:
        return 'transactionsTemplate';
      case EntityTypeEnum.contact:
        return 'contactsTemplate';
      case EntityTypeEnum.opportunity:
        return 'opportunitiesTemplate';
    }
  }

  /**
   * Changes the configured columns to be displayed without saving in the server.
   * Saves locally to keep columns even if page is refreshed.
   *
   * @param newColumns columns to be displayed
   */
  apply(type: EntityTypeEnum, newColumns: Column[], groupBy: Column[] = []): void {
    const template = this.template[type];

    if (template) {
      template.columns = newColumns.map((c) => c.field);
      template.groupBy = groupBy.map((c) => c.field);

      this.selectTemplate(type, template);
    }
  }

  /**
   * Updates the selected columns and store it in localstorage to keep it between refreshes.
   */
  save(type: EntityTypeEnum, newColumns: Column[], groupBy: Column[] = []): Observable<EntityTemplate> {
    const template = this.template[type];

    if (template) {
      this.apply(type, newColumns, groupBy);

      return this.http.put<EntityTemplate>(`${environment.apiUrl}/entity/template/${template.idtEntityTemplate}`, {
        ...template,
      });
    }

    return EMPTY;
  }

  /**
   * Save a new template
   * @param newColumns the columns to be selected for this new template
   * @param name the name of the template
   */
  saveAs(type: EntityTypeEnum, name: string, newColumns: Column[], groupBy: Column[] = []): Observable<EntityTemplate> {
    this.selectTemplate(type, {
      name,
      columns: newColumns.map((c) => c.field),
      groupBy: groupBy.map((c) => c.field),
      type,
    });

    return this.http
      .post<EntityTemplate>(`${environment.apiUrl}/entity/template`, this.template[type])
      .pipe(tap((t) => this.selectTemplate(type, t)));
  }

  /**
   * Get all templates.
   */
  get(type: EntityTypeEnum): Observable<EntityTemplateListItem[]> {
    return this.http.get<EntityTemplateListItem[]>(`${environment.apiUrl}/entity/template`, {
      params: {
        type,
      },
    });
  }

  /**
   * Gets a single template.
   *
   * @param id the id of the template
   */
  getOne(id: number): Observable<EntityTemplate> {
    return this.http.get<EntityTemplate>(`${environment.apiUrl}/entity/template/${id}`);
  }

  /**
   * Deletes a template in the server.
   *
   * @param id the id of the template to delete
   */
  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/entity/template/${id}`);
  }

  /**
   * Changes the selected template.
   *
   * @param template the template to select
   */
  selectTemplate(type: EntityTypeEnum, template: EntityTemplate): void {
    this.template[type] = template;
    localStorage.setItem(this.getLocalStorageName(type), JSON.stringify(template));

    this.groupByColumn[type] = this.columns[type].pipe(
      take(1),
      map((columns) => {
        // Set the group by columns preserving the order
        return (
          template.groupBy?.reduce((res: Column[], c) => {
            const cc = columns.find((ccc) => c === ccc.field);

            if (cc) {
              res.push(cc);
            }

            return res;
          }, []) || []
        );
      }),
    );

    this.selectedColumns[type] = this.columns[type].pipe(
      take(1),
      map((columns) => {
        // Set the selected columns preserving the order
        return template.columns.reduce((res: Column[], c) => {
          const cc = columns.find((ccc) => c === ccc.field);

          if (cc) {
            res.push(cc);
          }

          return res;
        }, []);
      }),
      tap((c) => {
        // Remove any stored filter that is no longer shown
        Object.keys(localStorage)
          .filter((key) => key.startsWith(`${type}-filter`))
          .filter((key) => !c.some((cc) => key.includes(cc.field)))
          .forEach((key) => {
            localStorage.removeItem(key);
          });
      }),
    );
  }

  /**
   * Get the value for the provided column in the provided entity.
   *
   * @param entity the entity
   * @param column the column to get the value from
   * @returns the value transformed to display
   */
  getEntityValue(entity: any, column: Column): string {
    const value = get(entity, column.field);

    return this.getValue(value, column);
  }

  /**
   * Transforms a value applying the defined metamodel transformations.
   *
   * @param value the value
   * @param column the column metamodel definitions
   * @returns the value transformed
   */
  getValue(value: any, column: Column): string {
    if (value != null) {
      if (Array.isArray(value)) {
        const v = value as any[];
        return v.map((vv) => this.getValue(vv, column)).join(', ');
      }

      if (column.fieldTransform === 'currency') {
        value = formatCurrency(value, this.locale, '$');
      } else if (column.fieldTransform === 'currencyWithoutCents') {
        value = formatCurrency(value, this.locale, '$', 'USD', '0.0-0');
      } else if (column.fieldTransform === 'decimal') {
        value = formatNumber(value, this.locale, '0.2-2');
      } else if (column.fieldTransform === 'dateOnly') {
        value = value ? formatDate(value, 'MM/dd/yyyy', this.locale) : value;
      } else if (column.fieldTransform === 'status') {
        // Transform string to boolean value if necessary
        if (typeof value === 'string') {
          value = value === 'true';
        }
        value = value ? 'Inactive' : 'Active';
      } else if (column.fieldTransform === 'yesOrNo') {
        // Transform string to boolean value if necessary
        if (typeof value === 'string') {
          value = value === 'true';
        }
        value = value ? 'Yes' : 'No';
      } else if (column.fieldTransform === 'percentage') {
        value = formatPercent(value / 100, this.locale, '1.2-2');
      } else if (column.fieldTransform === 'enum') {
        const input = value.replace(/_/, ' ');
        value = input
          .toLowerCase()
          .split(' ')
          .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      }
    }

    return value;
  }

  /**
   * Go to the details page for the provided entity.
   *
   * @param type the type of the entity
   * @param entity the entity data
   */
  goToDetails(type: EntityTypeEnum, entity: any): void {
    switch (type) {
      case EntityTypeEnum.account:
        this.router.navigate(['', { outlets: { sidenav: ['account', entity.idtAccount] } }]);
        break;
      case EntityTypeEnum.transaction:
        this.router.navigate(['', { outlets: { sidenav: ['transaction', entity.idtClientTransaction] } }]);
        break;
      case EntityTypeEnum.contact:
        this.router.navigate(['contact', entity.idtContact]);
        break;
      case EntityTypeEnum.opportunity:
        this.router.navigate(['', { outlets: { sidenav: ['opportunity', entity.idtOpportunity] } }]);
        break;
    }
  }
}
