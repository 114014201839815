import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchService } from '../../services/search.service';

/**
 * General Search field
 */
@Component({
  selector: 'app-super-search-field',
  templateUrl: './super-search-field.component.html',
  styleUrls: ['./super-search-field.component.scss'],
})
export class SuperSearchFieldComponent implements OnInit, OnDestroy {
  searchField = new FormControl<string | null>(null);
  subscription?: Subscription;

  /**
   * Whether to show the search input. Used for mobile view only.
   */
  showSearch = false;

  /**
   * Reference to the search input element.
   */
  @ViewChild('searchInput')
  searchInput!: ElementRef;

  constructor(private searchService: SearchService, private cd: ChangeDetectorRef, private trackerService: TrackerService) {}

  ngOnInit(): void {
    this.subscription = this.searchField.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((term) => {
      if (term) {
        this.trackerService.event('super_search', 'execute', { term });
      }
      this.searchService.search(term);
    });

    // Cleanup whenever the search is closed
    this.searchService.active$.subscribe((active) => {
      if (!active) {
        this.cleanUp();
        this.showSearch = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Closes the search results.
   */
  close(): void {
    this.trackerService.event('super_search', 'close');
    this.cleanUp();
  }

  /**
   * Sets the searchField to empty and also sets false
   * for active$ in searchService that is used
   * to show or hide the search results
   */
  cleanUp(): void {
    this.searchField.setValue(null);
    this.searchService.search('');
  }

  /**
   * Show the search input.
   */
  displaySearch(): void {
    this.showSearch = true;
    this.cd.detectChanges();
    this.searchInput.nativeElement.focus();
  }

  /**
   * Handle leaving focus on search field. Hides the search if empty.
   */
  focusout(): void {
    if (this.searchField.value == null) {
      this.showSearch = false;
    }
  }
}
