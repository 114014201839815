<mat-sidenav-container fxFlex>
  <mat-sidenav-content class="tw-@container" fxFlex fxLayout="column" appResizeObserver (sizeChanged)="onResize($event)">
    <div fxFlex fxLayout="column" class="abs-scroll-auto">
      <router-outlet (activate)="onRouteChange()" />
    </div>
    <mat-toolbar class="footer-toolbar mat-elevation-z4" color="primary">
      @if (loggedIn$ | async) {
        <mat-toolbar-row>
          @if (history.length > 0) {
            <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="Browsing history">
              <mat-icon fontIcon="mdi-history" />
            </button>
          }

          @if (history.length > 5) {
            <mat-icon fontIcon="mdi-dots-horizontal" class="abs-margin-half-right abs-container-gt-xs" />
          }
          @for (hist of (history | slice: 0 : 5).reverse(); track hist; let last = $last) {
            <div fxLayoutAlign="center center" class="abs-container-gt-xs">
              <a
                class="abs-link"
                [routerLink]="hist.path.length ? hist.path : ['']"
                (click)="track('History')"
                fxLayout="column"
                fxLayoutAlign="center start"
              >
                <span>{{ hist.title }}</span>
                @if (hist.subtitle) {
                  <span class="mat-caption abs-opacity abs-text-overflow-ellipsis">
                    {{ hist.subtitle }}
                  </span>
                }
              </a>
              @if (!last) {
                <mat-icon class="abs-margin-half-horizontal" fontIcon="mdi-chevron-right" />
              }
            </div>
          }
          <span fxFlex></span>
          <button mat-button (click)="addContact()">
            <mat-icon fontIcon="mdi-account-plus" />
            <span class="abs-margin-half-left abs-container-gt-sm">New Contact</span>
          </button>
          <button mat-button [routerLink]="['', { outlets: { sidenav: ['log', 'new'] } }]" (click)="track('New Log')">
            <mat-icon fontIcon="mdi-message-reply-text" />
            <span class="abs-margin-half-left abs-container-gt-sm">New Log</span>
          </button>
          <button mat-button (click)="addDocument()">
            <mat-icon fontIcon="mdi-upload" />
            <span class="abs-margin-half-left abs-container-gt-sm">Upload Doc</span>
          </button>
        </mat-toolbar-row>
      }
    </mat-toolbar>
  </mat-sidenav-content>
  <mat-sidenav
    mode="side"
    class="mat-elevation-z4"
    [opened]="openSidebar"
    disableClose
    ngClass="full-width"
    ngClass.gt-sm="half-width"
    ngClass.gt-lg="third-width"
  >
    <router-outlet name="sidenav" (activate)="openSidebar = true" (deactivate)="openSidebar = false" />
  </mat-sidenav>
</mat-sidenav-container>

<!-- History menu -->
<mat-menu #menu>
  @for (hist of history; track hist) {
    <div
      class="history"
      mat-menu-item
      [routerLink]="hist.path.length ? hist.path : ['']"
      (click)="track('History')"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-icon fxFlex="none" fontIcon="mdi-history" />
      <div fxLayout="column">
        <span>{{ hist.title }}</span>
        @if (hist.subtitle) {
          <span class="mat-caption">{{ hist.subtitle }}</span>
        }
      </div>
    </div>
  }
</mat-menu>
