import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

/**
 * Component to add a navigation toolbar.
 */
@Component({
  selector: 'app-list-navigation',
  templateUrl: './list-navigation.component.html',
  styleUrl: './list-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListNavigationComponent {
  /**
   * The currently selected index.
   */
  index = input.required<number>();

  /**
   * The full list of items.
   */
  list = input.required<any[]>();

  /**
   * The property to use to display the next and privous items.
   */
  displayProperty = input.required<string>();

  /**
   * Output that emits when clicking either next of previous itens, emits the index it should navigate to.
   */
  navigate = output<number>();
}
