import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { Country } from '../../model/country.model';
import { AddressService } from '../../services/address.service';

/**
 * Form field for country selection.
 */
@Component({
  selector: 'app-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss'],
})
export class CountryAutocompleteComponent implements OnInit {
  /**
   * The label to show on the field.
   */
  @Input()
  label: string = 'Country';

  /**
   * The form control to bind to.
   */
  @Input()
  control!: FormControl<number | null>;

  /**
   * Control for the MatSelect filter keyword
   */
  countrySearchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * All possible countries.
   */
  allCountries: Country[] = [];

  /**
   * The list of matching countries.
   */
  countries$?: Observable<Country[]>;

  /**
   * The list of countries that should also appear at the top of the list.
   */
  topCountries: Country[] = [];

  constructor(
    private addressService: AddressService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.addressService
      .getCountries()
      .pipe(
        tap((countries) => {
          this.allCountries = countries;
          this.topCountries = countries.filter((country) => country.abbreviation === 'US');
        }),
      )
      .subscribe(() => {
        this.countries$ = this.countrySearchControl.valueChanges.pipe(
          takeUntilDestroyed(this.destroyRef),
          distinctUntilChanged(),
          map((search: string) => {
            if (search.length === 0) {
              return this.allCountries;
            } else if (this.allCountries) {
              return this.allCountries?.filter((p) => p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
            }
            return [];
          }),
          startWith(this.allCountries),
        );
      });
  }
}
