<mat-form-field>
  <mat-label>{{ label() }}</mat-label>
  <mat-chip-grid #chipGrid>
    @for (selectedValue of control().value; track selectedValue) {
      <mat-chip-row (removed)="remove(selectedValue)">
        {{ selectedValue }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close" />
        </button>
      </mat-chip-row>
    }
  </mat-chip-grid>
  <input
    #tagInput
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)"
    [formControl]="filterControl"
    placeholder="Add value"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    @for (option of filteredOptions$ | async; track option) {
      <mat-option [value]="option">{{ option }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
