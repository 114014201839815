import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, input, signal } from '@angular/core';
import { AuthService } from '../../business/auth/services/auth.service';

/**
 * Component to render the header for printed reports.
 */
@Component({
  selector: 'app-print-header',
  templateUrl: './print-header.component.html',
  styleUrl: './print-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintHeaderComponent {
  /**
   * The report name/title.
   */
  title = input.required<string>();

  /**
   * The current logged in user who generated the report.
   */
  currentUser$ = this.authService.user$;

  /**
   * The time the report was generated.
   */
  reportDate = signal<Date | null>(null);

  /**
   * Before printing, set the report date.
   *
   * Notice, we must explicitly call for detect changes, to make sure it completes and renders the date before the print dialog is opened.
   */
  @HostListener('window:beforeprint')
  onBeforePrint(): void {
    this.reportDate.set(new Date());
    this.cdr.detectChanges();
  }

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {}
}
