import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, Observable, startWith } from 'rxjs';
import { TagService } from '../../services/tag.service';

/**
 * Component for autocomplete form fields for contact tags.
 */
@Component({
  selector: 'app-tag-autocomplete',
  templateUrl: './tag-autocomplete.component.html',
  styleUrl: './tag-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagAutocompleteComponent implements OnInit {
  /**
   * Characters to cause the autocomplete to add as a new value.
   */
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  /**
   * The tag id.
   */
  idtTag = input.required<number>();

  /**
   * The label to use for the field.
   */
  label = input.required<string>();

  /**
   * The form control to hold the values.
   */
  control = input.required<FormControl<string[]>>();

  /**
   * All already used values.
   */
  options!: string[];

  /**
   * Form control to hold values while being typed and filter options.
   */
  filterControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The filtered options.
   */
  filteredOptions$!: Observable<string[]>;

  /**
   * Reference to the input element.
   */
  @ViewChild('tagInput')
  input!: ElementRef<HTMLInputElement>;

  constructor(
    private tagService: TagService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // Seach all already used values for the tag
    this.tagService.getTagOptions(this.idtTag()).subscribe((interests) => {
      this.options = interests;

      this.filteredOptions$ = this.filterControl.valueChanges.pipe(
        map((search) => {
          if (!search) {
            return this.options;
          }

          return this.options.filter((i) => i.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
        }),
        startWith(this.options),
      );
    });

    // If the form control is changed programatically, mark change detection to be executed so it updates the displayed values
    this.control().valueChanges.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  /**
   * Removes a selected value.
   *
   * @param value the value to be removed
   */
  remove(value: string): void {
    const control = this.control();
    control.setValue(control.value.filter((v) => v !== value));
  }

  /**
   * Handles selecting an option from the autocomplete.
   *
   * @param event the autocomplete selection event
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    const control = this.control();

    if (!this.control().value.includes(event.option.viewValue)) {
      control.setValue([...control.value, event.option.viewValue]);
    }

    this.input.nativeElement.value = '';
    this.filterControl.setValue('');

    // Hack to keep the autocomplete panel open after selecting a tag
    setTimeout(() => {
      this.input.nativeElement.blur();
      this.input.nativeElement.focus();
    });
  }

  /**
   * Handles adding a new value.
   *
   * @param event the chip event
   */
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && !this.control().value.includes(value)) {
      const control = this.control();
      control.setValue([...control.value, value]);
    }

    this.input.nativeElement.value = '';
    this.filterControl.setValue('');
  }
}
