import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Inject, OnInit, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IndexedIndexTO } from 'app/modules/common/business/index/models/index.model';
import {
  ClassHotIssueEligibilityEnum,
  ClassTypeEnum,
  PortfolioClass,
  PortfolioClassUpdateDTO,
  ReturnBasisEnum,
  TermViewTO,
} from 'app/modules/common/business/portfolio/model/portfolio.model';
import { PortfolioService } from 'app/modules/common/business/portfolio/services/portfolio.service';
import { ObservableUtilsService, trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { debounceTime, distinctUntilChanged, fromEvent, Observable, switchMap, tap } from 'rxjs';
import { CurrencyService } from '../../services/currency.service';

interface DialogData {
  idtPortfolio: number;
  portfolioClass?: PortfolioClass;
  parentClass?: PortfolioClass;
}

/**
 * Component for the dialog to add or edit classes and series.
 */
@Component({
  selector: 'app-class-add-or-edit',
  templateUrl: './class-add-or-edit.component.html',
  styleUrl: './class-add-or-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassAddOrEditComponent implements OnInit, AfterViewInit {
  ClassHotIssueEligibilityEnum = ClassHotIssueEligibilityEnum;
  ReturnBasisEnum = ReturnBasisEnum;
  ClassTypeEnum = ClassTypeEnum;

  /**
   * Whether waiting on server response.
   */
  loading = signal(false);

  /**
   * The from definition.
   */
  formGroup = this.fb.group({
    idtParent: new FormControl<number | null>(null),
    idtTerm: new FormControl<number | null>(null, Validators.required),
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    type: new FormControl<ClassTypeEnum | null>(null, Validators.required),
    returnBasis: new FormControl<ReturnBasisEnum | null>(null, Validators.required),
    inactive: new FormControl<boolean>(false, { nonNullable: true }),
    situation: new FormControl<number>(1, { nonNullable: true }),
    idtCurrency: new FormControl<number | null>(1, { validators: Validators.required }),
    hotIssueEligibility: new FormControl<ClassHotIssueEligibilityEnum | null>(null, Validators.required),
    citcoId: new FormControl<string>('', { nonNullable: true }),
    citcoSmaId: new FormControl<string>('', { nonNullable: true }),
  });

  /**
   * List of terms for terms to select.
   */
  terms$?: Observable<TermViewTO[]>;

  /**
   * The options to show for the index input.
   */
  currencyOptions = signal<IndexedIndexTO[]>([]);

  /**
   * Form contorl to hold the selected index.
   */
  currencyControl = new FormControl<IndexedIndexTO | null>({ id: 1, currency: { name: 'USD Currency (USD)' } }, Validators.required);

  /**
   * The currency input element reference.
   */
  currencyInput = viewChild<ElementRef>('currencyInput');

  /**
   * Whether this dialog was opened for a series.
   */
  get series(): boolean {
    return !!this.formGroup.controls.idtParent.value;
  }

  /**
   * Whether currently editing an existing class/series.
   */
  get edition(): boolean {
    return !!this.data.portfolioClass;
  }

  /**
   * The proper dialog title to display.
   */
  get title(): string {
    return `${this.edition ? 'Edit' : 'Create'} ${this.series ? 'Series' : 'Class'}`;
  }

  constructor(
    private fb: FormBuilder,
    private portfolioService: PortfolioService,
    private currencyService: CurrencyService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private destroyRef: DestroyRef,
    private dialogRef: MatDialogRef<ClassAddOrEditComponent>,
    private observableUtils: ObservableUtilsService,
  ) {}

  ngOnInit() {
    this.loadTerms();

    if (!this.edition) {
      this.setupTypeBehavior();
    }

    if (this.data.portfolioClass) {
      this.patchValue();
    } else if (this.data.parentClass) {
      this.setValuesFromParentClass();
    }
  }

  ngAfterViewInit() {
    this.setupCurrencySearch();
  }

  /**
   * Set series values that should be inheritted from the class.
   */
  private setValuesFromParentClass() {
    this.formGroup.patchValue(
      {
        idtParent: this.data.parentClass!.idtClass,
        idtCurrency: this.data.parentClass!.idtCurrency,
        idtTerm: this.data.parentClass!.idtTerm,
        hotIssueEligibility: this.data.parentClass!.hotIssueEligibility,
        type: this.data.parentClass!.type,
        returnBasis: this.data.parentClass!.returnBasis,
      },
      { emitEvent: false },
    );
  }

  /**
   * Triggers form validation.
   *
   * @returns true, if the form is valid
   */
  isValid(): boolean {
    // Mark the currency control as touched since it is not part of the main form group and we want it to be red in case of validation errors.
    this.currencyControl.markAllAsTouched();

    return this.formGroup.valid;
  }

  /**
   * Save the class.
   */
  save() {
    let obs: Observable<number | undefined>;

    if (this.edition) {
      obs = this.portfolioService.updateClass(this.data.idtPortfolio, this.data.portfolioClass!.idtClass, this.buildData());
    } else {
      obs = this.portfolioService.createClass(this.data.idtPortfolio, this.buildData());
    }

    obs.pipe(trackLoading(this.loading), this.observableUtils.catchErrorAndNotify('Error saving class or series')).subscribe((idtClass) => {
      this.dialogRef.close(idtClass ?? this.data.portfolioClass?.idtClass);
    });
  }

  /**
   * Builds the data to be sent to the server in the expected format.
   *
   * @returns the data to be sent to the server
   */
  private buildData(): PortfolioClassUpdateDTO {
    const data = this.formGroup.getRawValue();

    return {
      citcoId: data.citcoId,
      citcoSmaId: data.citcoSmaId,
      hotIssueEligibility: data.hotIssueEligibility === ClassHotIssueEligibilityEnum.RESTRICTED ? 1 : 2,
      idtCurrency: data.idtCurrency!,
      idtParent: data.idtParent,
      idtTerm: data.idtTerm!,
      inactive: data.inactive,
      name: data.name,
      returnBasis: data.returnBasis === ReturnBasisEnum.NAV ? 1 : 2,
      situation: data.situation,
      type: data.type === ClassTypeEnum.LP ? 2 : 3,
    };
  }

  /**
   * Load the terms to be displayed as filter options.
   */
  private loadTerms() {
    this.terms$ = this.portfolioService.getTerms(this.data.idtPortfolio);
  }

  /**
   * Patch the form with the values of an existing class for editions.
   * @param data the existing class data
   */
  private patchValue() {
    const data = this.data.portfolioClass!;

    this.currencyControl.setValue({
      id: data.idtCurrency,
      currency: {
        name: data.currency,
      },
    });

    this.formGroup.patchValue(
      {
        idtParent: data.idtParent,
        citcoId: data.citcoId,
        citcoSmaId: data.citcoSmaId,
        hotIssueEligibility: data.hotIssueEligibility,
        idtCurrency: data.idtCurrency,
        idtTerm: data.idtTerm,
        inactive: data.inactive,
        situation: data.closed ? 2 : 1,
        name: data.name,
        returnBasis: data.returnBasis,
        type: data.type,
      },
      { emitEvent: false },
    );
  }

  /**
   * Setup autocomplete search behavior for the currency field.
   */
  private setupCurrencySearch() {
    if (this.currencyInput()) {
      fromEvent(this.currencyInput()!.nativeElement, 'input')
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          distinctUntilChanged(),
          debounceTime(500),
          switchMap(() => {
            return this.currencyService.searchCurrencies(this.currencyInput()!.nativeElement.value);
          }),
          tap((options) => {
            this.currencyOptions.set(options);
          }),
        )
        .subscribe();

      this.currencyControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((currency) => {
        this.formGroup.controls.idtCurrency.setValue(currency?.id ?? null);
      });
    }
  }

  /**
   * Configure behavior to set initial return basis based on the selected class type.
   *
   * Type LP applies initial Market return basis.
   * Type Offshore applies initial NAV return basis.
   */
  private setupTypeBehavior() {
    this.formGroup.controls.type.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (value === ClassTypeEnum.LP) {
        this.formGroup.controls.returnBasis.setValue(ReturnBasisEnum.MARKET);
      } else {
        this.formGroup.controls.returnBasis.setValue(ReturnBasisEnum.NAV);
      }
    });
  }

  /**
   * Function to build the string to show as the selected value for the index.
   *
   * @param index the selected value
   * @returns the value to display
   */
  displayCurrency(index: IndexedIndexTO | null) {
    return index?.currency.name ?? '';
  }
}
