import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Component for the sidenav page to assign contacts to groups after creating an account.
 */
@Component({
  selector: 'app-add-account-groups-sidenav',
  templateUrl: './add-account-groups-sidenav.component.html',
  styleUrls: ['./add-account-groups-sidenav.component.scss'],
})
export class AddAccountGroupsSidenavComponent {
  /**
   * The contact id.
   */
  idtContact!: number;

  /**
   * The portfolio id.
   */
  idtPortfolio!: number;

  loading = true;

  constructor(route: ActivatedRoute) {
    route.data.subscribe((data) => {
      this.idtContact = data.account.client.idtOwner;
      this.idtPortfolio = data.account.idtPortfolio;
    });
  }
}
