<table
  mat-table
  [dataSource]="dataSource()"
  matSort
  (matSortChange)="onSortChange($event)"
  class="with-hover tw-w-full"
  fixedLayout
  [trackBy]="trackOpportunity"
>
  <ng-container matColumnDef="contact">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
    <td mat-cell *matCellDef="let element">
      @if (element.idtContact) {
        <app-contact-context-menu [id]="element.idtContact" [displayText]="element.contact" />
      } @else {
        -
      }
    </td>
  </ng-container>
  <ng-container matColumnDef="consultantCompany">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Consultant</th>
    <td mat-cell *matCellDef="let element">
      @if (element.idtConsultantCompany) {
        <app-contact-context-menu [id]="element.idtConsultantCompany" [displayText]="element.consultantCompany" />
      } @else {
        -
      }
    </td>
  </ng-container>
  <ng-container matColumnDef="lead">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Lead</th>
    <td mat-cell *matCellDef="let element">{{ element.lead | emptyValue: '-' }}</td>
  </ng-container>
  <ng-container matColumnDef="leadBackup">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Backup Lead</th>
    <td mat-cell *matCellDef="let element">{{ element.leadBackup | emptyValue: '-' }}</td>
  </ng-container>
  <ng-container matColumnDef="portfolio">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Portfolio</th>
    <td mat-cell *matCellDef="let element">
      <app-portfolio-context-menu [id]="element.idtPortfolio" [displayText]="element.portfolio" />
    </td>
  </ng-container>
  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Value (M)</th>
    <td mat-cell *matCellDef="let element" class="!tw-text-end">{{ element.size | millions }}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="statusOrder">Status</th>
    <td mat-cell *matCellDef="let element">
      <app-opportunity-status [status]="element.status" />
    </td>
  </ng-container>
  <ng-container matColumnDef="lastLogDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Last Contact</th>
    <td mat-cell *matCellDef="let element" class="!tw-text-end">
      @if (element.lastLogDate) {
        <a
          [routerLink]="['', { outlets: { sidenav: ['log', element.idtLastLog] } }]"
          class="abs-link"
          matTooltip="Open last log associated to this opportunity"
        >
          {{ element.lastLogDate | date: 'MM/dd/y' }}
        </a>
      } @else {
        -
      }
    </td>
  </ng-container>
  <ng-container matColumnDef="nextContactDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Contact Due</th>
    <td mat-cell *matCellDef="let element" class="!tw-text-end" [ngClass]="{ exception: element.exceptionStatus === 'Overdue' }">
      {{ element.blackout ? 'Blackout' : (element.nextContactDate | date: 'MM/dd/y' | emptyValue: '-') }}
    </td>
  </ng-container>
  <ng-container matColumnDef="months">
    <th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before">Req. Freq.</th>
    <td mat-cell *matCellDef="let element" class="!tw-text-end">
      @if (element.months) {
        <span matTooltip="Contact frequency required">{{ element.months }}M</span>
      } @else {
        -
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <div class="tw-flex tw-flex-row">
        <button mat-icon-button color="primary" (click)="createLog(element)" matTooltip="Create log">
          <mat-icon fontIcon="mdi-message-reply-text" />
        </button>
        <button
          mat-icon-button
          color="primary"
          [routerLink]="['', { outlets: { sidenav: ['opportunity', element.idtOpportunity] } }]"
          matTooltip="Opportunity details"
        >
          <mat-icon fontIcon="mdi-cash-100" />
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>

  <tr class="mat-row" *matNoDataRow>
    @if (!loading()) {
      <td [attr.colspan]="displayedColumns().length"><span class="tw-pl-1">No opportunities found</span></td>
    }
  </tr>
</table>
