import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EmailHistoryContactGroupViewTO } from 'app/modules/routes/mailing/model/mailing.model';
import { EmailHistoryService } from 'app/modules/routes/mailing/services/email-history.service';
import { ContactLastEmailErrorService } from './contact-last-email-error.service';

/**
 * Component to show an alert icon for emails with errors.
 */
@Component({
  selector: 'app-contact-last-email-error',
  templateUrl: './contact-last-email-error.component.html',
  styleUrls: ['./contact-last-email-error.component.scss'],
})
export class ContactLastEmailErrorComponent implements OnChanges {
  /**
   * The email address.
   */
  @Input()
  email!: string;

  /**
   * Output the error message to be shown.
   */
  @Output()
  errorChanged = new EventEmitter<string>();

  /**
   * The last message sent to this email address.
   */
  lastMessage?: EmailHistoryContactGroupViewTO;

  /**
   * Whether to show the error icon.
   */
  showError = false;

  constructor(
    private emailhistoryService: EmailHistoryService,
    private contactLastEmailErrorService: ContactLastEmailErrorService
  ) {}

  /**
   * On changes to the email referenced, reload the last message.
   */
  ngOnChanges(): void {
    this.emailhistoryService.getLastMessage(this.email).subscribe((m) => {
      this.lastMessage = m;

      const message = m ? this.contactLastEmailErrorService.getErrorDescription(m.errorDetails, m.unsubDate) : undefined;
      this.showError = !!message;
      this.errorChanged.emit(message);
    });
  }
}
