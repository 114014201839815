<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree abs-margin-top">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node">
      <!-- use a disabled button to provide padding for tree leaf -->
      <button mat-icon-button disabled></button>
      <button mat-button (click)="select(node)">
        {{ node.name || node.className }}
      </button>
    </li>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li>
      <div class="mat-tree-node">
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon [fontIcon]="treeControl.isExpanded(node) ? 'mdi-chevron-down' : 'mdi-chevron-right'" />
        </button>
        <button mat-button (click)="select(node)">{{ node.name }}</button>
      </div>
      <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet />
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
<div fxLayoutAlign="center center" class="slider-wrapper">
  <mat-slide-toggle color="primary" (change)="sliderChanged($event)">Include not invested portfolios/classes</mat-slide-toggle>
</div>
