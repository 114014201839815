import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { AccountIndexedDTO } from '../../../account/model/account.model';
import { AccountService } from '../../../account/services/account.service';
import { ContactIndexedDTO } from '../../../contact/model/contact.model';
import { ContactService } from '../../../contact/services/contact.service';

/**
 * Component to select a client.
 */
@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss'],
})
export class ClientSelectComponent implements OnInit {
  /**
   * The form control.
   */
  @Input({ required: true })
  control!: FormControl<AccountIndexedDTO | null>;

  /**
   * Form control for the contact serch behavior.
   */
  searchControl = new FormControl<string>('', { nonNullable: true });

  /**
   * The list of contacts to display.
   */
  contacts$?: Observable<ContactIndexedDTO[]>;

  /**
   * The list of accounts to display.
   */
  accounts$?: Observable<AccountIndexedDTO[]>;

  constructor(
    private contactService: ContactService,
    private accountService: AccountService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.setupContactSearch();
  }

  /**
   * Setups the contact search.
   */
  private setupContactSearch(): void {
    const obs = this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged(), debounceTime(1000));

    this.contacts$ = obs.pipe(
      mergeMap((search) => {
        if (search.length < 2) {
          return of([]);
        } else {
          return this.contactService.searchIndexedInvestedContacts(search);
        }
      }),
    );

    this.accounts$ = obs.pipe(
      mergeMap((search) => {
        if (search.length < 2) {
          return of([]);
        } else {
          return this.accountService.searchIndexedAccounts(search);
        }
      }),
    );
  }
}
