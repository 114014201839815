@if (!showChip) {
  <div class="tab-tools abs-margin-right"></div>
} @else {
  <mat-chip-listbox class="abs-margin-half-top abs-margin-half-left">
    @if (portfolio) {
      <mat-chip (removed)="removePortfolio()">
        {{ portfolio.ticker }}
        <button matChipRemove>
          <mat-icon fontIcon="mdi-close" />
        </button>
      </mat-chip>
    }
    @if (!!asOf) {
      <mat-chip>as of {{ asOf.toLocaleString() }}</mat-chip>
    }
  </mat-chip-listbox>
}

<app-custom-list class="!tw-flex tw-flex-1 tw-flex-col">
  <cdk-virtual-scroll-viewport class="tw-flex tw-flex-1 tw-flex-col" itemSize="44" [ngClass]="{ 'abs-scroll-hidden': loading }">
    <!-- Skeleton items while loading -->
    <app-list-skeleton-loader [lines]="2" [height]="44" [loading]="loading()" [items]="20" />

    <app-custom-list-item
      *cdkVirtualFor="let client of clients$ | async; trackBy: trackClient"
      [selected]="selectedClient?.idtClient === client.idtClient"
      (click)="select(client)"
    >
      <div class="tw-flex tw-max-w-full tw-flex-1">
        <div class="column-name tw-flex tw-flex-1 tw-flex-col">
          <span>
            <div class="tw-flex">
              <app-contact-context-menu
                class="abs-bold abs-text-overflow-ellipsis"
                [matTooltip]="client.name"
                [displayText]="client.name"
                [id]="client.idtContact"
              />
            </div>
          </span>
          <span class="abs-text-overflow-ellipsis abs-color-faded" [matTooltip]="client.externalRep">
            <div class="tw-flex">
              @for (consultant of client.consultants; track consultant) {
                <app-contact-context-menu [displayText]="consultant.name" [id]="consultant.idtContact" />
              }
            </div>
          </span>
        </div>
        <div class="column-amount tw-flex tw-flex-col tw-items-end tw-justify-center">
          <span>{{ client.totalClient | currency }}</span>
          <span class="abs-color-faded">{{ client.allocation / 100 | percent: '.2' }}</span>
        </div>
      </div>
      <mat-divider />
    </app-custom-list-item>
  </cdk-virtual-scroll-viewport>
</app-custom-list>
