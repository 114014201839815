/**
 * Represents the social media of a contact
 */
export interface ContactSocialMedia {
  idtContactSocialMedia?: number;
  idtContact?: number;
  type: ContactSocialMediaTypeEnum;
  value?: string;
}

/**
 * Represents the Enum of contact social media type
 */
export enum ContactSocialMediaTypeEnum {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  WEBSITE = 'WEBSITE',
}

export interface UpdateSocialInputDTO {
  website: string;
  linkedin: string;
}
