<form [id]="id" [formGroup]="formGroup" class="tw-flex tw-flex-1 tw-flex-col tw-@container" (submit)="formGroup.valid && save()">
  <mat-form-field>
    @if (branch()) {
      <mat-label>Branch Name</mat-label>
    } @else {
      <mat-label>Name</mat-label>
    }
    <input matInput formControlName="name" autofocus autocapitalize="words" />
  </mat-form-field>

  <div class="tw-flex tw-flex-row tw-gap-1">
    <app-user-select class="tw-flex tw-flex-1 tw-flex-col" [control]="formGroup.controls.idtLead" label="Main lead" />
    <app-user-select class="tw-flex tw-flex-1 tw-flex-col" [control]="formGroup.controls.idtLeadBackup" label="Backup lead" />
  </div>

  <div class="tw-flex tw-flex-col tw-gap-x-1 @xs:tw-flex-row">
    @if (type() === ContactTypeEnum.PERSON) {
      <div class="tx-flex-row tw-flex tw-gap-1 @xs:tw-flex-1">
        <mat-form-field class="tw-w-[100px]">
          <mat-label>Type</mat-label>
          <mat-select formControlName="emailType">
            @for (type of channelTypes; track type) {
              <mat-option [value]="type">
                {{ type | titlecase }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <app-contact-email-field
          [control]="formGroup.controls.email"
          (searchingDuplicatedEmailsChanged)="searchingDuplicatedEmailsChanged.emit($event)"
        />
      </div>
    } @else {
      <mat-form-field class="tw-flex-1">
        <mat-label>Website</mat-label>
        <input matInput formControlName="website" />
      </mat-form-field>
    }

    <div class="tx-flex-row tw-flex tw-gap-1 @xs:tw-flex-1">
      <mat-form-field class="tw-w-[100px]">
        <mat-label>Type</mat-label>
        <mat-select formControlName="phoneType">
          @for (type of channelTypes; track type) {
            <mat-option [value]="type">
              {{ type | titlecase }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="tw-flex-1">
        <mat-label>Phone</mat-label>
        <input matInput formControlName="phone" type="phone" />
        <mat-error>Invalid phone</mat-error>
      </mat-form-field>
    </div>
  </div>

  @if (type() === ContactTypeEnum.COMPANY) {
    <div class="tw-flex tw-flex-col tw-gap-1">
      <span class="tw-mb-1 tw-ml-1 tw-font-medium">Address</span>

      <div class="tw-flex tw-gap-1">
        <app-country-autocomplete class="tw-w-full" [control]="formGroup.controls.idtCountry" />

        @if (this.formGroup.controls.idtCountry.value === idtCountryUsa) {
          <mat-form-field class="tw-w-full @xs:tw-w-2/6">
            <mat-label>State</mat-label>

            <mat-select formControlName="idtState">
              @for (state of states$ | async; track state) {
                <mat-option [value]="state.idtState">
                  {{ state.abbreviation || state.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        } @else {
          <mat-form-field class="tw-w-full @xs:tw-w-2/6">
            <mat-label>State</mat-label>
            <input matInput type="text" formControlName="stateName" />
          </mat-form-field>
        }

        <mat-form-field class="tw-w-full">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>
      </div>
    </div>
  }
</form>
