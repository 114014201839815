import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { Observable, catchError, debounceTime, distinctUntilChanged, finalize, mergeMap, of, tap, throwError } from 'rxjs';
import { BasicContact, ContactIndexedDTO, ContactTypeEnum } from '../../../model/contact.model';
import { ContactService } from '../../../services/contact.service';
import { ContactNewBasicData } from '../contact-new-basic-data-form/contact-new-basic-data-form.component';

/**
 * Component to associating a new person contact to a company.
 */
@Component({
  selector: 'app-contact-new-associate-to-company',
  templateUrl: './contact-new-associate-to-company.component.html',
  styleUrl: './contact-new-associate-to-company.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactNewAssociateToCompanyComponent implements OnInit {
  ContactTypeEnum = ContactTypeEnum;

  /**
   * Emits the selected company when clicked or a new one is saved.
   */
  @Output()
  companySelected = new EventEmitter<BasicContact | null>();

  /**
   * Emits true, when the server is processing a request.
   */
  @Output()
  processing = new EventEmitter<boolean>();

  /**
   * The contact lists to be displayed as options to avoid creating duplicated ones.
   */
  contactData$?: Observable<ContactIndexedDTO[]>;

  /**
   * Field used to search for conctact
   */
  searchField = new FormControl<string>('', { nonNullable: true });

  constructor(
    private contactService: ContactService,
    private dialogService: DialogService,
    private destroyRef: DestroyRef,
    private trackerService: TrackerService
  ) {}

  /**
   * On component initialization see if the current page is a contact, and get the company
   */
  ngOnInit(): void {
    this.setupSearch();
  }

  /**
   * Configure company search.
   */
  private setupSearch(): void {
    this.contactData$ = this.searchField.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      debounceTime(1000),
      distinctUntilChanged(),
      tap(() => {
        this.processing.emit(true);
      }),
      mergeMap((term) => {
        if (term.length > 2) {
          this.trackerService.event('contact_new', 'associate_company_search_existing', { term });
          return this.contactService.searchIndexedContacts(term, ContactTypeEnum.COMPANY);
        }

        return of([]);
      }),
      tap(() => {
        this.processing.emit(false);
      })
    );
  }

  /**
   * Select a company as the contact's company.
   *
   * @param contact the company contact to select
   */
  selectCompany(contact: BasicContact): void {
    this.companySelected.emit(contact);

    this.trackerService.event('contact_new', 'associate_company_select_company');
  }

  /**
   * Create a new company and select it as the created person's company.
   *
   * @param data the new company data
   */
  createNewCompany(data: ContactNewBasicData): void {
    this.processing.emit(true);

    this.contactService
      .create({
        type: ContactTypeEnum.COMPANY,
        ...data,
      })
      .pipe(
        catchError((err) => {
          this.dialogService.showError(err.error?.message || 'Error creating contact');

          return throwError(() => err);
        }),
        finalize(() => {
          this.processing.emit(false);
        })
      )
      .subscribe((contact) => {
        this.selectCompany(contact);
        this.trackerService.event('contact_new', 'associate_company_create_new');
      });
  }

  /**
   * If cancelling, emit null to not change anything.
   */
  cancel(): void {
    this.companySelected.emit(null);
  }
}
