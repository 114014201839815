<mat-form-field fxFlex>
  <mat-label>{{ label() }}</mat-label>

  <input type="text" matInput [formControl]="formControl" [matAutocomplete]="auto" #input />

  <mat-autocomplete requireSelection #auto [displayWith]="getOptionDisplay">
    <mat-option>None</mat-option>

    @for (company of companies(); track company.idtContact) {
      <mat-option [value]="company">
        {{ company.name }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
