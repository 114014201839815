import { Pipe, PipeTransform } from '@angular/core';

/**
 * Return the initials of a name
 * Considering first and last name
 */
@Pipe({
  name: 'acronym',
})
export class AcronymPipe implements PipeTransform {
  transform(value: string): string | null {
    if (value == null) {
      return null;
    }

    const initials = value.match(/\b\w/g) || [];
    const initialsResult: string = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initialsResult;
  }
}
