<a
  [mtxTooltip]="tooltip"
  mtxTooltipClass="wide-tooltip mdc-tooltip--multiline"
  [routerLink]="['', { outlets: { sidenav: ['account', data.idtAccount] } }]"
  class="abs-text-overflow-ellipsis abs-link"
>
  {{ data.accountName }}
</a>

<ng-template #tooltip>
  <div fxLayout="column">
    <span>{{ data.accountName }}</span>
    <span>Portfolio: {{ data.portfolioName }}</span>
    <span>Class: {{ data.className }}</span>
    @if (data.seriesName) {
      <span>Series: {{ data.seriesName }}</span>
    }

    <span class="abs-margin-top">
      Mgmt:
      <span [ngClass]="{ strikethrough: data.managementFeeWaived }">{{ data.managementFee != null ? data.managementFee : '-' }}%</span>
      <span>&nbsp;({{ data.effectiveManagementFee != null ? data.effectiveManagementFee : '-' }})%</span>
    </span>
    <span>
      Inc:
      <span>{{ data.incentiveFee != null ? data.incentiveFee : '-' }}%</span>
      <span>&nbsp;({{ data.effectiveIncentiveFee != null ? data.effectiveIncentiveFee : '-' }}%)</span>
    </span>
    <span>{{ data | hurdleData }}</span>
  </div>
</ng-template>
