<div fxLayout="row">
  <div fxFlex fxLayout="column">
    <form [formGroup]="contactAddressForm">
      <div fxFlex fxLayout="column">
        <div fxFlex fxLayout="row" fxLayoutGap="8px">
          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
              @for (type of addressTypes; track type) {
                <mat-option [value]="type">
                  {{ type | titlecase }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Address</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="8px">
          <app-country-autocomplete fxFlex="50" [control]="$any(contactAddressForm.get('idtCountry'))" />

          @if (this.contactAddressForm.controls.idtCountry.value === idtCountryUsa) {
            <mat-form-field fxFlex="50">
              <mat-label>State</mat-label>

              <mat-select formControlName="idtState">
                @for (state of states$ | async; track state) {
                  <mat-option [value]="state.idtState">
                    {{ state.abbreviation || state.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          } @else {
            <mat-form-field fxFlex="50">
              <mat-label>State</mat-label>
              <input matInput type="text" formControlName="stateName" />
            </mat-form-field>
          }
        </div>

        <div fxFlex fxLayout="row" fxLayoutGap="8px">
          <mat-form-field fxFlex="50">
            <mat-label>City</mat-label>
            <input matInput formControlName="city" />
          </mat-form-field>
          <mat-form-field fxFlex="50">
            <mat-label>Zipcode</mat-label>
            <input matInput formControlName="zipCode" />
          </mat-form-field>
        </div>

        <mat-slide-toggle class="tw-mt-1" formControlName="favorite">Primary address</mat-slide-toggle>
      </div>
    </form>
  </div>

  <button fxLayoutAlign="end" mat-icon-button aria-label="Remove" (click)="removeForm()">
    <mat-icon fontIcon="mdi-minus-circle" />
  </button>
</div>
