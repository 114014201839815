import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Component that shows the performance figures in the correct color
 */
@Component({
  selector: 'app-performance-value',
  templateUrl: './performance-value.component.html',
  styleUrls: ['./performance-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceValueComponent {
  /**
   * value to be displayed
   */
  @Input({ required: true }) value!: number;

  /**
   * showType that controls the logic of whether the <sup> tag should appear
   */
  @Input() showType: boolean = true;

  /**
   * the type of value that will be displayed inside the <sup> tag, examples of values are: Y, Q, M
   */
  @Input() type!: string;

  @Input() estimated = false;
}
