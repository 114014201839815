import { Input, Pipe, PipeTransform } from '@angular/core';

/**
 * A custom pipe that displays numbers greater than a limit as "limit+".
 */
@Pipe({
  name: 'limitNumber',
})
export class LimitNumberPipe implements PipeTransform {
  /**
   * The maximum value before "limit+" is displayed. Defaults to 9.
   */
  @Input() limit: number = 9;

  /**
   * Transforms a number value into a string that displays "limit+" if the value
   * is greater than the specified limit or the value itself if it's not.
   *
   * @param value the number to transform
   * @returns a string representation of the transformed number
   */
  transform(value: number): string {
    return value > this.limit ? `${this.limit}+` : value.toString();
  }
}
