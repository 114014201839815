import { Component, ElementRef, Inject, QueryList, ViewChildren, signal } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContactChannel, ContactChannelEnum } from '../../model/contact-channel.model';
import { ContactService } from '../../services/contact.service';
import { ContactChannelForm, ContactChannelFormComponent } from '../contact-create-edit/contact-channel-form/contact-channel-form.component';

@Component({
  selector: 'app-contact-email-edit',
  templateUrl: './contact-email-edit.component.html',
  styleUrls: ['./contact-email-edit.component.scss'],
})
export class ContactEmailEditComponent {
  ContactChannelEnum = ContactChannelEnum;

  /**
   * The id of the contact.
   */
  private idtContact!: number;

  /**
   * Form group object.
   */
  formGroup = this.fb.group({
    emails: new FormArray<ContactChannelForm>([]),
  });

  /**
   * Get the formArray with the data.
   */
  get formArray() {
    return this.formGroup.controls.emails;
  }

  private emails?: ContactChannel[];

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * Reference to the form components created for each consultant.
   */
  @ViewChildren(ContactChannelFormComponent, { read: ElementRef })
  formComponents!: QueryList<ElementRef>;

  constructor(
    private contactService: ContactService,
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) private data: { idtContact: number; includeNew: boolean; includeAddress: string },
    private dialogRef: MatDialogRef<ContactEmailEditComponent>,
  ) {
    this.idtContact = data.idtContact;
    this.getEmails();
  }

  /**
   * Get the emails from the server.
   */
  private getEmails(): void {
    this.contactService
      .getChannels(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((channels) => {
          this.emails = channels.filter((c) => c.channel === ContactChannelEnum.EMAIL);

          for (const email of this.emails) {
            this.addContactEmailForm(email);
          }
        }),
        tap(() => {
          if (this.data.includeNew) {
            this.addContactEmailForm(null, this.data.includeAddress);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Get a job data from the list of already saved jobs.
   *
   * @param index the index of the job in the list
   * @returns the job found or null
   */
  getEmail(index: number): ContactChannel | null {
    if (this.emails) {
      return this.emails[index];
    }

    return null;
  }

  /**
   * Adds a new contact email form group in the form array
   */
  addContactEmailForm(contactEmail: ContactChannel | null = null, initialValue?: string): void {
    const form = ContactChannelFormComponent.getContactChannelForm(contactEmail);
    form.patchValue({ channel: ContactChannelEnum.EMAIL, ...(initialValue && { value: initialValue }) });
    form.controls.value.addValidators(Validators.email);
    this.formArray.push(form);

    // If creating a new email, after the form component is created, scroll it into the visible area
    if (!contactEmail) {
      setTimeout(() => {
        const el = this.formComponents.last.nativeElement;
        el.scrollIntoView();

        // If an initial value was provided (pre-filled), focus on the mat-select to pick the email type (business or personal)
        // Notice, the second setTimeout is necessary
        if (initialValue) {
          setTimeout(() => {
            el.querySelector('mat-select').focus();
          });
        }
      });
    }
  }

  /**
   * Remove a job from the list.
   *
   * @param index the index to be removed
   */
  remove(index: number) {
    this.formArray.removeAt(index);
    this.formGroup.markAsDirty();
  }

  /**
   * Changes the flag to false when it is different from index and is true.
   * @param index the index from formArray of flag
   * @param field the field of the form to set the value
   */
  handleFormFieldFlag(index: number, field: string): void {
    const formArray = this.formArray;

    for (let i = 0; i < formArray.length; i++) {
      if (i !== index && formArray?.controls[i]?.get(field)?.value) {
        formArray.controls[i].get(field)?.setValue(false);
      }
    }
  }

  /**
   * Check if the form is valid.
   *
   * @returns true, if it is valid
   */
  private validate(): boolean {
    // reactive form validation
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }

  save(): void {
    if (!this.saving() && this.validate()) {
      const formValue = this.formArray.getRawValue();

      const formInputValue: ContactChannel[] = formValue.map((job) => {
        return {
          idtContact: job.idtContact!,
          channel: job.channel!,
          type: job.type!,
          value: job.value,
          defaultChannel: job.defaultChannel,
        };
      });

      this.contactService
        .saveChannels(this.idtContact, formInputValue, ContactChannelEnum.EMAIL)
        .pipe(
          trackLoading(this.saving),
          catchError((err) => {
            this.dialogService.showError(err.error?.message || "Couldn't save the contact emails");
            return throwError(() => err);
          }),
          tap(() => {
            this.dialogService.showSuccess('Contact emails saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
