/**
 * Represents the Person
 */
export interface Person {
  idtPerson: number;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  picture: string;
  pictureUrl: string;
  anniversaryDate: Date;
  title: string;
  salutation: string;
  spouseName: string;
  shirtSize: string;
  version: number;
}

/**
 * Represents the Enum of person title
 */
export enum PersonTitleEnum {
  DR = 'DR',
  MISS = 'MISS',
  MR = 'MR',
  MRS = 'MRS',
  MS = 'MS',
  PROF = 'PROF',
}
