import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Region } from '../model/account.model';

/**
 * Service to interact with the server for region related operations.
 */
@Injectable({
  providedIn: 'root',
})
export class RegionService {
  constructor(private http: HttpClient) {}

  /**
   * Get all available regions form the server.
   *
   * @returns the list of regions found, wrapped in an observable
   */
  get(): Observable<Region[]> {
    return this.http.get<Region[]>(`${environment.apiUrl}/region`);
  }
}
