import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { AddressTypeEnum, ContactAddressViewTO, CountriesIds } from 'app/modules/common/business/address/model/address.model';
import { AddressService } from 'app/modules/common/business/address/services/address.service';
import { atLeastOneValue } from 'app/modules/common/framework/validations/validation-utils';

/**
 * The form group type.
 */
export type AddressFromGroup = FormGroup<{
  idtAddress: FormControl<number | null>;
  idtCountry: FormControl<number | null>;
  idtState: FormControl<number | null>;
  stateName: FormControl<string>;
  name: FormControl<string | null>;
  location: FormControl<string | null>;
  city: FormControl<string | null>;
  zipCode: FormControl<string | null>;
  type: FormControl<AddressTypeEnum | null>;
  favorite: FormControl<boolean>;
}>;

/**
 * Component of contact address form
 */
@Component({
  selector: 'app-contact-address-form',
  templateUrl: './contact-address-form.component.html',
  styleUrls: ['./contact-address-form.component.scss'],
})
export class ContactAddressFormComponent implements OnInit {
  /**
   * The index of form array.
   */
  @Input()
  index!: number;

  /**
   * The contact address form
   */
  @Input()
  contactAddressForm!: AddressFromGroup;

  /**
   * Emits the remove event to delete a contact address form from array.
   */
  @Output()
  remove = new EventEmitter<number>();

  /**
   * USA country id.
   */
  idtCountryUsa = CountriesIds.USA;

  /**
   * Observable wrapping the states to display in the select.
   */
  states$ = this.addressService.findStatesByCountry(this.idtCountryUsa);

  /**
   * The list of address types
   */
  addressTypes: string[] = Object.keys(AddressTypeEnum);

  /**
   * Emits when this address is selected as the favorite address.
   */
  @Output()
  markedFavorite = new EventEmitter<void>();

  constructor(
    private addressService: AddressService,
    private destroyRef: DestroyRef,
  ) {}

  static getContactAddressForm(contactAddress?: ContactAddressViewTO, favorite?: boolean): AddressFromGroup {
    return new FormGroup(
      {
        idtAddress: new FormControl<number | null>(contactAddress?.idtAddress || null),
        idtCountry: new FormControl<number | null>(contactAddress?.idtCountry || null),
        // If the country is not EUA, don't set the idtState, therefore, if we change the state name, if will create a new state instead of updating for everyone
        idtState: new FormControl<number | null>((contactAddress?.idtCountry === CountriesIds.USA ? contactAddress?.idtState : null) || null),
        stateName: new FormControl<string>(
          {
            value: (contactAddress?.idtCountry === CountriesIds.USA ? '' : contactAddress?.state) || '',
            disabled: contactAddress?.idtCountry == null,
          },
          { nonNullable: true },
        ),
        name: new FormControl<string | null>(contactAddress?.name || null),
        location: new FormControl<string | null>(contactAddress?.location || null),
        city: new FormControl<string | null>(contactAddress?.city || null),
        zipCode: new FormControl<string | null>(contactAddress?.zipCode || null),
        type: new FormControl<AddressTypeEnum | null>(contactAddress?.type || null),
        favorite: new FormControl<boolean>(!!favorite, { nonNullable: true }),
      },
      {
        validators: [atLeastOneValue],
      },
    );
  }

  ngOnInit(): void {
    this.configureStateField();

    // If address is marked as favorite inform parent component
    this.contactAddressForm.controls.favorite.valueChanges.subscribe((v) => {
      if (v) {
        this.markedFavorite.emit();
      }
    });
  }

  /**
   * Configure state field behavior depending on selected country.
   */
  private configureStateField(): void {
    this.contactAddressForm.controls.idtCountry.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      const control = this.contactAddressForm.controls.stateName;

      // If there is a country but it is no USA, enable the State name field. Otherwise clear it and disable it.
      // If user has no country selected, the state is disabled, or if it selects USA the idtState field is used.
      if (value && value !== this.idtCountryUsa) {
        control.enable();
        this.contactAddressForm.controls.idtState.setValue(null);
      } else {
        control.disable();
        control.setValue('');
      }
    });
  }

  /**
   * Emits the event to remove a job form from form array.
   */
  removeForm(): void {
    this.remove.emit(this.index);
  }
}
