<app-dialog-title displayTitle="Relationships" [loading]="saving() || loading()">
  <button mat-icon-button type="button" (click)="addContactRelationshipForm()" [disabled]="saving()">
    <mat-icon fontIcon="mdi-plus-circle" color="primary" />
  </button>
</app-dialog-title>

<mat-dialog-content>
  <form (submit)="save()" id="relationships-form">
    <div class="tw-flex tw-flex-col">
      @for (relationshipForm of formArray.controls; track relationshipForm; let i = $index) {
        <app-contact-relationship-form
          [formGroup]="$any(relationshipForm)"
          [index]="i"
          (remove)="remove($event)"
          [initialContactTo]="getContactFromRelationship(i)"
          class="abs-dashed-box abs-margin-bottom"
        />
      }
    </div>
  </form>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button matDialogClose>Cancel</button>
  <span class="tw-flex-1"></span>
  <button mat-raised-button color="primary" type="submit" [disabled]="saving()" form="relationships-form">Save</button>
</div>
