import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, OnInit, Output, booleanAttribute, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CountriesIds } from 'app/modules/common/business/address/model/address.model';
import { AddressService } from 'app/modules/common/business/address/services/address.service';
import { UserService } from 'app/modules/common/business/user/user.service';
import { map, shareReplay } from 'rxjs';
import { ContactChannelTypeEnum } from '../../../model/contact-channel.model';
import { ContactTypeEnum } from '../../../model/contact.model';

/**
 * Definition of the contact data.
 */
export interface ContactNewBasicData {
  name: string;
  idtLead: number | null;
  idtLeadBackup: number | null;
  email: string;
  emailType: ContactChannelTypeEnum;
  phone: string;
  phoneType: ContactChannelTypeEnum;
  website: string;
  idtCountry: number | null;
  idtState: number | null;
  stateName: string;
  city: string | null;
}

/**
 * Component for a form to fill basic contact data.
 */
@Component({
  selector: 'app-contact-new-basic-data-form',
  templateUrl: './contact-new-basic-data-form.component.html',
  styleUrl: './contact-new-basic-data-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactNewBasicDataFormComponent implements OnInit {
  ContactTypeEnum = ContactTypeEnum;

  /**
   * Static counter to avoid duplicated form ids.
   */
  private static counter = 0;

  /**
   * The form id.
   */
  id = 'contactForm';

  /**
   * Form group to hold the values to save.
   */
  formGroup = this.fb.group({
    name: new FormControl<string>('', { validators: Validators.required, nonNullable: true }),
    idtLead: new FormControl<number | null>(null, { validators: Validators.required }),
    idtLeadBackup: new FormControl<number | null>(null),
    email: new FormControl<string>('', { nonNullable: true, validators: Validators.email }),
    emailType: new FormControl<ContactChannelTypeEnum>(ContactChannelTypeEnum.BUSINESS, { nonNullable: true }),
    phone: new FormControl<string>('', { nonNullable: true }),
    phoneType: new FormControl<ContactChannelTypeEnum>(ContactChannelTypeEnum.BUSINESS, { nonNullable: true }),
    website: new FormControl<string>('', { nonNullable: true }),
    idtCountry: new FormControl<number | null>(null, { nonNullable: true }),
    idtState: new FormControl<number | null>(null, { nonNullable: true }),
    stateName: new FormControl<string>(
      {
        value: '',
        disabled: true,
      },
      { nonNullable: true },
    ),
    city: new FormControl<string | null>(null, { nonNullable: true }),
  });

  /**
   * The name of the contact, can be changed.
   */
  name = input<string>();

  /**
   * Initial email value.
   */
  email = input<string>();

  /**
   * The type of contact.
   */
  type = input.required<ContactTypeEnum>();

  /**
   * If creating a branch.
   */
  branch = input(false, { transform: booleanAttribute });

  /**
   * The users available for leads.
   */
  users$ = this.userService.getEmployees().pipe(
    map((us) => us.filter((u) => !u.inactive)),
    shareReplay(1),
  );

  /**
   * List of channel types.
   */
  channelTypes: string[] = Object.keys(ContactChannelTypeEnum);

  /**
   * Emits when the form is submitted.
   */
  @Output()
  submitted = new EventEmitter<ContactNewBasicData>();

  /**
   * USA country id.
   */
  idtCountryUsa = CountriesIds.USA;

  /**
   * Observable wrapping the states to display in the select.
   */
  states$ = this.addressService.findStatesByCountry(this.idtCountryUsa);

  /**
   * Output to emit when the duplicated email search is occuring.
   */
  @Output()
  searchingDuplicatedEmailsChanged = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private addressService: AddressService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.configureStateField();
    this.id = `contactForm-${++ContactNewBasicDataFormComponent.counter}`;

    if (this.name()) {
      this.formGroup.controls.name.setValue(this.name()!);
    }
    if (this.email()) {
      this.formGroup.controls.email.setValue(this.email()!);
    }
  }

  /**
   * Handle submitting the form, emit values to parent component.
   */
  save(): void {
    this.submitted.emit(this.formGroup.getRawValue());
  }

  /**
   * Configure state field behavior depending on selected country.
   */
  private configureStateField(): void {
    this.formGroup.controls.idtCountry.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      const control = this.formGroup.controls.stateName;

      // If there is a country but it is no USA, enable the State name field. Otherwise clear it and disable it.
      // If user has no country selected, the state is disabled, or if it selects USA the idtState field is used.
      if (value && value !== this.idtCountryUsa) {
        control.enable();
        this.formGroup.controls.idtState.setValue(null);
      } else {
        control.disable();
        control.setValue('');
      }
    });
  }
}
