<div class="wrapper tw-flex tw-h-full tw-flex-1 tw-flex-col">
  <mat-toolbar color="primary">
    <span class="abs-margin-left abs-text-overflow-ellipsis">{{ (accountDetails$ | async)?.name }}</span>
    <span class="tw-flex tw-flex-1"></span>
    @if (!(accountDetails$ | async)?.inactive) {
      <button
        mat-icon-button
        [routerLink]="['', { outlets: { sidenav: ['transaction', 'new'] } }]"
        [queryParams]="{ 'sidenav.idtAccount': idtAccount, 'sidenav.idtClient': idtClient }"
        *hasPermission="'CREATE_TRANSACTION'"
        matTooltip="New transaction"
      >
        <mat-icon fontIcon="mdi-cash-multiple" />
      </button>
    }
    <button
      mat-icon-button
      [routerLink]="['', { outlets: { sidenav: ['account', idtClient, 'new'] } }]"
      [queryParams]="{ 'sidenav.cloneFrom': idtAccount }"
      *hasPermission="'CREATE_ACCOUNT'"
      matTooltip="Clone"
    >
      <mat-icon fontIcon="mdi-content-copy" />
    </button>
    <button
      mat-icon-button
      [routerLink]="['', { outlets: { sidenav: ['account', idtClient, idtAccount, 'edit'] } }]"
      *hasPermission="'EDIT_ACCOUNT'"
      matTooltip="Edit"
    >
      <mat-icon fontIcon="mdi-pencil" />
    </button>
    <button mat-icon-button [routerLink]="['', { outlets: { sidenav: null } }]">
      <mat-icon fontIcon="mdi-close" />
    </button>
  </mat-toolbar>

  <div class="tw-flex tw-flex-1">
    <mat-tab-group mat-stretch-tabs="false" class="flex-tab-group tab-tool-header abs-padding-horizontal tw-flex tw-flex-1">
      <mat-tab label="Account Details">
        <div class="tab-tools tw-items-center tw-justify-end"></div>
        <div class="tw-flex tw-flex-1 tw-flex-col">
          @if (accountDetails$ | async; as accountDetails) {
            <div class="abs-padding abs-scroll-auto tw-flex tw-flex-1 tw-flex-col">
              <app-output-section label="Investment" icon="mdi-briefcase" class="abs-margin-top">
                <app-output label="Client">
                  <div class="abs-flex">
                    @if (accountDetails.client?.owner; as owner) {
                      <app-contact-context-menu [displayText]="owner.name" [id]="owner.idtContact" />
                    }
                  </div>
                </app-output>
                <div class="tw-flex">
                  <app-output class="tw-w-1/2" label="Portfolio">
                    <app-portfolio-context-menu
                      [id]="accountDetails.portfolio!.idtPortfolio"
                      [displayText]="accountDetails.portfolio!.fullName"
                    />
                  </app-output>
                  <app-output class="tw-w-1/4" label="Class">
                    <span>{{ accountDetails.portfolioClass?.name || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="Series">
                    <span>{{ accountDetails.portfolioSeries?.name || '-' }}</span>
                  </app-output>
                </div>
                <div class="tw-flex">
                  <app-output class="tw-w-1/2" label="Initial Investment Date">
                    <span>{{ (accountDetails?.initialInvestment | date: 'MM/dd/y') || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="Balance">
                    <span>{{ ((currentBalance$ | async)?.endBalance | currency) || '-' }}</span>
                  </app-output>
                  <div class="tw-flex tw-w-1/4 tw-items-center">
                    @if (accountDetails.ocio) {
                      <mat-chip>Outsourced CIO</mat-chip>
                    }
                  </div>
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Fees" icon="mdi-currency-usd">
                <div class="tw-flex tw-flex-col tw-gap-1">
                  <div class="tw-flex">
                    <app-output class="tw-w-1/2" label="Management">
                      <span [ngClass]="{ strikethrough: accountDetails.portfolioClass?.managementFeeWaived }">
                        @if (accountDetails.portfolioClass?.managementFee != null) {
                          {{ accountDetails.portfolioClass!.managementFee! / 100 | percent: '1.2-2' }}
                        } @else {
                          -
                        }
                      </span>
                    </app-output>
                    <app-output class="tw-w-1/2" label="Effective">
                      @if (accountDetails.effectiveManagementFee != null) {
                        <span>
                          {{ accountDetails.effectiveManagementFee / 100 | percent: '1.2-2' }}
                        </span>
                      } @else {
                        <span>-</span>
                      }
                    </app-output>
                  </div>
                  <div class="tw-flex">
                    <app-output class="tw-w-1/2" label="Incentive">
                      @if (accountDetails.portfolioClass?.incentiveFee != null) {
                        <span>
                          {{ accountDetails.portfolioClass?.incentiveFee! / 100 | percent: '1.2-2' }}
                        </span>
                      } @else {
                        <span>-</span>
                      }
                      @if (accountDetails.portfolioClass?.incentiveFee != null) {
                        <span style="white-space: pre-line">
                          @if (accountDetails.portfolioClass) {
                            {{ accountDetails.portfolioClass | hurdleData }}
                          } @else {
                            -
                          }
                        </span>
                      }
                    </app-output>
                    <app-output class="tw-w-1/2" label="Effective">
                      @if (accountDetails.effectiveIncentiveFee != null) {
                        <span>
                          {{ accountDetails.effectiveIncentiveFee / 100 | percent: '1.2-2' }}
                        </span>
                      } @else {
                        <span>-</span>
                      }
                      @if (accountDetails.effectiveIncentiveFee != null) {
                        <span style="white-space: pre-line">
                          @if (accountDetails.portfolioClass) {
                            {{ accountDetails.portfolioClass | hurdleData }}
                          } @else {
                            -
                          }
                        </span>
                      }
                    </app-output>
                  </div>
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Consultant" icon="mdi-domain">
                <div class="tw-flex">
                  <app-output class="tw-w-1/2" label="Company">
                    <div class="abs-flex">
                      @if (accountDetails.consultantView?.idtConsultantCompany) {
                        <app-contact-context-menu
                          [displayText]="accountDetails.consultantView!.consultantCompanyName"
                          [id]="accountDetails.consultantView!.idtConsultantCompany"
                        />
                      } @else {
                        <span>-</span>
                      }
                    </div>
                  </app-output>
                  <app-output class="tw-w-1/2" label="Name">
                    <div class="abs-flex">
                      @if (accountDetails.consultantView?.idtConsultant) {
                        <app-contact-context-menu
                          [displayText]="accountDetails.consultantView!.consultantName"
                          [id]="accountDetails.consultantView!.idtConsultant"
                        />
                      } @else {
                        <span>-</span>
                      }
                    </div>
                  </app-output>
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Leads" icon="mdi-account-tie">
                <div class="tw-flex">
                  <app-output class="tw-w-1/4" label="Lead">
                    <span>{{ accountDetails.lead?.name || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="Backup">
                    <span>{{ accountDetails.leadBackup?.name || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="Portfolio Manager">
                    <span>{{ accountDetails.portfolio?.manager?.name || '-' }}</span>
                  </app-output>
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Legal/Compliance" icon="mdi-gavel">
                <div class="tw-flex">
                  <app-output class="tw-w-1/4" label="Side Letter">
                    <span>{{ accountDetails.sideLetter | yesNo }}</span>
                  </app-output>

                  @if (accountDetails.sideLetter) {
                    <app-output label="Side Letter Summary">
                      <span>{{ accountDetails.sideLetterSummary || '-' }}</span>
                    </app-output>
                  }
                </div>
                <div class="tw-flex">
                  <app-output class="tw-w-1/4" label="Erisa">
                    <span>{{ accountDetails.erisaStatus | emptyValue }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="Finra 5131">
                    <span>{{ accountDetails.finra5131 || '-' | titlecase }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="Blue Sky State">
                    <span>{{ accountDetails.blueSkyState || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-1/4" label="New Issue">
                    <span>{{ accountDetails.newIssueStatus || '-' | titlecase }}</span>
                  </app-output>
                </div>
                <div class="tw-flex">
                  <app-output class="tw-w-1/2" label="FormPf">
                    <span>{{ accountDetails.formPf || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-1/2" label="Share Rebate">
                    <span>{{ accountDetails.shareRebate | yesNo }}</span>
                  </app-output>
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Location" icon="mdi-earth">
                <div class="tw-flex">
                  <app-output class="tw-w-1/4" label="Region">
                    <span>{{ accountDetails.region?.name || '-' }}</span>
                  </app-output>
                  <app-output class="tw-w-3/4" label="Country">
                    <span>{{ accountDetails.country?.name || '-' }}</span>
                  </app-output>
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Account keys" icon="mdi-account-key">
                <div class="tw-flex tw-flex-col">
                  @for (key of accountDetails.keys; track key) {
                    <div class="tw-flex tw-items-center">
                      {{ key.key }}
                      @if (accountDetails.keys.length > 1 && key.main) {
                        <mat-chip-option class="mini abs-margin-half-left">Main</mat-chip-option>
                      }
                    </div>
                  }
                  @if (accountDetails.keys.length === 0) {
                    <div>No keys configured</div>
                  }
                </div>
                <mat-divider />
              </app-output-section>
              <app-output-section label="Other info" icon="mdi-information-outline">
                <div class="tw-flex">
                  <app-output class="tw-w-1/2" label="Type">
                    <span>{{ accountDetails.type?.name || '-' }}</span>
                  </app-output>
                </div>
              </app-output-section>
            </div>
          }
        </div>
      </mat-tab>

      <mat-tab label="Balance History">
        <ng-template matTabContent>
          @if (accountDetails$ | async; as account) {
            <app-account-balance-history
              [idtAccount]="account.idtAccount!"
              [inactive]="account.inactive"
              [name]="account.name"
            />
          }
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
