import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

/**
 * Verifies if value is defined number or string and not empty.
 *
 * @param value the value to check
 * @returns boolean to indicate the value is a number or string
 */
function isValue(value: number | string | null | undefined): value is number | string {
  return !(value == null || value === '' || value !== value);
}

/**
 * Transforms a string into a number (if needed).
 */
function strToNumber(value: number | string): number {
  // Convert strings to numbers
  if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }

  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }

  return value;
}

/**
 * Pipe to format numbers into percent representation.
 * Unlike the angular pipe, it doesn't multiply the number by 100.
 */
@Pipe({ name: 'absPercent' })
export class CustomPercentPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  /**
   * The transform method.
   *
   * @param value The value to be formatted
   * @param digitsInfo Sets digit and decimal representation
   * @param locale Specifies what locale format rules to use
   */
  transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null;
  transform(value: number | string | null | undefined, digitsInfo?: string, locale?: string): string | null {
    if (!isValue(value)) {
      return null;
    }

    locale ||= this._locale;

    const num = strToNumber(value);
    return formatNumber(num, locale, digitsInfo) + '%';
  }
}
