import { ChangeDetectionStrategy, Component, input, Input } from '@angular/core';

/**
 * Output a visually distinct section of information, clearly defined by a title and icon.
 */
@Component({
  selector: 'app-output-section',
  templateUrl: './output-section.component.html',
  styleUrls: ['./output-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutputSectionComponent {
  @Input({ required: true })
  label!: string;

  @Input({ required: true })
  icon!: string;

  gap = input<boolean>(true);
}
