import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-transaction-list-header',
  templateUrl: './transaction-list-header.component.html',
  styleUrls: ['./transaction-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionListHeaderComponent {
  /**
   * Whether the list should be selectable, reserves space accordingly.
   */
  @Input()
  selectable = false;
}
