import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/modules/common/framework/dialog/dialog.service';
import { ObservableUtilsService, trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { requiredIfValidator } from 'app/modules/common/framework/validations/validation-utils';
import { tap } from 'rxjs';
import { PersonTitleEnum } from '../../../person/model/person.model';
import { ContactInput, ContactTypeEnum } from '../../model/contact.model';
import { ContactService } from '../../services/contact.service';

/**
 * Component for dialog to edit a contact's basic data.
 */
@Component({
  selector: 'app-contact-basic-info-edit',
  templateUrl: './contact-basic-info-edit.component.html',
  styleUrl: './contact-basic-info-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactBasicInfoEditComponent implements OnInit {
  ContactTypeEnum = ContactTypeEnum;

  /**
   * The form definition.
   */
  contactForm = this.fb.group({
    idtLead: new FormControl<number | null>(null, Validators.required),
    idtLeadBackup: new FormControl<number | null>(null),
    name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    title: new FormControl<string>('', { nonNullable: true }),
    salutation: new FormControl<string>('', {
      validators: requiredIfValidator(() => this.type === ContactTypeEnum.PERSON),
      nonNullable: true,
    }),
    branchName: new FormControl<string>('', { nonNullable: true }),
    idtParentCompany: new FormControl<number | null>(null),
  });

  /**
   * The contact type
   */
  type!: ContactTypeEnum;

  /**
   * The contact id.
   */
  idtContact: number;

  /**
   * Indicates if processing a save.
   */
  saving = signal(false);

  /**
   * Whether loading data.
   */
  loading = signal(false);

  /**
   * The full contact data.
   */
  contact = signal<ContactInput | null>(null);

  /**
   * Whether the contact is a branch company.
   */
  isBranch = signal(false);

  /**
   * Whether to show the parent company input.
   */
  showParentCompanyInput = signal(false);

  /**
   * List of person titles.
   */
  personTitles: string[] = Object.keys(PersonTitleEnum);

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,
    @Inject(MAT_DIALOG_DATA) data: { idtContact: number },
    private dialogRef: MatDialogRef<ContactBasicInfoEditComponent>,
    private observableUtils: ObservableUtilsService,
    private dialogService: DialogService,
  ) {
    this.idtContact = data.idtContact;
  }

  ngOnInit() {
    this.loadContact();
    this.setupParentCompanyChange();
  }

  /**
   * Load the contact data and setup the fields to be displayed.
   */
  private loadContact(): void {
    this.contactService
      .get(this.idtContact)
      .pipe(
        trackLoading(this.loading),
        tap((contact) => {
          this.contact.set(contact);
          this.isBranch.set(contact.type === ContactTypeEnum.COMPANY && contact.branch);
          this.showParentCompanyInput.set(!contact.company?.idtParentCompany);
          this.setContactForm(contact);
        }),
      )
      .subscribe();
  }

  /**
   * Update the form values after loading the contact.
   */
  private setContactForm(contact: ContactInput): void {
    this.contactForm.patchValue(
      {
        idtLead: contact.idtLead,
        idtLeadBackup: contact.idtLeadBackup,
        name: contact.name,
        title: contact.person?.title,
        salutation: contact.person?.salutation,
        branchName: contact.company?.branchName,
        idtParentCompany: contact.company?.idtParentCompany,
      },
      { emitEvent: false },
    );
  }

  /**
   * Setup logic when changing whether the contact has a parent company.
   */
  private setupParentCompanyChange() {
    this.contactForm.controls.idtParentCompany.valueChanges.subscribe((value) => {
      this.isBranch.set(!!value);
    });
  }

  /**
   * After the name is changed (input focus leave), set the salutation, if still empty, as the first name.
   */
  nameChanged(): void {
    const preferredNameControl = this.contactForm.controls.salutation;

    if (preferredNameControl.value == null || preferredNameControl.value === '') {
      const name = this.contactForm.controls.name.value;
      preferredNameControl?.setValue(name.split(' ')[0]);
    }
  }

  /**
   * Remove parent company value.
   */
  removeFromParentCompany(): void {
    this.showParentCompanyInput.set(true);
    this.contactForm.controls.idtParentCompany.setValue(null);
  }

  /**
   * Send the data to the server to be persisted.
   */
  save(): void {
    if (!this.saving()) {
      this.contactService
        .updateBaseData(this.idtContact, this.contactForm.getRawValue())
        .pipe(
          trackLoading(this.saving),
          this.observableUtils.catchErrorAndNotify("Couldn't save the contact"),
          tap(() => {
            this.dialogService.showSuccess('Contact saved successfully');
            this.dialogRef.close(true);
          }),
        )
        .subscribe();
    }
  }
}
