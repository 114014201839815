/**
 * The transaction status options.
 */
export enum TransactionStatusEnum {
  PROSPECT = 'PROSPECT',
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}

/**
 * The transaction pending status options.
 */
export enum TransactionPendingStatusEnum {
  PENDING_DOCS = 'PENDING_DOCS',
  REDEMPTION_FILLED = 'REDEMPTION_FILLED',
  OPEN_PENDING_DOCS = 'OPEN_PENDING_DOCS',
  SUBSCRIPTION_RECEIVED = 'SUBSCRIPTION_RECEIVED',
  CONFIRMED_DOCS = 'CONFIRMED_DOCS',
  OPPORTUNITY_PENDING = 'OPPORTUNITY_PENDING',
  WIRE_REC_NO_DOCS = 'WIRE_REC_NO_DOCS',
}
