<mat-form-field>
  <mat-label>{{ label() }}</mat-label>

  <mat-select [formControl]="control()">
    <mat-option>
      <ngx-mat-select-search [formControl]="userSelectSearchControl" placeholderLabel="Search" noEntriesFoundLabel="Nothing found">
        <mat-icon ngxMatSelectSearchClear fontIcon="mdi-close" />
      </ngx-mat-select-search>
    </mat-option>
    <mat-option>None</mat-option>
    @for (user of filteredUsers(); track user.idtUser) {
      <mat-option [value]="user.idtUser">
        <span [class.abs-color-faded]="user.inactive">{{ user.name }}</span>
      </mat-option>
    }
  </mat-select>
</mat-form-field>
