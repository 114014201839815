import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataFilter } from 'app/modules/common/framework/model/data-filter';
import { Pageable } from 'app/modules/common/framework/pagination/pageable';
import PageableService from 'app/modules/common/framework/pagination/pageable-service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BalanceWithTransactionSumViewTO } from '../../client/model/client-balance.model';

/**
 * Service to get the balance history list.
 */
@Injectable({
  providedIn: 'root',
})
export class BalanceHistoryService implements PageableService<BalanceWithTransactionSumViewTO> {
  constructor(private http: HttpClient) {}

  /**
   * Get a single page of balance objects.
   *
   * @param page the page number
   * @param size the page size
   * @param sort the columnd and direction to sort by
   * @param filters the filters to apply to the query
   * @returns the page returned from the server wrapped in an observable
   */
  getPage(page: number, size: number, sort: string | string[], filters: string): Observable<Pageable<BalanceWithTransactionSumViewTO>> {
    return this.http.get<Pageable<BalanceWithTransactionSumViewTO>>(`${environment.apiUrl}/balance/history`, {
      params: {
        page: page.toString(),
        size: size.toString(),
        sort,
        filters,
      },
    });
  }

  /**
   * Export the balance history into an excel file.
   *
   * @param sort the columns to sort by
   * @param filters the filters to apply to the query
   */
  export(sort: string | string[], filters: DataFilter): Observable<Blob> {
    return this.http.post<Blob>(
      `${environment.apiUrl}/balance/history/export`,
      {},
      {
        params: { sort, filters: filters.encode() },
        responseType: 'blob' as 'json',
      }
    );
  }
}
