<div mat-dialog-content class="tw-@container" [ngClass]="{ '!tw-hidden': selecting() }">
  <span class="abs-bold abs-margin-left abs-margin-bottom">Basic information</span>

  <app-contact-new-basic-data-form
    (submitted)="save($event)"
    [name]="idtMainCompany() ? '' : name()"
    [email]="email()"
    [type]="type()"
    [branch]="idtMainCompany()"
    #contactForm
    (searchingDuplicatedEmailsChanged)="searchingDuplicatedEmails.set($event)"
  />

  @if (type() === ContactTypeEnum.PERSON) {
    <div class="tw-flex tw-flex-col tw-gap-1 @xs:tw-flex-row">
      @if (selectedCompany()) {
        <div class="tw-flex-1">
          <div class="tw-flex tw-flex-1 tw-flex-row">
            <app-output label="Company">
              <div class="abs-bold">{{ selectedCompany()!.name }}</div>
            </app-output>
            <div class="tw-flex tw-items-center">
              <button mat-icon-button (click)="selecting.set(true)"><mat-icon fontIcon="mdi-pencil" /></button>
              <button mat-icon-button (click)="selectedCompany.set(null)"><mat-icon fontIcon="mdi-close" /></button>
            </div>
          </div>
        </div>
      } @else {
        <div class="tw-flex tw-flex-1 tw-flex-row tw-items-center">
          <button mat-raised-button (click)="selecting.set(true)">Select company</button>
        </div>
      }

      @if (selectedCompany()) {
        <div class="tw-flex tw-flex-1">
          <mat-form-field class="tw-flex-1">
            <mat-label>Job Role</mat-label>

            <input matInput [formControl]="jobRoleControl" [matAutocomplete]="jobRoleAutocomplete" />
            <mat-autocomplete #jobRoleAutocomplete="matAutocomplete">
              @for (job of filteredRoles$ | async; track job) {
                <mat-option [value]="job.role">{{ job.role }}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      }
    </div>
  }

  @if (mainCompany()) {
    <div>
      Creating company as a branch of <span class="abs-bold">{{ mainCompany()!.name }}</span>
    </div>
  }
</div>

@if (!selecting()) {
  <div mat-dialog-actions>
    <button mat-button matDialogClose>Cancel</button>
    <span class="tw-flex-1"></span>
    <button mat-raised-button type="submit" [attr.form]="contactForm.id" color="accent" [disabled]="searchingDuplicatedEmails()">Save</button>
  </div>
} @else {
  <app-contact-new-associate-to-company
    (companySelected)="companySelected($event)"
    (processing)="processing.emit($event)"
  />
}
