import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MONTH_YEAR_DATE_FORMATS } from 'app/modules/common/framework/date-format/custom-date-format';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { filter, mergeMap, startWith, tap } from 'rxjs/operators';
import { PortfolioAllocation } from '../../model/portfolio-allocation.model';
import { PortfolioService } from '../../services/portfolio.service';

/**
 * Component that represents a list of all system portfolios and its allocations.
 */
@Component({
  selector: 'app-portfolio-allocation',
  templateUrl: './portfolio-allocation.component.html',
  styleUrls: ['./portfolio-allocation.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_DATE_FORMATS }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioAllocationComponent implements OnInit {
  constructor(private portfolioService: PortfolioService) {}

  /**
   * Portfolio allocation list.
   */
  portfolios$: Observable<PortfolioAllocation[]> | undefined;

  /*
   * Firm totalization item.
   */
  total: PortfolioAllocation | undefined;

  /**
   * The selected portfolio.
   */
  @Input()
  selectedPortfolio?: PortfolioAllocation;

  /**
   * Emits the select event.
   */
  @Output()
  selectPortfolio = new EventEmitter<PortfolioAllocation | undefined>();

  /**
   * Emits when selected date is changed.
   */
  @Output()
  dateChanged = new EventEmitter<DateTime>();

  /**
   * Form control for the date filter input.
   */
  dateControl = new FormControl<DateTime | null>(null);

  loading = signal(true);

  /**
   * The current date to block selecting future dates.
   */
  maxDate = DateTime.now().endOf('month');

  /**
   * On component initialization loads all portfolio allocations and set a property with the first
   * portfolio to be used for the firm totalization properties as these properties are setted in all
   * itens.
   */
  ngOnInit(): void {
    this.portfolios$ = this.dateControl.valueChanges.pipe(
      startWith(null),
      filter(() => this.dateControl.valid),
      tap((date) => {
        if (date) {
          this.dateChanged.emit(date);
        }

        this.loading.set(true);
      }),
      mergeMap((date) => this.portfolioService.findAllocations(date)),
      tap((allocations) => {
        this.total = allocations[0];

        if (!this.dateControl.value) {
          this.dateControl.setValue(DateTime.fromISO(this.total.firmAsOfDate));
        }

        this.loading.set(false);
      })
    );
  }

  /**
   * Select portfolio and emit an event to the parent component.
   * @param idtPortfolio portfolio id.
   */
  select(portfolio?: PortfolioAllocation): void {
    if (this.selectedPortfolio?.idtPortfolio === portfolio?.idtPortfolio) {
      this.selectPortfolio.emit(undefined);
    } else {
      this.selectPortfolio.emit(portfolio);
    }
  }

  /**
   * Sets the proper date value when the month is selected, not requiring the day to be selected.
   *
   * @param fullDate the full date tha comes when the client choose a month on calendar
   * @param datepicker responsible for managing the datepicker popup/dialog
   */
  monthSelected(fullDate: DateTime, datepicker: MatDatepicker<DateTime>): void {
    this.dateControl.setValue(fullDate.endOf('month'));

    datepicker.close();
  }
}
