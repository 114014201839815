<div class="search-field" fxFlex fxLayoutAlign="end" fxLayoutAlign.gt-xs="center">
  <mat-form-field fxHide [fxShow]="showSearch" fxShow.gt-xs ngClass.xs="full-width" subscriptSizing="dynamic" class="labelless">
    @if (!searchField.value) {
      <mat-icon matPrefix fontIcon="mdi-magnify" />
    }
    @if (searchField.value) {
      <mat-icon matPrefix (click)="close()" fontIcon="mdi-close" class="abs-clickable" />
    }
    <input matInput type="text" placeholder="Search" [formControl]="searchField" #searchInput (focusout)="focusout()" maxlength="128" />
  </mat-form-field>

  <button mat-icon-button (click)="displaySearch()" fxShow [fxHide]="showSearch" fxHide.gt-xs class="tw-m-[6px]">
    <mat-icon fontIcon="mdi-magnify" />
  </button>
</div>
