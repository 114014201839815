import { Component, Input } from '@angular/core';
import { AccountListItem } from '../../model/account.model';

/**
 * Component for an item of account
 */
@Component({
  selector: 'app-account-list-item',
  templateUrl: './account-list-item.component.html',
  styleUrls: ['./account-list-item.component.scss'],
})
export class AccountListItemComponent {
  @Input()
  account!: AccountListItem;
}
