@if (!account.itdUnreliable) {
  <div>
    <div matTooltip="Inception: {{ account.initialInvestmentDate | date: 'M/dd/y' }}">
      <app-performance-value
        [value]="cumulative ? account.itdCumulative : account.itd"
        [estimated]="estimated"
        [showType]="dense"
        [type]="'I' + (cumulative ? 'C' : 'A')"
      />
    </div>
  </div>
} @else {
  <div
    fxLayoutAlign="end end"
    matTooltip="Inception: {{ account.initialInvestmentDate | date: 'M/dd/y' }} (pre {{ minDate.toJSDate() | date: 'MM/yyyy' }} in progress)"
    >
    <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" />
  </div>
}
